<template>
    <div>
        <v-btn
            v-for="(btn, index) in profile.associations"
            :key="index"
            :class="{ 'mx-1': !isXs, 'mr-1': isXs }"
            fab
            :x-small="isXs"
            :small="isSm"
            @click.native="$emit('execute', btn.value)"
            :color="'secondary'"
        >
            <v-icon
                v-if="btn.value == 0"
                :size="isXs ? 12 : isSm ? 16 : 24"
                :class="{ 'primary--text': value == 0 }"
                >fa-arrow-up</v-icon
            >
            <v-icon
                v-if="btn.value == 2"
                :size="isXs ? 12 : isSm ? 16 : 24"
                :class="{ 'primary--text': value == 2 }"
                >fa-stop</v-icon
            >
            <v-icon
                v-if="btn.value == 4"
                :size="isXs ? 12 : isSm ? 16 : 24"
                :class="{ 'primary--text': value == 4 }"
                >fa-arrow-down</v-icon
            >
        </v-btn>
    </div>
</template>

<script>
export default {
    props: ['profile', 'value'],
    computed: {
        isXs() {
            return this.$vuetify.breakpoint.name == 'xs';
        },
        isSm() {
            return this.$vuetify.breakpoint.name == 'sm';
        }
    }
};
</script>
