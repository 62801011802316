<template>
    <v-card>
        <v-toolbar color="primary" class="black--text"
            ><h3>Versionsinfo</h3></v-toolbar
        >
        <v-card-text class="my-2">
            <div class="py-2" v-html="releaseNotesHTML"></div>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn text @click="$emit('close')">Schliessen</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { marked } from 'marked';
import releasenotes from '../../../releasenotes.md';

export default {
    data() {
        return {};
    },
    computed: {
        releaseNotesHTML() {
            const rn = releasenotes;
            return marked.parse(rn);
        }
    }
};
</script>
