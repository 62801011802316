<template>
    <div>
        <v-snackbar
            v-model="model"
            :timeout="waittime"
            :color="color"
            :multi-line="true"
            @input="close"
        >
            <slot>Ein unbekannter Fehler ist aufgetreten</slot>

            <a v-if="this.details" @click.stop="dialogDetails = true">
                Details anzeigen
            </a>

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="acknowledge">
                    {{ button }}
                </v-btn>
            </template>
        </v-snackbar>
        <v-dialog v-model="dialogDetails" width="500">
            <v-card>
                <v-card-title class="text-h5">
                    Details
                </v-card-title>
                <v-card-text>
                    <code>{{ this.details }}</code>
                    <v-icon
                        @click="copyToClipboard"
                        style="cursor: pointer"
                        :title="copyIcon.title"
                        :color="copyIcon.color"
                        class="ml-3"
                        >fa-copy</v-icon
                    >
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogDetails = false">
                        Schliessen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
/**
just use this component with v-model="error.display".
It will close and reset the error.display value automatically after 4 seconds by default.
*/
export default {
    props: {
        value: {
            required: true,
            type: Boolean
        },
        timeout: {
            default: 4000,
            type: Number
        },
        color: {
            default: 'error',
            type: String
        },
        button: {
            default: 'OK',
            type: String
        },
        error: {
            default: null
        }
    },
    data() {
        return {
            model: false,
            exception: null,
            details: '',
            dialogDetails: false,
            copyIcon: {
                title: 'Text in Zwischenablage kopieren.',
                color: 'white'
            },
            waittime: 4000
        };
    },
    watch: {
        timeout: {
            immediate: true,
            handler(val) {
                this.waittime = val;
            }
        },
        dialogDetails(val) {
            if (val) {
                this.waittime = -1;
            } else {
                this.copyIcon.title = 'Text in Zwischenablage kopieren.';
                this.copyIcon.color = 'white';
            }
        },
        value: {
            immediate: true,
            handler(val) {
                this.model = val;
                if (val && this.exception !== null) {
                    try {
                        if (typeof this.exception === 'object') {
                            this.$sentry.captureException(this.exception);
                        } else if (typeof this.exception === 'string') {
                            this.$sentry.captureMessage(this.exception);
                        }
                    } catch (ex) {
                        console.warn(ex);
                    }
                    this.exception = null;
                }
            }
        },
        error(ex) {
            this.exception = ex;
            if (typeof this.exception === 'object') {
                this.details = this.exception;
            } else if (typeof this.exception === 'string') {
                this.details = this.exception;
            }
        }
    },
    methods: {
        close() {
            this.model = false;
            this.$emit('input', this.model);
        },
        acknowledge() {
            this.$emit('ack');
            this.close();
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.details).then(
                () => {
                    this.copyIcon.title = 'Text wurde kopiert.';
                    this.copyIcon.color = 'success';
                },
                err => {
                    console.warn(err);
                    this.copyIcon.title = 'Fehler beim kopieren des Textes.';
                    this.copyIcon.color = 'error';
                }
            );
        }
    }
};
</script>
