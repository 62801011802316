<template>
    <div>
        <span>{{ value }}</span>
        <span> km/h</span>
    </div>
</template>
<script>
export default {
    props: ['profile', 'value']
};
</script>
