<template>
    <div class="d-flex justify-center">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    width="50%"
                    text
                    small
                    color="primary"
                    class="px-0 kno-shrinked-btn"
                    v-on="on"
                    v-bind="attrs"
                >
                    <v-icon>
                        fa-star
                    </v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    v-for="(btn, index) in profile.associations"
                    :key="index"
                    @click.native="$emit('execute', btn.value)"
                    style="cursor:pointer"
                >
                    <v-list-item-title>{{ btn.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    props: ['profile', 'value']
};
</script>

<style>
button.kno-shrinked-btn {
    min-width: 0 !important;
}
</style>
