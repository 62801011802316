<template>
    <v-navigation-drawer
        class="notch-safe-nav"
        app
        v-model="drawer"
        :permanent="permanent"
        left
        :style="{
            'background-color': $vuetify.breakpoint.mdAndUp
                ? 'rgba(0,0,0,0.4)'
                : 'primary'
        }"
    >
        <div
            class="secondary"
            id="sidenav-head"
            align="center"
            justify="center"
        >
            <v-row>
                <v-col>
                    <v-img
                        class="mt-2"
                        src="@/assets/knockaut_logo.png"
                        width="80%"
                        alt="KnockAut Logo"
                    />
                </v-col>
            </v-row>
        </div>
        <!-- List available rooms -->
        <v-list dense class="py-0">
            <div v-for="(item, index) in rooms" :key="`room-${index}`">
                <DrawerListItem :item="item" :index="index" />
            </div>
            <v-list-item v-if="rooms.length <= 1">
                <v-list-item-content>
                    <v-list-item-title>
                        Keine Räume vorhanden
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <!-- List available modules -->
        <template v-if="modules.length - 1 > 0">
            <v-spacer></v-spacer>
            <v-list dense>
                <div v-for="(item, index) in modules" :key="`module-${index}`">
                    <DrawerListItem :item="item" :index="index" />
                </div>
            </v-list>
        </template>

        <AppUpdater />
    </v-navigation-drawer>
</template>

<script>
import DrawerListItem from './DrawerListItem.vue';
import AppUpdater from './AppUpdater.vue';
export default {
    components: { DrawerListItem, AppUpdater },
    name: 'SideBar',
    data() {
        return {
            permanent: this.$vuetify.breakpoint.mdAndUp,
            rooms: [
                {
                    title: 'Home',
                    icon: 'Basement',
                    id: 0,
                    routeName: 'Home',
                    routeParams: { roomId: 0 }
                }
            ],
            modules: [
                {
                    title: 'Module',
                    routeName: 'Modules',
                    icon: 'fas fa-lg fa-dice-d6'
                }
            ]
        };
    },
    watch: {
        drawer(val) {
            // watch drawer, because it can be set from outside, f.ex. the "menu" button in AppNavigation.
            if (this.$vuetify.breakpoint.mdAndUp) {
                // in web app and Tablet app, toggle SideBar with "permanent" parameter.
                this.permanent = val;
            } else {
                // in mobile app, toggle SideBar with "drawer" parameter.
                this.permanent = false;
            }
        }
    },
    computed: {
        drawer: {
            get() {
                return this.$store.state.sidebarShown;
            },
            set(value) {
                this.$store.commit('showSidebar', value);
            }
        }
    },
    methods: {
        async addRooms() {
            const id = this.$store.state.rootID;
            const firstItem = this.$store.state.snapshot.objects[
                'ID' + this.$store.state.configuratorID
            ];
            this.rooms[0].title = firstItem.name;
            this.rooms[0].icon = firstItem.icon ? firstItem.icon : 'IPS';
            const dynamicItems = [];
            const children = await this.$store.dispatch('getChildren', [id, 0]);
            for (const key in children) {
                const roomName = children[key].name;
                const icon = children[key].icon
                    ? children[key].icon
                    : 'fas fa-door-open fa-lg';
                const roomId = key.substring(2);
                const room = {
                    title: roomName,
                    icon: icon,
                    id: roomId,
                    routeName: 'Home',
                    routeParams: { roomId },
                    position: children[key].position
                };
                dynamicItems.push(room);
            }
            dynamicItems.sort((a, b) =>
                a.title > b.title ? 1 : b.title > a.title ? -1 : 0
            );

            dynamicItems.sort((a, b) => a.position - b.position);

            dynamicItems.forEach(item => {
                this.rooms.push(item);
            });
        }
    },
    async mounted() {
        await this.addRooms();

        // update Modules Menu
        const availableModuleItems = [
            {
                guid: '{17433113-1A92-45B3-F250-B5E426040E64}',
                name: 'Alarmanlage',
                icon: 'fas fa-lg fa-volume-up',
                routeName: 'KnockautAlarm'
            }
        ];
        const allModuleIDs = await this.$api.getModuleList();
        allModuleIDs.forEach(async moduleID => {
            const moduleItem = availableModuleItems.find(
                moduleItem => moduleItem.guid == moduleID
            );
            if (moduleItem) {
                // Only show module in sidebar if instance exists
                const moduleInstance = Object.filter(
                    this.$store.getters.snapshotObjects,
                    object =>
                        object.data && object.data.moduleID == moduleItem.guid
                );
                if (Object.keys(moduleInstance).length >= 1) {
                    this.modules.push({
                        title: moduleItem.name,
                        icon: moduleItem.icon,
                        id: moduleItem.guid,
                        routeName: moduleItem.routeName,
                        routeParams: { id: moduleItem.guid }
                    });
                }
            }
        });
        if (this.$vuetify.breakpoint.mdAndUp) {
            this.drawer = true;
        } else {
            this.drawer = false;
        }
    }
};
</script>

<style scoped>
.v-list-item--active {
    border-left: 8px solid #b5e10a;
}
.v-list-item--disabled {
    color: #fff !important;
}
.v-list-item__action {
    margin-right: 16px !important;
}
</style>
