<template>
    <div>
        <v-footer
            height="70%"
            :inset="true"
            app
            style="background-color: rgb(56, 50, 47, 0.95)"
            v-if="!$store.state.footerEdit"
        >
            <v-card
                class="d-flex align-center justify-space-around"
                flat
                height="100%"
                width="100%"
                color="rgb(0,0,0,0)"
                tile
                @mousedown="startHold"
                @mouseleave="stopHold"
                @mouseup="stopHold"
                @touchstart="startHold"
                @touchend="stopHold"
                @touchcancel="stopHold"
            >
                <footer-variable
                    v-for="(ftVar, index) in footerVariables"
                    :key="index"
                    :edit="$store.state.footerEdit"
                    :variableid="ftVar"
                    style="pointer-events: none"
                ></footer-variable>
            </v-card>
            <v-progress-linear
                v-if="!$store.state.footerEdit && holdCount > 0"
                :value="(100 / 15) * holdCount"
            ></v-progress-linear>
        </v-footer>
        <v-footer
            height="140%"
            :inset="true"
            app
            style="background-color: rgb(56, 50, 47, 0.95)"
            v-if="$store.state.footerEdit"
        >
            <v-card
                class="d-flex align-center"
                flat
                height="30%"
                width="100%"
                color="rgb(0,0,0,0)"
                tile
                style="border-bottom: solid grey 1px"
            >
                <button class="accent--text" @click="cancelFooterEdit">
                    ABBRECHEN
                </button>
                <v-spacer></v-spacer>
                <button class="accent--text" @click="saveFooterEdit">
                    SPEICHERN
                </button>
            </v-card>
            <v-card
                class="d-flex align-center justify-space-around"
                flat
                height="70%"
                width="100%"
                color="rgb(0,0,0,0)"
                tile
            >
                <footer-variable
                    v-for="(ftVar, index) in newFooterVariables"
                    :key="index"
                    :edit="$store.state.footerEdit"
                    :variableid="ftVar"
                    :index="index"
                    @delete="deleteFooterVariable"
                    @update="updateFooterVariable"
                ></footer-variable>
                <i
                    class="fas fa-plus-circle fa-2x accent--text"
                    style="cursor:pointer"
                    @click.stop="browseFooterVariable = true"
                ></i>
                <SymconObjectBrowser
                    v-if="$store.state.footerEdit"
                    v-model="browseFooterVariable"
                    :types="[$CONST.OBJECTTYPE.VARIABLE]"
                    :root="$store.state.rootID"
                    @selected="addFooterVariable"
                ></SymconObjectBrowser>
            </v-card>
        </v-footer>
    </div>
</template>

<script>
import FooterVariable from '@/components/tiles/FooterVariable';
import SymconObjectBrowser from '@/components/SymconObjectBrowser';

export default {
    components: {
        FooterVariable,
        SymconObjectBrowser
    },
    data() {
        return {
            holdInterval: false,
            holdCount: 0,
            footerVariables: [],
            newFooterVariables: [],
            browseFooterVariable: false
        };
    },
    methods: {
        startHold() {
            if (
                !this.holdInterval &&
                this.$store.state.settingsPassword &&
                this.$store.state.isPasswordValid
            ) {
                this.holdInterval = setInterval(() => {
                    this.holdCount++;
                    if (this.holdCount >= 15) {
                        this.stopHold();
                        this.$store.state.footerEdit = true;
                    }
                }, 100);
            }
        },
        stopHold() {
            clearInterval(this.holdInterval);
            this.holdInterval = false;
            this.holdCount = 0;
        },
        deleteFooterVariable(index) {
            this.newFooterVariables.splice(index, 1);
        },
        updateFooterVariable(index, value) {
            this.$set(this.newFooterVariables, index, value);
        },
        addFooterVariable(id) {
            this.newFooterVariables.push(id);
        },
        cancelFooterEdit() {
            this.newFooterVariables = this.footerVariables.slice();
            this.$store.state.footerEdit = false;
        },
        async saveFooterEdit() {
            this.footerVariables = this.newFooterVariables.slice();
            const vars = [];
            this.newFooterVariables.forEach(value => {
                vars.push({ id: value, name: '' });
            });
            try {
                const success = await this.$api.syncFooterVars(vars);
                if (success) {
                    this.$store.state.footerEdit = false;
                } else {
                    this.$localNotifier.addNewNotification(
                        'Beim Speichern der Änderungen ist ein Fehler aufgetreten.'
                    );
                }
            } catch (error) {
                this.$localNotifier.addNewNotification(error.message, error);
            }
        }
    },
    beforeMount() {
        // get FooterVariables
        const folder = Object.filter(
            this.$store.state.snapshot.objects,
            object =>
                object.parentID == this.$store.state.rootID &&
                object.name == 'SystemFooter'
        );
        const folderKeys = Object.keys(folder);
        if (folderKeys.length === 0) {
            this.$localNotifier.addNewNotification(
                'Fehlender Speicherort für Fusszeileninhalt.'
            );
            this.footerVariables = [];
            this.newFooterVariables = [];
        } else {
            const folderID = folderKeys[0].substring(2);
            const footerVariables = Object.filter(
                this.$store.state.snapshot.objects,
                object => object.parentID == folderID && !object.hidden
            );
            const footvars = [];
            Object.keys(footerVariables).forEach(key => {
                footerVariables[key].id = key.substring(2);
                footvars.push(footerVariables[key]);
            });
            footvars.sort((a, b) => (a.position > b.position ? 1 : -1));
            footvars.map(vari => vari.id);
            this.footerVariables = footvars.map(vari => parseInt(vari.id));
            this.newFooterVariables = this.footerVariables.slice();
        }
    }
};
</script>
<style>
footer {
    letter-spacing: 0;
}
</style>
