<template>
    <div v-if="showUpdateDialog" class="text-center">
        <v-dialog persistent v-model="showUpdateDialog" width="500">
            <v-card>
                <v-card-title>
                    Update verfügbar
                </v-card-title>

                <v-card-text>
                    <div>
                        <span>
                            Eine neue Version des Knockaut Basis Modul ist
                            verfügbar. Um die App weiterhin verwenden zu können,
                            müssen Sie das Modul aktualisieren.<br />
                            Aktuell installierte Modul-Version:<br />
                            V{{ appInfo.Version }} B{{ appInfo.Build }}-{{
                                appInfo.Date
                            }}
                        </span>
                        <span>
                            <v-progress-circular
                                v-if="isUpdateRunning"
                                indeterminate
                                :width="2"
                            >
                                <v-dialog
                                    v-model="isUpdateRunning"
                                    persistent
                                    width="300"
                                >
                                    <v-card color="secondary" dark>
                                        <v-card-text>
                                            <p class="py-3">
                                                Aktualisiere Modul. Bitte warten
                                                Sie bis das Update fertig ist
                                                und schalten Sie den Controller
                                                nicht aus.
                                            </p>
                                            <v-progress-linear
                                                indeterminate
                                                color="primary"
                                                class="mb-0"
                                            ></v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </v-progress-circular>
                        </span>
                        <v-row justify="center">
                            <v-dialog v-model="updateInfo" max-width="290">
                                <v-card>
                                    <v-card-title class="headline"
                                        >Aktualisiert</v-card-title
                                    >
                                    <v-card-text
                                        >Starten Sie die App neu um die neuesten
                                        Änderungen zu übernehmen.</v-card-text
                                    >
                                </v-card>
                            </v-dialog>
                        </v-row>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="updateModule">
                        Aktualisieren
                    </v-btn>
                    <v-btn color="grey" text @click="showUpdateDialog = false">
                        Schliessen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import packageInfo from '../../package.json';
export default {
    name: 'ModuleUpdater',
    data() {
        return {
            showUpdateDialog: false,
            appInfo: {},
            isUpdateRunning: false,
            updateInfo: false
        };
    },
    methods: {
        async checkModuleVersion() {
            try {
                this.appInfo = await this.$api.getAppInfo();
                if (this.appInfo.updateAvailable) {
                    this.showUpdateDialog = true;
                } else {
                    this.showUpdateDialog = false;
                }
            } catch (e) {
                this.appInfo = {
                    Version: '0',
                    Build: 0,
                    Date: '20220101',
                    updateAvailable: false
                };
            }
        },
        async updateModule() {
            this.isUpdateRunning = true;
            try {
                await this.$api
                    .updateApp(packageInfo.version)
                    .then(() => {})
                    .finally(() => {
                        this.isUpdateRunning = false;
                        setTimeout(() => {
                            this.updateInfo = false;
                            this.showUpdateDialog = false;
                        }, 6000);
                        this.updateInfo = true;
                    });
                this.appInfo.updateAvailable = false;
            } catch (e) {
                this.isUpdateRunning = false;
                this.updateInfo = false;
            }
        }
    },
    mounted() {
        this.checkModuleVersion();
    }
};
</script>
