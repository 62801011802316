import store from '../store/index';
//import { OBJECTTYPE, VARIABLETYPE } from './constants'; // Throws WEBPACK import error!

export const OBJECTTYPE = {
    CATEGORY: 0,
    INSTANCE: 1,
    VARIABLE: 2,
    SCRIPT: 3,
    EVENT: 4,
    MEDIA: 5,
    LINK: 6
};

export const VARIABLETYPE = {
    BOOLEAN: 0,
    INTEGER: 1,
    FLOAT: 2,
    STRING: 3
};

export const AdaptiveIcons = {
    Battery: [0, 50, 100],
    Door: [0, 100],
    Energystorage: [0, 25, 50, 75, 100],
    Eyes: [0, 100],
    Floorlamp: [0, 100],
    Garage: [0, 25, 100],
    Hourglass: [0, 30, 60, 100],
    Intensity: [0, 25, 50, 75, 100],
    Jalousie: [0, 50, 100],
    Light: [0, 1, 25, 50, 75, 100],
    Lock: [0, 100],
    Presence: [0, 100],
    Raffstore: [0, 50, 100],
    Speaker: [0, 1, 25, 50, 100],
    Speedo: [0, 25, 50, 75, 100],
    Temperature: [0, 25, 50, 75, 100],
    Window: [0, 100],
    Windspeed: [0, 30, 60, 100]
};

export function mapIconName(name) {
    if (symconMapping[name]) {
        return symconMapping[name];
    }
    return name;
}

export function getIcon(object, findOrFail = false, rawName = false) {
    // object can be either a snapshot-object (object), an ObjectID (int), or just an icon name (string)
    const iconName = getIconLocal(object, findOrFail);
    if (typeof iconName === 'boolean') {
        return iconName;
    }
    if (rawName) {
        return iconName;
    }
    return 'fa-' + iconName;
}

export function getIconLocal(data, findOrFail = false) {
    let iconName = '';
    if (Number.isNaN(parseInt(data)) && typeof data === 'string') {
        // STRING INPUT
        // icon name as received
        iconName = data;
    } else {
        if (!Number.isNaN(parseInt(data))) {
            // NUMBER INPUT
            // get SnapshotObject by objectID
            const objectID = data;
            data = store.state.snapshot.objects['ID' + objectID];
            if (!data) {
                return '';
            }
        }
        // OBJECT INPUT or object found from number input.
        let object = data;
        // 1. get icon from object itself
        iconName = object.icon;
        if (!iconName) {
            // 2. get icon from link-target
            if (object.type === OBJECTTYPE.LINK) {
                object =
                    store.state.snapshot.objects['ID' + object.data.targetID];
                iconName = object.icon;
            }
            if (!iconName) {
                // 3. get icon by object-type
                let profileName = '';
                switch (object.type) {
                    case OBJECTTYPE.CATEGORY:
                        iconName = findOrFail ? '' : 'Door';
                        break;
                    case OBJECTTYPE.INSTANCE:
                        iconName = findOrFail ? '' : 'Plug';
                        break;
                    case OBJECTTYPE.VARIABLE:
                        // find variable profile
                        profileName = object.data.profile;
                        if (object.data.customProfile) {
                            profileName = object.data.customProfile;
                        }
                        if (profileName) {
                            const profile =
                                store.state.snapshot.profiles[profileName];
                            // 4. get icon by profile association
                            if (
                                profile &&
                                Array.isArray(profile.associations) &&
                                profile.associations.length > 0
                            ) {
                                let association = undefined;
                                let value = undefined;
                                // typecast value
                                switch (object.data.type) {
                                    case VARIABLETYPE.BOOLEAN:
                                        value = Boolean(object.data.value);
                                        break;
                                    case VARIABLETYPE.FLOAT:
                                        value = parseFloat(object.data.value);
                                        break;
                                    case VARIABLETYPE.INTEGER:
                                        value = parseInt(object.data.value);
                                        break;
                                    case VARIABLETYPE.STRING:
                                        value = String(object.data.value);
                                        break;
                                }
                                if (object.data.type !== VARIABLETYPE.FLOAT) {
                                    // search exact match
                                    association = profile.associations.find(
                                        assoc => {
                                            return assoc.value === value;
                                        }
                                    );
                                } else {
                                    // search nearest
                                    association = profile.associations.reduce(
                                        (nearest, obj) => {
                                            return Math.abs(value - obj.value) <
                                                Math.abs(value - nearest.value)
                                                ? obj
                                                : nearest;
                                        }
                                    );
                                }
                                iconName = association ? association.icon : '';
                            }
                            // 5. get defined icon from profile
                            if (!iconName) {
                                if (profile) {
                                    iconName = profile.icon;
                                    // if profile icon exists and icon is adaptive,
                                    // then try to get adaptive icon
                                    if (
                                        !!iconName &&
                                        !!AdaptiveIcons[iconName]
                                    ) {
                                        if (
                                            profile.type !== VARIABLETYPE.STRING
                                        ) {
                                            // "+" converts bool, int and float to number
                                            const absValue = +object.data.value;
                                            let percentValue = -1;
                                            if (
                                                [
                                                    VARIABLETYPE.INTEGER,
                                                    VARIABLETYPE.FLOAT
                                                ].includes(profile.type)
                                            ) {
                                                const v = {
                                                    min: profile.minValue,
                                                    max: profile.maxValue,
                                                    x: absValue
                                                };
                                                if (v.max > v.min) {
                                                    percentValue =
                                                        ((v.x - v.min) /
                                                            (v.max - v.min)) *
                                                        100;
                                                }
                                            } else {
                                                // boolean
                                                percentValue = absValue * 100;
                                            }
                                            // if percent is valid, get specific version of the icon.
                                            // otherwise leave the icon in default version.
                                            if (percentValue >= 0) {
                                                // search nearest
                                                const iconValue = AdaptiveIcons[
                                                    iconName
                                                ].reduce((nearest, numb) => {
                                                    return Math.abs(
                                                        percentValue - numb
                                                    ) <
                                                        Math.abs(
                                                            percentValue -
                                                            nearest
                                                        )
                                                        ? numb
                                                        : nearest;
                                                });
                                                iconName += '-' + iconValue;
                                            }
                                        }
                                    }
                                }
                            }
                            // 6. set fallback icon
                            if (!iconName) {
                                iconName = findOrFail ? '' : 'Minus';
                            }
                        } else {
                            iconName = findOrFail ? '' : 'Minus';
                        }
                        break;
                    case OBJECTTYPE.SCRIPT:
                        iconName = 'Script';
                        break;
                    case OBJECTTYPE.EVENT:
                        iconName = findOrFail ? '' : 'Clock';
                        break;
                    case OBJECTTYPE.MEDIA:
                        iconName = 'Image';
                        break;
                    case OBJECTTYPE.LINK:
                        iconName = findOrFail ? '' : 'Link';
                        break;
                }
            }
        }
    }
    if (!iconName && findOrFail) {
        return false;
    }
    return mapIconName(iconName);
}

export const symconMapping = {
    Aircraft: 'plane-up',
    Alert: 'bell-on',
    ArrowRight: 'right-long',
    Backspace: 'caret-left',
    Basement: 'house',
    Bath: 'bath',
    Battery: 'car-battery',
    Bed: 'bed',
    Bike: 'bicycle',
    Book: 'book',
    Bulb: 'lightbulb',
    Calendar: 'calendar-day',
    Camera: 'camera',
    Car: 'car-side',
    Caret: 'i',
    Cat: 'cat',
    Climate: 'arrows-spin',
    Clock: 'clock',
    Close: 'xmark',
    CloseAll: 'xmark-large',
    Cloud: 'cloud',
    Cloudy: 'clouds',
    Cocktail: 'martini-glass',
    Cross: 'xmark',
    Database: 'database',
    Dining: 'utensils',
    Distance: 'arrows-left-right-to-line',
    DoctorBag: 'briefcase-medical',
    Dog: 'dog',
    Doll: 'nesting-dolls',
    Dollar: 'dollar-sign',
    Door: 'door-closed',
    Download: 'download',
    Drops: 'raindrops',
    Duck: 'duck',
    Edit: 'pen',
    Electricity: 'bolt',
    EnergyProduction: 'circle-bolt',
    EnergySolar: 'solar-panel',
    EnergyStorage: 'square-bolt',
    ErlenmeyerFlask: 'flask-round-potion',
    Euro: 'euro-sign',
    Execute: 'hand-back-point-up',
    Eyes: 'eye',
    Factory: 'industry',
    Favorite: 'star',
    Female: 'venus',
    Fitness: 'dumbbell',
    Flag: 'flag',
    Flame: 'fire-flame-curved',
    FloorLamp: 'lamp-floor',
    Flower: 'flower',
    Fog: 'cloud-fog',
    Garage: 'garage',
    Gas: 'gas-pump',
    Gauge: 'gauge',
    Gear: 'wrench-simple',
    Graph: 'chart-line',
    GroundFloor: 'house',
    Handicap: 'wheelchair',
    Heart: 'heart',
    Help: 'note-medical',
    HollowArrowDown: 'chevron-down',
    HollowArrowLeft: 'chevron-left',
    HollowArrowRight: 'chevron-right',
    HollowArrowUp: 'chevron-up',
    HollowDoubleArrowDown: 'chevrons-down',
    HollowDoubleArrowLeft: 'chevrons-left',
    HollowDoubleArrowRight: 'chevrons-right',
    HollowDoubleArrowUp: 'chevrons-up',
    HollowLargeArrowDown: 'angle-down',
    HollowLargeArrowLeft: 'angle-left',
    HollowLargeArrowRight: 'angle-right',
    HollowLargeArrowUp: 'angle-up',
    Hourglass: 'hourglass-half',
    HouseRemote: 'house-signal',
    Image: 'image',
    Information: 'info',
    Intensity: 'signal',
    Internet: 'globe',
    IPS: 'house',
    Jalousie: 'shutters',
    Key: 'key',
    Keyboard: 'keyboard',
    Kitchen: 'kitchen-set',
    Leaf: 'leaf',
    Light: 'lightbulb',
    Lightning: 'cloud-bolt',
    Link: 'share',
    Lock: 'lock-keyhole',
    LockClosed: 'lock',
    LockOpen: 'lock-open',
    Macro: 'puzzle',
    Mail: 'envelope',
    Male: 'mars',
    Melody: 'music',
    Menu: 'grid',
    Minus: 'minus',
    Mobile: 'mobile',
    Moon: 'moon',
    Motion: 'person-running',
    Move: 'arrows-up-down-left-right',
    Music: 'music',
    Network: 'network-wired',
    Notebook: 'laptop',
    Ok: 'check',
    Pacifier: 'baby',
    Paintbrush: 'paintbrush',
    Pants: '',
    Party: 'champagne-glass',
    People: 'users',
    Plug: 'plug',
    Plus: 'plus',
    Popcorn: 'popcorn',
    Power: 'power-off',
    Presence: 'house-user',
    Radiator: 'heat',
    Raffstore: 'blinds-open',
    Rainfall: 'cloud-showers',
    Recycling: 'recycle',
    Remote: 'mobile-signal-out',
    Repeat: 'rotate-right',
    Return: 'arrow-turn-down-left',
    Robot: 'robot',
    Rocket: 'rocket',
    Script: 'caret-right',
    Shift: 'arrow-up',
    Shower: 'shower',
    Shuffle: 'shuffle',
    Shutter: 'sort',
    Sink: 'sink',
    Sleep: 'snooze',
    Sleet: 'cloud-sleet',
    Snow: 'cloud-snow',
    Snowflake: 'snowflake',
    Sofa: 'couch',
    Speaker: 'volume',
    Speedo: 'gauge-simple',
    Stars: 'stars',
    Sun: 'sun',
    Sunny: 'cloud-sun',
    Talk: 'message-dots',
    Tap: 'faucet-drip',
    Teddy: 'teddy-bear',
    Tee: 'shirt',
    Telephone: 'phone',
    Temperature: 'temperature-full',
    Thunder: 'cloud-bolt',
    Title: 't',
    TopFloor: 'house',
    Tree: 'tree-deciduous',
    TurnLeft: 'rotate-left',
    TurnRight: 'rotate-right',
    TV: 'tv',
    Umbrella: 'umbrella',
    Unicorn: 'unicorn',
    Ventilation: 'fan',
    Warning: 'triangle-exclamation',
    Wave: 'water',
    WC: 'toilet',
    Wellness: 'tree-palm',
    WindDirection: 'compass',
    Window: 'window-frame',
    WindSpeed: 'windsock',
    XBMC: 'film',

    // Adaptive Icons
    'Battery-0': 'battery-empty',
    'Battery-50': 'battery-half',
    'Battery-100': 'battery-full',
    'Door-0': 'door-open',
    'Door-100': 'door-closed',
    'EnergyStorage-0': 'square-bolt',
    'EnergyStorage-25': 'square-bolt',
    'EnergyStorage-50': 'square-bolt',
    'EnergyStorage-75': 'square-bolt',
    'EnergyStorage-100': 'square-bolt',
    'Eyes-0': 'eye-slash',
    'Eyes-100': 'eye',
    'FloorLamp-0': 'lamp-floor',
    'FloorLamp-100': 'lamp-floor',
    'Garage-0': 'garage-car',
    'Garage-25': 'garage-open',
    'Garage-100': 'garage',
    'Hourglass-0': 'hourglass-half',
    'Hourglass-30': 'hourglass-half',
    'Hourglass-60': 'hourglass-half',
    'Hourglass-100': 'hourglass-half',
    'Intensity-0': 'signal-weak',
    'Intensity-25': 'signal-fair',
    'Intensity-50': 'signal-good',
    'Intensity-75': 'signal-strong',
    'Intensity-100': 'signal',
    'Jalousie-0': 'blinds-raised',
    'Jalousie-50': 'blinds-raised',
    'Jalousie-100': 'blinds',
    'Light-0': 'lightbulb',
    'Light-1': 'lightbulb-on',
    'Light-25': 'lightbulb-on',
    'Light-50': 'lightbulb-on',
    'Light-75': 'lightbulb-on',
    'Light-100': 'lightbulb-on',
    'Lock-0': 'lock-keyhole-open',
    'Lock-100': 'lock-keyhole',
    'Presence-0': 'house-blank',
    'Presence-100': 'house-user',
    'Raffstore-0': 'blinds-raised',
    'Raffstore-50': 'blinds-raised',
    'Raffstore-100': 'blinds',
    'Speaker-0': 'volume-xmark',
    'Speaker-1': 'volume-off',
    'Speaker-25': 'volume-low',
    'Speaker-50': 'volume',
    'Speaker-100': 'volume-high',
    'Speedo-0': 'gauge-simple-min',
    'Speedo-25': 'gauge-simple-low',
    'Speedo-50': 'gauge-simple',
    'Speedo-75': 'gauge-simple-high',
    'Speedo-100': 'gauge-simple-max',
    'Temperature-0': 'temperature-empty',
    'Temperature-25': 'temperature-quarter',
    'Temperature-50': 'temperature-half',
    'Temperature-75': 'temperature-three-quarters',
    'Temperature-100': 'temperature-full',
    'Window-0': 'window-frame-open',
    'Window-100': 'window-frame',
    'WindSpeed-0': 'windsock',
    'WindSpeed-30': 'windsock',
    'WindSpeed-60': 'windsock',
    'WindSpeed-100': 'windsock',
    'Clock-12-00': 'clock-twelve',
    'Clock-12-05': 'clock-twelve',
    'Clock-12-10': 'clock-twelve',
    'Clock-12-15': 'clock-twelve',
    'Clock-12-20': 'clock-twelve',
    'Clock-12-25': 'clock-twelve',
    'Clock-12-30': 'clock-twelve-thirty',
    'Clock-12-35': 'clock-twelve-thirty',
    'Clock-12-40': 'clock-twelve-thirty',
    'Clock-12-45': 'clock-twelve-thirty',
    'Clock-12-50': 'clock-twelve-thirty',
    'Clock-12-55': 'clock-twelve-thirty',
    'Clock-13-00': 'clock-one',
    'Clock-13-05': 'clock-one',
    'Clock-13-10': 'clock-one',
    'Clock-13-15': 'clock-one',
    'Clock-13-20': 'clock-one',
    'Clock-13-25': 'clock-one',
    'Clock-13-30': 'clock-one-thirty',
    'Clock-13-35': 'clock-one-thirty',
    'Clock-13-40': 'clock-one-thirty',
    'Clock-13-45': 'clock-one-thirty',
    'Clock-13-50': 'clock-one-thirty',
    'Clock-13-55': 'clock-one-thirty',
    'Clock-14-00': 'clock-two',
    'Clock-14-05': 'clock-two',
    'Clock-14-10': 'clock-two',
    'Clock-14-15': 'clock-two',
    'Clock-14-20': 'clock-two',
    'Clock-14-25': 'clock-two',
    'Clock-14-30': 'clock-two-thirty',
    'Clock-14-35': 'clock-two-thirty',
    'Clock-14-40': 'clock-two-thirty',
    'Clock-14-45': 'clock-two-thirty',
    'Clock-14-50': 'clock-two-thirty',
    'Clock-14-55': 'clock-two-thirty',
    'Clock-15-00': 'clock-three',
    'Clock-15-05': 'clock-three',
    'Clock-15-10': 'clock-three',
    'Clock-15-15': 'clock-three',
    'Clock-15-20': 'clock-three',
    'Clock-15-25': 'clock-three',
    'Clock-15-30': 'clock-three-thirty',
    'Clock-15-35': 'clock-three-thirty',
    'Clock-15-40': 'clock-three-thirty',
    'Clock-15-45': 'clock-three-thirty',
    'Clock-15-50': 'clock-three-thirty',
    'Clock-15-55': 'clock-three-thirty',
    'Clock-16-00': 'clock-four',
    'Clock-16-05': 'clock-four',
    'Clock-16-10': 'clock-four',
    'Clock-16-15': 'clock-four',
    'Clock-16-20': 'clock-four',
    'Clock-16-25': 'clock-four',
    'Clock-16-30': 'clock-four-thirty',
    'Clock-16-35': 'clock-four-thirty',
    'Clock-16-40': 'clock-four-thirty',
    'Clock-16-45': 'clock-four-thirty',
    'Clock-16-50': 'clock-four-thirty',
    'Clock-16-55': 'clock-four-thirty',
    'Clock-17-00': 'clock-five',
    'Clock-17-05': 'clock-five',
    'Clock-17-10': 'clock-five',
    'Clock-17-15': 'clock-five',
    'Clock-17-20': 'clock-five',
    'Clock-17-25': 'clock-five',
    'Clock-17-30': 'clock-five-thirty',
    'Clock-17-35': 'clock-five-thirty',
    'Clock-17-40': 'clock-five-thirty',
    'Clock-17-45': 'clock-five-thirty',
    'Clock-17-50': 'clock-five-thirty',
    'Clock-17-55': 'clock-five-thirty',
    'Clock-18-00': 'clock-six',
    'Clock-18-05': 'clock-six',
    'Clock-18-10': 'clock-six',
    'Clock-18-15': 'clock-six',
    'Clock-18-20': 'clock-six',
    'Clock-18-25': 'clock-six',
    'Clock-18-30': 'clock-six-thirty',
    'Clock-18-35': 'clock-six-thirty',
    'Clock-18-40': 'clock-six-thirty',
    'Clock-18-45': 'clock-six-thirty',
    'Clock-18-50': 'clock-six-thirty',
    'Clock-18-55': 'clock-six-thirty',
    'Clock-19-00': 'clock-seven',
    'Clock-19-05': 'clock-seven',
    'Clock-19-10': 'clock-seven',
    'Clock-19-15': 'clock-seven',
    'Clock-19-20': 'clock-seven',
    'Clock-19-25': 'clock-seven',
    'Clock-19-30': 'clock-seven-thirty',
    'Clock-19-35': 'clock-seven-thirty',
    'Clock-19-40': 'clock-seven-thirty',
    'Clock-19-45': 'clock-seven-thirty',
    'Clock-19-50': 'clock-seven-thirty',
    'Clock-19-55': 'clock-seven-thirty',
    'Clock-20-00': 'clock-eight',
    'Clock-20-05': 'clock-eight',
    'Clock-20-10': 'clock-eight',
    'Clock-20-15': 'clock-eight',
    'Clock-20-20': 'clock-eight',
    'Clock-20-25': 'clock-eight',
    'Clock-20-30': 'clock-eight-thirty',
    'Clock-20-35': 'clock-eight-thirty',
    'Clock-20-40': 'clock-eight-thirty',
    'Clock-20-45': 'clock-eight-thirty',
    'Clock-20-50': 'clock-eight-thirty',
    'Clock-20-55': 'clock-eight-thirty',
    'Clock-21-00': 'clock-nine',
    'Clock-21-05': 'clock-nine',
    'Clock-21-10': 'clock-nine',
    'Clock-21-15': 'clock-nine',
    'Clock-21-20': 'clock-nine',
    'Clock-21-25': 'clock-nine',
    'Clock-21-30': 'clock-nine-thirty',
    'Clock-21-35': 'clock-nine-thirty',
    'Clock-21-40': 'clock-nine-thirty',
    'Clock-21-45': 'clock-nine-thirty',
    'Clock-21-50': 'clock-nine-thirty',
    'Clock-21-55': 'clock-nine-thirty',
    'Clock-22-00': 'clock-ten',
    'Clock-22-05': 'clock-ten',
    'Clock-22-10': 'clock-ten',
    'Clock-22-15': 'clock-ten',
    'Clock-22-20': 'clock-ten',
    'Clock-22-25': 'clock-ten',
    'Clock-22-30': 'clock-ten-thirty',
    'Clock-22-35': 'clock-ten-thirty',
    'Clock-22-40': 'clock-ten-thirty',
    'Clock-22-45': 'clock-ten-thirty',
    'Clock-22-50': 'clock-ten-thirty',
    'Clock-22-55': 'clock-ten-thirty',
    'Clock-23-00': 'clock-eleven',
    'Clock-23-05': 'clock-eleven',
    'Clock-23-10': 'clock-eleven',
    'Clock-23-15': 'clock-eleven',
    'Clock-23-20': 'clock-eleven',
    'Clock-23-25': 'clock-eleven',
    'Clock-23-30': 'clock-eleven-thirty',
    'Clock-23-35': 'clock-eleven-thirty',
    'Clock-23-40': 'clock-eleven-thirty',
    'Clock-23-45': 'clock-eleven-thirty',
    'Clock-23-50': 'clock-eleven-thirty',
    'Clock-23-55': 'clock-eleven-thirty'
};
