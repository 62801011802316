export default {
    data() {
        return {
            none: null
        };
    },
    methods: {
        icon(iconName) {
            iconName = iconName ? iconName : 'Script';
            const icon =
                this.$store.getters.httpHost +
                '/img/icons/' +
                iconName +
                '.svg';
            return icon;
        },
        /**
         * Get all objetzs of a given type which are children or lower of the given root element from the IPS Instance-Tree.
         * @param {*} parentID The IPS Object-ID of the root element from which the children shoulld be returned.
         * @param {*} type The IPPS Object-Type of the returning elements. (f.ex. 6=Links).
         * @param {*} asFlatArray Returns a flatten Array of objects if true. Otherwise it returns an object tree.
         */
        getChildObjectsRecursively(parentID, type = 0, ignoreHidden = false) {
            let objectsArray = [];
            let objects = Object.filter(
                this.$store.state.snapshot.objects,
                object =>
                    object.type == type &&
                    object.parentID == parentID &&
                    (!object.hidden || ignoreHidden)
            );
            let folders = Object.filter(
                this.$store.state.snapshot.objects,
                object =>
                    object.type == 0 &&
                    object.parentID == parentID &&
                    (!object.hidden || ignoreHidden)
            );
            for (const key in objects) {
                objects[key].id = key.substring(2);
                objectsArray.push(objects[key]);
            }
            if (Object.keys(folders).length >= 0) {
                for (const key in folders) {
                    folders[key].childObjects = this.getChildObjectsRecursively(
                        key.substring(2),
                        type
                    );
                    for (const k in folders[key].childObjects) {
                        folders[key].childObjects[k].id = k.substring(2);
                        folders[key].childObjects[k].tiletype =
                            type == 0 ? 'categories' : '';
                        objectsArray.push(folders[key].childObjects[k]);
                    }
                }
            }
            return objectsArray;
        }
    }
};
