<template>
    <v-row no-gutters>
        <v-col cols="12" sm="12">
            <v-card
                flat
                tile
                max-height="200px"
                :style="{
                    padding:
                        $vuetify.breakpoint.name == 'xs'
                            ? '12px 3vw 0 3vw'
                            : '12px 2vw 0 2vw'
                }"
                style="padding-bottom: 0"
                :color="'rgb(255, 255, 255, 0.0)'"
            >
                <div
                    style="width: 4px; height: 30px; float: left; padding-right: 0; margin-top: 5px"
                    v-if="breadcrumbs.slice(1).length > 0"
                    :class="{
                        primary: breadcrumbs.slice(1).length == 1
                    }"
                    class="grey darken-1"
                ></div>
                <v-breadcrumbs
                    v-if="breadcrumbs.slice(1).length > 0"
                    :items="breadcrumbs.slice(1)"
                    large
                    class="py-1 pl-3"
                >
                    <template v-slot:divider>
                        <span></span>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="{
                                name: 'Home',
                                params: {
                                    ...$route.params,
                                    roomId: item.id
                                }
                            }"
                            :disabled="item.disabled"
                        >
                            <div
                                v-if="breadcrumbs.slice(1).indexOf(item) > 0"
                                style="width:4px; height:30px; position: relative; left: -12px"
                                :class="{
                                    'grey darken-1': !item.disabled,
                                    primary: item.disabled
                                }"
                            ></div>
                            <span style="line-height: 32px">{{
                                item.text
                            }}</span>
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'Breadcrumbs',
    data() {
        return {
            breadcrumbs: []
        };
    },
    computed: {
        newVirtualRouteSignal() {
            return this.$store.state.newVirtualRouteSignal;
        }
    },
    watch: {
        newVirtualRouteSignal: {
            handler(update) {
                if (update) {
                    if (isNaN(this.$route.params.roomId)) {
                        return;
                    }
                    const targetID = this.$route.params.roomId;
                    this.refreshBreadcrumbs(targetID);
                }
            },
            immediate: true
        }
    },
    methods: {
        refreshBreadcrumbs(currentID) {
            this.breadcrumbs.splice(0);
            const rootID = this.$store.getters.toValidIntID(
                this.$store.state.rootID
            );
            const itemID = this.$store.getters.toValidIntID(currentID);
            if (rootID === false || itemID === false) {
                return;
            } else {
                const ancestorChain = this.$store.getters.getAncestors(
                    currentID,
                    rootID
                );
                console.log(ancestorChain);
                console.log(itemID);
                ancestorChain.map(obj => {
                    this.breadcrumbs.push({
                        disabled: obj.id === itemID,
                        id: obj.id,
                        text: obj.name ? obj.name : '' + obj.id
                    });
                });
            }
            this.$store.commit('resetVirtualRouteSignal');
        }
    }
};
</script>
<style>
.v-breadcrumbs li a.v-breadcrumbs__item {
    color: grey !important;
}
.v-breadcrumbs li a.v-breadcrumbs__item--disabled {
    color: #b5e10a !important;
}
</style>
