import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { getRandomInt } from '../util/random';

export const addPushNotificationListeners = async store => {
    await PushNotifications.addListener('registration', token => {
        console.log('Registration token: ', token);
        store.dispatch('notificationModule/setToken', token.value);
    });

    await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
        store.dispatch('notificationModule/setRegistrationError', err);
    });

    await PushNotifications.addListener(
        'pushNotificationReceived',
        async notification => {
            console.log('Push notification received: ', notification);
            store.dispatch(
                'notificationModule/pushNotificationReceived',
                notification
            );
            /* If data is present, it looks like this:
                body: <string>
                categoryID: <string>
                configuratorID: <string>
                message: <string>
                sound: <string>
                targetID: <string>
                title: <string>
                token: <string>
            */
            if (notification.data) {
                await LocalNotifications.schedule({
                    notifications: [
                        {
                            title: notification.data.title,
                            body: notification.data.message,
                            id: getRandomInt(100000),
                            sound: null,
                            attachments: null,
                            actionTypeId: '',
                            extra: null
                        }
                    ]
                });
            }
        }
    );

    await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        notification => {
            console.log(
                'Push notification action performed',
                notification.actionId,
                notification.inputValue
            );
            store.dispatch(
                'notificationModule/pushNotificationActionPerformed',
                notification
            );
        }
    );
};

export const registerPushNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
        throw new Error('Keine Berechtigung!');
    }

    await PushNotifications.register();
};

export const getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
};
