/**
 * Checks if instance moduleID corresponds to one of the uuids
 * @param {*} instance symcon object instance
 * @param {*} uuids list of strings of module GUIDS
 * @returns true if instance of one of the modules, false otherwise
 */
export function isInstanceOfModules(instance, uuids) {
    if (instance.data && instance.data.target && instance.data.target.data) {
        if (Array.isArray(uuids)) {
            return uuids.includes(instance.data.target.data.moduleID);
        } else {
            return instance.data.target.data.moduleID === uuids;
        }
    } else {
        return false;
    }
}

export function getModuleByGuid(guid = '{}', rootStoreGetters) {
    let module = Object.filter(
        rootStoreGetters.snapshotObjects,
        object =>
            object.data && object.data.moduleID && object.data.moduleID == guid
    );
    if (Object.keys(module).length === 0) {
        return null;
    } else {
        const id = Object.keys(module)[0];
        module = module[id];
        module.id = id.substring(2);
        return module;
    }
}

/**
 * Checks if instance is part of the KnockautX product line
 * @param {*} instance symcon object instance to check
 * @returns true if KnockautX Product, false otherwise
 */
export function isKnockautXInstance(instance) {
    return isInstanceOfModules(instance, [
        KNOCKAUT_X_Co2Sensor_MODULE_ID,
        KNOCKAUT_X_Dimmaktor_MODULE_ID,
        KNOCKAUT_X_DoorWindowSensor_MODULE_ID,
        KNOCKAUT_X_IndoorSiren_MODULE_ID,
        KNOCKAUT_X_KnobDimmerSwitch_MODULE_ID,
        KNOCKAUT_X_KnobDimmerSwitchWhite_MODULE_ID,
        KNOCKAUT_X_LED_RGB_E14_6W_MODULE_ID,
        KNOCKAUT_X_LED_RGB_E27_MODULE_ID,
        KNOCKAUT_X_LED_RGB_GU10_MODULE_ID,
        KNOCKAUT_X_MasterGatewayTWO_MODULE_ID,
        KNOCKAUT_X_MotionSensor_MODULE_ID,
        KNOCKAUT_X_PluginReceiverMulti_MODULE_ID,
        KNOCKAUT_X_Repeater_MODULE_ID,
        KNOCKAUT_X_Schaltaktor_MODULE_ID,
        KNOCKAUT_X_SchaltSteckdose_MODULE_ID,
        KNOCKAUT_X_SmartElectricMeter_MODULE_ID,
        KNOCKAUT_X_SmokeAlarm_MODULE_ID,
        KNOCKAUT_X_TemperatureHumiditySensor_MODULE_ID,
        KNOCKAUT_X_TemperatureHumiditySensorLCD_MODULE_ID,
        KNOCKAUT_X_Thermostat_MODULE_ID,
        KNOCKAUT_X_VibrationSensor_MODULE_ID,
        KNOCKAUT_X_WallThermostate_MODULE_ID,
        KNOCKAUT_X_WaterSensor_MODULE_ID,
        KNOCKAUT_X_WirelessSwitch1Channel_MODULE_ID,
        KNOCKAUT_X_WirelessSwitch4Channel_MODULE_ID,
        KNOCKAUT_X_WirelessSwitchMini1Channel_MODULE_ID
    ]);
}

// control tile sections
export const CONTROL_TILE_SECTIONS = {
    prependVar: 'prepend-var',
    prepend: 'prepend',
    name: 'name',
    controls: 'controls',
    append: 'append'
};

// control tile scale types
export const CONTROL_TILE_SCALES = {
    ratio: 'ratio',
    width: 'width',
    height: 'height'
};

// general device idents
export const IDENTS = {
    KNOCKAUT_X_SWITCH: 'switch',
    KNOCKAUT_X_SWITCH_LED: 'switch_led',
    KNOCKAUT_X_SWITCH_1: 'switch_1',
    KNOCKAUT_X_SWITCH_LED_1: 'switch_led_1',
    DOMINOSWISS_SWITCH: 'Switch',
    DOMINOSWISS_STATUS: 'Status',
    DOMINOSWISS_WARNING: 'Warning',
    DOMINOSWISS_SAVING: 'Saving',
    PHILIPS_HUE_STATE: 'HUE_State',
    PHILIPS_HUE_V2_STATE: 'on'
};

// SONOS MODULES
// currently supported version (GitHub tkugelberg/Sonos)
export const SONOS_SonosPlayer_MODULE_ID =
    '{52F6586D-A1C7-AAC6-309B-E12A70F6EEF6}';
// deprecated version since 2019 (GitHub tkugelberg/SymconSonos)
export const SONOS_Sonos_MODULE_ID = '{F6F3A773-F685-4FD2-805E-83FD99407EE8}';

// HUE MODULE
export const HUE_LIGHT = '{87FA14D1-0ACA-4CBD-BE83-BA4DF8831876}';
export const HUE_GROUPED_LIGHT = '{6324AC4A-330C-4CB2-9281-12EECB450024}';

// KNOCKAUT X MODULES
export const KNOCKAUT_X_Co2Sensor_MODULE_ID =
    '{A407FBD2-F78C-2FC3-E287-C260741E492C}';
export const KNOCKAUT_X_Dimmaktor_MODULE_ID =
    '{4AEDFFBA-CB6C-B29B-27BD-043785222352}';
export const KNOCKAUT_X_DoorWindowSensor_MODULE_ID =
    '{3240D96F-90DF-D7CD-AA05-28B3D8AAF746}';
export const KNOCKAUT_X_IndoorSiren_MODULE_ID =
    '{E1626F96-81DC-0F6B-F69C-9342AAD56A0C}';
export const KNOCKAUT_X_KnobDimmerSwitch_MODULE_ID =
    '{7D89DA39-1A59-D421-8BE1-CEAF4F22DE9B}';
export const KNOCKAUT_X_KnobDimmerSwitchWhite_MODULE_ID =
    '{7D89DA39-1A59-D421-8BE1-CEAF4F22DE9B}';
export const KNOCKAUT_X_LED_RGB_E14_6W_MODULE_ID =
    '{CDC03733-582C-5ABA-1BC6-8D208D98B98D}';
export const KNOCKAUT_X_LED_RGB_E27_MODULE_ID =
    '{C078B597-F469-1033-E796-D862E65F8E5B}';
export const KNOCKAUT_X_LED_RGB_GU10_MODULE_ID =
    '{7DB37997-2B5A-7A16-24EE-F890454CD045}';
export const KNOCKAUT_X_MasterGatewayTWO_MODULE_ID =
    '{ACF80F34-9A95-A63B-50F8-01F3FFEE60A2}';
export const KNOCKAUT_X_MotionSensor_MODULE_ID =
    '{C131870C-696F-20BB-5A60-9C40FFCD3693}';
export const KNOCKAUT_X_PluginReceiverMulti_MODULE_ID =
    '{E99C8F2C-F4B1-8CE3-86B4-05401E1E2F9F}';
export const KNOCKAUT_X_Repeater_MODULE_ID =
    '{5C2C4710-B70C-14C9-0BE2-8D139B247068}';
export const KNOCKAUT_X_SchaltSteckdose_MODULE_ID =
    '{6B64A27C-35BC-D48C-76C9-73AC7DA3D289}';
export const KNOCKAUT_X_Schaltaktor_MODULE_ID =
    '{7CFA1D77-782E-9746-1BC2-7151EFFBAAAA}';
export const KNOCKAUT_X_SmartElectricMeter_MODULE_ID =
    '{8F058C6F-FFF4-6D6E-E8C0-4D6879918C67}';
export const KNOCKAUT_X_SmokeAlarm_MODULE_ID =
    '{95D0B6D4-70F5-BDB5-6D61-60A55960E38D}';
export const KNOCKAUT_X_TemperatureHumiditySensor_MODULE_ID =
    '{98648CA8-1AFC-55FF-CCE0-011351301922}';
export const KNOCKAUT_X_TemperatureHumiditySensorLCD_MODULE_ID =
    '{C6AA8CA2-5778-EFC3-7EBE-70F603C31764}';
export const KNOCKAUT_X_Thermostat_MODULE_ID =
    '{41FCFD9A-FB59-9865-B349-DFD0A5E9F963}';
export const KNOCKAUT_X_VibrationSensor_MODULE_ID =
    '{54F7E29C-833B-530E-891C-DCE5320DEEF5}';
export const KNOCKAUT_X_WallThermostate_MODULE_ID =
    '{5301383A-B412-464A-1F67-A13E21F027AB}';
export const KNOCKAUT_X_WaterSensor_MODULE_ID =
    '{C5763338-97B9-8813-BC40-AB18693E97AF}';
export const KNOCKAUT_X_WirelessSwitch1Channel_MODULE_ID =
    '{C913AC7B-44DA-255B-D679-1BBB31D133A0}';
export const KNOCKAUT_X_WirelessSwitch4Channel_MODULE_ID =
    '{C087D434-EF64-049C-5C76-AD26C1EB9965}';
export const KNOCKAUT_X_WirelessSwitchMini1Channel_MODULE_ID =
    '{C9DB389E-3858-ED80-EF1D-BB1F1F73047C}';

// DOMINOSWISS MODULES
export const DOMINOSWISS_EGate_MODULE_ID =
    '{1252F612-CF3F-4995-A152-DA7BE31D4154}';
export const DOMINOSWISS_Group_MODULE_ID =
    '{7F5C8432-CEAC-45A7-BF96-4BBC3CF04B57}';
export const DOMINOSWISS_LXDIMM_MODULE_ID =
    '{5ED1AA15-6D8B-4DA8-B1C8-781D24442288}';
export const DOMINOSWISS_LXRLUP_MODULE_ID =
    '{E498DF29-57B1-48F5-8C13-A4673EE0EF9E}';
export const DOMINOSWISS_MAG_MODULE_ID =
    '{26AE9337-13A8-4BF8-99D0-EE11D91FDEE2}';
export const DOMINOSWISS_MXSLIM_MODULE_ID =
    '{0A5C3DFA-CD52-4529-82F1-99DCFCF8A7A2}';
export const DOMINOSWISS_MXFEShutter_MODULE_ID =
    '{3AA1A627-78B0-4E17-9206-0BB012094D1C}';
export const DOMINOSWISS_MXFEULTRA_MODULE_ID =
    '{FE8DB15B-6FD0-F9A7-D7FD-B5188D368528}';
export const DOMINOSWISS_PIR_MODULE_ID =
    '{CE892EF8-C01D-43D2-BBA7-D5B54484795E}';
export const DOMINOSWISS_TM_MODULE_ID =
    '{61CD5357-4D1E-E0CF-CBE0-08EAA8478A39}';
export const DOMINOSWISS_UTC_MODULE_ID =
    '{4E1FBB10-9283-7779-6D79-7D190ECE33FF}';
export const DOMINOSWISS_Weatherstation_MODULE_ID =
    '{B3F0007D-44EE-460B-81D1-5A74C85EE29C}';
