var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"color-tile",attrs:{"flat":"","tile":""}},[_c('v-responsive',{staticClass:"d-flex justify-center align-center",attrs:{"aspect-ratio":9 / 9}},[_c('v-card-text',{staticClass:"ma-0 pa-0 text-center"},[_c('p',{staticClass:"mb-3",style:({
                        fontSize:
                            _vm.fontSize *
                                (_vm.value.length > 7
                                    ? _vm.value.length > 10
                                        ? 1
                                        : 1.5
                                    : 2) +
                            'vw'
                    })},[_vm._v(" "+_vm._s(_vm.value)+" ")]),_c('b-icon',{staticClass:"tile-type-icon",attrs:{"name":"TileType_Variable"}}),_c('div',{staticClass:"tile-type-text-container",style:({ fontSize: _vm.fontSize + 'vw' })},[_c('div',{staticClass:"tile-type-text"},[_vm._v(" "+_vm._s(_vm.instance.name.toUpperCase())+" ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }