<template>
    <!--
    <v-card flat tile max-height="200px" max-width="200px">
        <v-responsive :aspect-ratio="9 / 9">-->
    <div>
        <FlipCard v-if="breakpoint == 'xl'">
            <template slot="front">
                <v-card-text class="ma-0 pa-0 text-center">
                    <i
                        :class="iconClass"
                        :style="{ fontSize: data.fontSize * 5 + 'vw' }"
                    ></i>
                    <b-icon
                        name="TileType_Sensor"
                        class="tile-type-icon"
                    ></b-icon>
                    <div
                        class="tile-type-text-container"
                        :style="{ fontSize: data.fontSize + 'vw' }"
                    >
                        <div class="tile-type-text">{{ data.name }}</div>
                    </div>
                </v-card-text>
            </template>
            <template slot="back">
                <v-card-title class="headline">
                    {{ instance.name.toUpperCase() }}
                </v-card-title>
                <v-card-text>
                    <v-row v-for="(variable, index) in vars" :key="index">
                        <v-col class="py-0">
                            <span style="font-size: 13px"
                                >{{ variable.name }}:
                                <span
                                    :class="getVariableColor(variable)"
                                    style="font-size: 15px"
                                >
                                    {{ variable.data.resolvedValue }}
                                </span></span
                            >
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>
        </FlipCard>
        <div v-else>
            <v-card
                flat
                tile
                @click="dialog = true"
                justify="center"
                align="center"
                class="color-tile"
            >
                <v-responsive
                    :aspect-ratio="9 / 9"
                    class="d-flex justify-center align-center"
                >
                    <v-card-text class="ma-0 pa-0 text-center">
                        <i
                            :class="iconClass"
                            :style="{ fontSize: data.fontSize * 5 + 'vw' }"
                        ></i>
                        <b-icon
                            name="TileType_Sensor"
                            class="tile-type-icon"
                        ></b-icon>
                        <div
                            class="tile-type-text-container"
                            :style="{ fontSize: data.fontSize + 'vw' }"
                        >
                            <div class="tile-type-text">{{ data.name }}</div>
                        </div>
                    </v-card-text>
                </v-responsive>
            </v-card>
            <v-dialog v-model="dialog" width="unset">
                <v-card class="secondary">
                    <v-card-title class="headline">
                        <p>{{ instance.name.toUpperCase() }}</p>
                    </v-card-title>

                    <v-card-text>
                        <v-row v-for="(variable, index) in vars" :key="index">
                            <v-col class="py-0">
                                <span style="font-size: 13px"
                                    >{{ variable.name }}:
                                    <span
                                        :class="getVariableColor(variable)"
                                        style="font-size: 15px"
                                        >{{ variable.data.resolvedValue }}
                                    </span>
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="primary--text"
                            text
                            @click="dialog = false"
                        >
                            Schliessen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<script>
import FlipCard from '@/components/FlipCard';
export default {
    props: ['instance', 'data'],
    components: {
        FlipCard
    },
    data() {
        return {
            vars: [],
            breakpoint: 'sm',
            dialog: false,
            coloring: {
                profiles: {
                    'BRELAG.MAGContact': {
                        value: true,
                        class: 'warning--text',
                        tilecolor: 2
                    },
                    'BRELAG.MAGEvent': {
                        value: 1,
                        class: 'error--text',
                        tilecolor: 3
                    },
                    'BRELAG.MAGLifesign': {
                        value: true,
                        class: 'error--text',
                        tilecolor: 3
                    },
                    'BRELAG.MAGBattery': {
                        value: true,
                        class: 'warning--text',
                        tilecolor: 2
                    },
                    '~Motion': {
                        value: true,
                        class: 'warning--text',
                        tilecolor: 2
                    }
                },
                tile: [
                    'no-icon-color',
                    'icon-primary',
                    'icon-warning',
                    'icon-error'
                ]
            },
            tileColorNumber: 0
        };
    },
    mounted() {
        this.fetchData();
    },
    computed: {
        breakP() {
            return this.$vuetify.breakpoint.name;
        },
        iconClass() {
            const classes = {
                fal: true,
                'tile-icon': true
            };
            classes[this.data.iconName] = true;
            classes[this.coloring.tile[this.tileColorNumber]] = true;
            return classes;
        }
    },
    watch: {
        breakP() {
            this.breakpoint = this.breakP;
        },
        vars: {
            deep: true,
            handler() {
                this.tileColorNumber = 0;
                for (const key in this.vars) {
                    this.updateTileColor(this.vars[key]);
                }
            }
        }
    },
    methods: {
        getVariableColor(variable, asNumber) {
            let profileName = variable.data.customProfile;
            if (profileName == '') {
                profileName = variable.data.profile;
            }
            if (this.coloring.profiles[profileName]) {
                if (
                    variable.data.value ===
                    this.coloring.profiles[profileName].value
                ) {
                    if (asNumber) {
                        return this.coloring.profiles[profileName].tilecolor;
                    } else {
                        return this.coloring.profiles[profileName].class;
                    }
                }
            }
            if (asNumber) {
                return 0;
            } else {
                return 'primary--text';
            }
        },
        updateTileColor(variable) {
            const tileColorNumber = this.getVariableColor(variable, true);
            if (tileColorNumber > this.tileColorNumber) {
                this.tileColorNumber = tileColorNumber;
            }
        },
        async fetchData() {
            let vars = [];
            const children = await this.$store.dispatch('getChildren', [
                this.instance.data.targetID,
                2
            ]);
            for (const key in children) {
                let resolvedValue = children[key].data.value;
                let profileName = '';
                if (children[key].data.profile) {
                    profileName = children[key].data.profile;
                }
                if (children[key].data.customProfile) {
                    profileName = children[key].data.customProfile;
                }
                if (profileName) {
                    const profile = this.$store.state.snapshot.profiles[
                        profileName
                    ];
                    if (profile) {
                        if (profile.associations.length > 0) {
                            // Iterate through associations to find corresponding value
                            for (const association of profile.associations) {
                                if (association.value === resolvedValue) {
                                    resolvedValue = association.name;
                                    break;
                                }
                            }
                        } else {
                            resolvedValue =
                                profile.prefix + resolvedValue + profile.suffix;
                        }
                    }
                }
                children[key].data.resolvedValue = resolvedValue;
                vars.push(children[key]);
                this.tileColorNumber = 0;
                this.updateTileColor(children[key]);
            }
            this.vars = vars;
            this.breakpoint = this.$vuetify.breakpoint.name;
        }
    }
};
</script>
<style scoped>
.v-card__title {
    word-break: break-word !important;
}
.icon-error {
    filter: invert(61%) sepia(53%) saturate(3086%) hue-rotate(338deg)
        brightness(94%) contrast(104%);
}
.icon-warning {
    filter: invert(31%) sepia(87%) saturate(1265%) hue-rotate(354deg)
        brightness(107%) contrast(102%);
}
.icon-primary {
    filter: invert(93%) sepia(3%) saturate(1034%) hue-rotate(315deg)
        brightness(82%) contrast(76%);
}
</style>
