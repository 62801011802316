export default {
    state: {
        moduleObject: null,
        children: [],
        stateLog: [],
        configuration: {
            States: [],
            Sensors: []
        },
        initialized: false,
        selectedMode: 0
    },
    actions: {},
    mutations: {
        updateSelectedMode(state, value) {
            state.selectedMode = value;
        },
        updateConfigState(state, { index, key, value }) {
            state.configuration.States[index][key] = value;
        },
        updateSensorDelaySwitch(state, { index, value }) {
            state.configuration.Sensors[index].delay = value;
        },
        updatePassword(state, password) {
            state.configuration.Password = password;
        }
    },
    getters: {
        state: state => {
            return state.children.find(obj => obj.ident === 'Status');
        },
        states: state => {
            return state.configuration['States'];
        },
        mode: (state, getters, rootState, rootGetters) => {
            const mode = state.children.find(obj => obj.ident === 'Mode');
            if (mode && mode.data) {
                mode.data.resolvedValue = mode.data.value;
                const profile = rootGetters.getProfile(mode.data.profile);
                const association = profile.associations.find(
                    obj => obj.value === mode.data.value
                );
                if (association.name) {
                    mode.data.resolvedValue = association.name;
                }
            }
            return mode;
        },
        password: state => {
            return state.children.find(obj => obj.ident === 'Password');
        },
        acknowledge: state => {
            return state.children.find(obj => obj.ident === 'Acknowledge');
        },
        preAlarm: state => {
            return state.children.find(obj => obj.ident === 'PreAlarm');
        },
        alarm: state => {
            return state.children.find(obj => obj.ident === 'Alarm');
        },
        formattedConfig: state => {
            let config = state.configuration;
            config = JSON.parse(JSON.stringify(config)); // create copy and break reference to store-object
            config['States'] = JSON.stringify(config['States']); // parse object to string to get the required object structure
            config['Sensors'] = JSON.stringify(config['Sensors']); // parse object to string to get the required object structure
            return config;
        }
    }
};
