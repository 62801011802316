<template>
    <v-app :style="background" class="selection-disable">
        <template v-if="!initializing">
            <AGB />
            <Updates />
            <app-navigation v-if="showAppNavigation"></app-navigation>
            <SideBar v-if="showAppNavigation" />
            <v-main>
                <v-container fluid :class="centerContent" class="pa-0">
                    <router-view></router-view>
                </v-container>
            </v-main>
            <LocalNotifierDisplay />
        </template>
    </v-app>
</template>

<script>
import SideBar from '@/components/navigation/SideBar.vue';
import AppNavigation from '@/components/navigation/AppNavigation';
import AGB from '@/components/AGB';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { addPushNotificationListeners } from './plugins/pushNotifications';
import Updates from './components/AppVersionManager.vue';
import LocalNotifierDisplay from './components/LocalNotifierDisplay.vue';

export default {
    name: 'App',
    components: {
        AppNavigation,
        AGB,
        Updates,
        LocalNotifierDisplay,
        SideBar
    },
    computed: {
        showAppNavigation() {
            return (
                this.$route.name &&
                !this.disableMenu.includes(this.$route.name) &&
                this.$store.state.isDashboardLoaded
            );
        },
        centerContent() {
            return this.disableMenu.includes(this.$route.name)
                ? 'fill-height'
                : '';
        }
    },
    async beforeMount() {
        this.isNative = Capacitor.isNativePlatform();

        if (this.isNative) {
            try {
                this.registerAppStateChangeListener();
                await addPushNotificationListeners(this.$store);
            } catch (error) {
                // TODO: Show error to user
                console.warn(error);
            }
        }

        if (this.$store.state.autoLoginController) {
            // if automatic login is active, then try to log in.
            try {
                await this.$store.dispatch('autoLogin');
            } catch (error) {
                // TODO: Show error to user
                console.warn(error);
            }
        }
        this.initializing = false;
        document.title = 'KNOCKAUT Ultimate';
    },
    methods: {
        registerAppStateChangeListener() {
            App.addListener('appStateChange', state => {
                if (
                    !this.$store.state.isAppActive &&
                    state.isActive &&
                    this.$store.state.snapshot &&
                    this.isNative &&
                    this.$route.name !== 'ControllerEditor'
                ) {
                    this.$router.push('/loading');
                } else {
                    this.$store.commit('setAppActive', state.isActive);
                }
            });
        }
    },
    data() {
        return {
            disableMenu: [
                'DashboardList',
                'ControllerList',
                'ControllerEditor',
                'ControllerSearch'
            ],
            background: {
                width: '100%',
                height: 'auto',
                position: 'absolute',
                top: 0,
                left: 0,
                background:
                    'url(' +
                    require('@/assets/Background.jpg') +
                    ') no-repeat fixed left top',
                backgroundSize: 'cover',
                backgroundColor: 'grey'
            },
            isNative: true,
            initializing: true
        };
    }
};
</script>

<style>
/** TODO: This does not belong here but in separate css file */
.kno-color-grey {
    background-color: #373434;
}
.kno-color-category {
    background: #292929;
}
.kno-text-grey {
    color: rgb(122, 122, 122) !important;
}
.color-tile {
    background-color: rgb(59, 54, 50) !important;
}
#app {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    touch-action: pan-x pan-y;
    padding-top: env(safe-area-inset-top) !important;
    padding-left: env(safe-area-inset-left) !important;
    padding-right: env(safe-area-inset-right) !important;
    max-width: 100% !important;
    overflow-x: hidden !important;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}
.v-application--wrap {
    min-height: calc(100vh - env(safe-area-inset-top)) !important;
}
.notch-safe-nav {
    height: calc(
        100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    ) !important;
    top: env(safe-area-inset-top) !important;
    bottom: env(safe-area-inset-bottom) !important;
}
.notch-safe-bar {
    top: env(safe-area-inset-top) !important;
}
.notch-bar-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: env(safe-area-inset-top) !important;
    background-color: rgba(56, 50, 47, 0.95);
    z-index: 6;
}
a.theme--dark.v-list-item--link.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #fff;
}
.v-messages {
    display: none !important;
}
.v-input__slot {
    margin-bottom: 0 !important;
}
.selection-disable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.v-list--dense .v-list-item .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list-item--dense .v-list-item__title,
.v-dialog > .v-card > .v-card__title,
.v-card__subtitle,
.v-card__text,
.v-card__actions {
    font-weight: 300 !important;
    letter-spacing: 0rem;
    word-break: break-word !important;
}
.v-card__subtitle,
.v-card__text,
.v-card__actions {
    font-weight: 300 !important;
    word-break: break-word !important;
}
.v-card__title {
    word-break: break-word !important;
}

.tile-icon {
    position: absolute;
    top: 25%;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 40%;
    max-height: 36%;
    width: auto;
    height: 100%;
}
.tile-type-icon {
    position: absolute;
    top: 2%;
    right: 2%;
    max-width: 20%;
    width: auto;
    filter: invert(0) opacity(30%);
}
.tile-type-text-container {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 5% 5% 5%;
    height: 30%;
}
.tile-type-text {
    display: inline-block;
    align-self: flex-end;
    margin: 0;
    width: 100%;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.1rem;
    overflow: hidden;
    color: #ccc;
    line-height: 110%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.v-navigation-drawer .v-navigation-drawer__border {
    display: none;
}
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.3);
}
tbody tr:nth-of-type(odd) > .v-data-table__mobile-row {
    background-color: rgba(0, 0, 0, 0.4) !important;
}
.settings-screen {
    margin-top: 2px !important;
}
.v-overlay.v-overlay--active .v-overlay__scrim {
    opacity: 1 !important;
    /*background-color: initial !important;*/
    background-color: rgba(255, 255, 255, 0.2) !important;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}
</style>
