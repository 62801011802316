import configs from '@/scripts/configs';
import merge from 'lodash/merge';

export class ControlTile {
    constructor() {
        this.icon = null;
        this.name = '';

        this.prependVar = null;
        this.controlVars = [];
        this.detailVars = [];
        this._resizePerControl = 0.5;
        this.scales = {
            prepend: 1,
            name: 2,
            controls: 'auto',
            append: 2 / 3
        };
    }

    // hasVisible..() getters are used to show or hide control tile sections
    get hasVisiblePrepend() {
        return !!this.scales.prepend;
    }
    get hasVisibleName() {
        return !!this.scales.name;
    }
    get hasVisibleControls() {
        return !!this.scales.controls;
    }
    get hasVisibleAppend() {
        return !!this.scales.append;
    }

    // scale..() getters are used to manipulate the width and ratio of control tile sections
    get scalePrepend() {
        return this.scales.prepend;
    }
    get scaleName() {
        return this.scales.name;
    }
    get scaleControls() {
        return this.scales.controls;
    }
    get scaleAppend() {
        return this.scales.append;
    }

    // used to scale many elements when multiple variable controls are shown.
    get scaleFactorTile() {
        const resizes = Math.max(this.controlVars.length - 1, 0);
        return 1 + resizes * this._resizePerControl;
    }
    // used to scale variable controls when multiple variable controls are shown.
    get scaleFactorControl() {
        return this.scaleFactorTile / this.controlVars.length;
    }
}

export class ControlTileFactory {
    static make(instance = null, variables = [], customConfig = {}) {
        const controlTile = new ControlTile();

        if (instance !== null) {
            controlTile.name = instance.name ? instance.name : '';
            controlTile.icon = instance.icon ? instance.icon : '';
        }

        const config = merge({}, configs.tiles.default, customConfig);

        if (
            instance.data.target &&
            instance.data.target.data.moduleID ===
            '{E498DF29-57B1-48F5-8C13-A4673EE0EF9E}'
        )
            controlTile.scales = config.scales;

        // set variables depending on filters from config
        const filters = config.filters;
        const varTypes = ['prependVar', 'controlVars', 'detailVars'];
        if (variables.length > 0) {
            for (const key of varTypes) {
                // return empty if filter === null
                if (filters[key].filter === null) {
                    this.convertDataType(key, []);
                    continue;
                }
                // else return filtered, sorted and limited data;
                try {
                    let vars = variables;
                    // check for variables to skip
                    if (filters[key].excludePrependVar) {
                        if (
                            controlTile.prependVar &&
                            controlTile.prependVar.id
                        ) {
                            vars = vars.filter(variable => {
                                return (
                                    variable.id !== controlTile.prependVar.id
                                );
                            });
                        }
                    }
                    if (typeof filters[key].filter === 'function') {
                        vars = vars.filter(filters[key].filter);
                    }
                    if (typeof filters[key].sort === 'function') {
                        vars = vars.sort(filters[key].sort);
                    }
                    if (filters[key].limit) {
                        vars = vars.slice(0, filters[key].limit);
                    }
                    controlTile[key] = this.convertDataType(key, vars);
                } catch (ex) {
                    console.error(ex);
                    console.warn('Error in tile filters configuration');
                    console.log(key, filters, variables);
                }
            }
        }
        return controlTile;
    }

    static convertDataType(filterKey, variables) {
        // if filterKey ends with "Var", return object from array
        if (filterKey.slice(-3) === 'Var') {
            if (variables.length >= 1) {
                return variables[0];
            } else {
                return null;
            }
        }
        // else, (filterKey ends with "Vars"), return array
        return variables;
    }
}
