var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.hasAction)?_c('div',[(_vm.hasAssociations && _vm.type != 0)?_c('v-select',{attrs:{"items":_vm.profile.associations,"item-text":"name","item.value":"value","dense":"","filled":"","rounded":""},model:{value:(_vm.processedValue),callback:function ($$v) {_vm.processedValue=$$v},expression:"processedValue"}}):(
                _vm.hasAssociations &&
                    _vm.type == 0 &&
                    _vm.profile.associations.length == 2
            )?_c('div',[_c('span',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.profile.associations[0].name)+" ")]),_c('v-switch',{staticClass:"mt-0 pt-0 centered-self",staticStyle:{"display":"inline-block"},model:{value:(_vm.processedValue),callback:function ($$v) {_vm.processedValue=$$v},expression:"processedValue"}}),_c('span',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.profile.associations[1].name)+" ")])],1):_c('div',[(_vm.isPercent)?_c('span',[_c('span',{staticClass:"float-left",staticStyle:{"margin-top":"6px","margin-right":"10px"}},[_vm._v(_vm._s(_vm.displayValue)+" ")]),_c('v-slider',{attrs:{"min":"0","max":"100","step":_vm.percentStepSize,"value":_vm.processedValue},on:{"end":_vm.setProcessedValue}})],1):(_vm.type != 0)?_c('v-text-field',{staticClass:"mt-0 pt-0 centered-input",attrs:{"type":_vm.getType,"filled":"","rounded":"","dense":""},model:{value:(_vm.processedValue),callback:function ($$v) {_vm.processedValue=$$v},expression:"processedValue"}},[(this.profile && this.profile.suffix)?_c('div',{staticStyle:{"white-space":"nowrap"},attrs:{"slot":"append"},slot:"append"},[_vm._v(" "+_vm._s(this.profile.suffix)+" ")]):_vm._e(),(this.profile && this.profile.prefix)?_c('div',{staticStyle:{"white-space":"nowrap"},attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" "+_vm._s(this.profile.prefix)+" ")]):_vm._e()]):_c('div',[_c('v-switch',{staticClass:"mt-0 pt-0 centered-self",model:{value:(_vm.processedValue),callback:function ($$v) {_vm.processedValue=$$v},expression:"processedValue"}})],1)],1)],1):_c('div',[_c('div',{staticStyle:{"height":"30px","line-height":"30px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.displayValue)+" ")])]),(_vm.showsubtitle)?_c('div',{staticClass:"cracked-ugly-title pr-4",style:({
            'font-size': _vm.fontsize + 'vw',
            'line-height': _vm.fontsize + 'vw'
        })},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }