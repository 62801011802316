<template>
    <div style="position:relative">
        <v-slider
            v-model="val"
            :min="profile.minValue"
            :max="profile.maxValue"
            :step="profile.stepSize"
            thumb-label
            ticks="always"
            tick-size="4"
            @click.stop="$emit('execute', parseInt(val))"
        ></v-slider>
    </div>
</template>
<script>
export default {
    props: ['profile', 'value'],
    data() {
        return {
            val: this.value
        };
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        value(newVal, oldVal) {
            this.val = newVal;
            //alert(newVal);
        }
    }
};
</script>
