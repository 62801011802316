export default {
    state: {
        name: 'util-store',
        filterObjects: {},
        filterObjectsCache: null
    },
    actions: {},
    mutations: {},
    getters: {
        getObjectByName: (state, getters, rootState) => (
            name,
            parentID = null
        ) => {
            const objects = Object.filter(
                rootState.snapshot.objects,
                object =>
                    (parentID === null ? true : object.parentID == parentID) &&
                    object.name === name
            );
            return Object.values(objects)[0];
        },
        getObjectsByName: (state, getters, rootState) => (
            name,
            parentID = null
        ) => {
            const objects = Object.filter(
                rootState.snapshot.objects,
                object =>
                    (parentID === null ? true : object.parentID == parentID) &&
                    object.name === name
            );
            return Object.values(objects);
        },
        getObjectByID: (state, getters, rootState) => id => {
            return rootState.snapshot.objects['ID' + id];
        },
        toValidIntID: () => id => {
            if (id === 0 || id === '0') {
                return 0;
            }
            let validID = id;
            if (isNaN(id)) {
                if (typeof id !== 'string') {
                    return false;
                }
                validID = id.substr(0, 2) === 'ID' ? id.substr(0, 2) : false;
                if (isNaN(validID)) {
                    return false;
                }
            }
            validID = parseInt(validID);
            if (validID < 1e4 || validID >= 2 ** 16) {
                return false;
            }
            return validID;
        },
        getAncestors: (state, getters) => (descendantID, ancestorID = 0) => {
            const obj = getters.getObjectByID(descendantID);
            const ancestorReached =
                descendantID === ancestorID || !descendantID;
            let ancestors = [];
            if (!ancestorReached) {
                ancestors = getters.getAncestors(obj.parentID, ancestorID);
            }
            ancestors.push(obj);
            return ancestors;
        },
        getObjectName: (state, getters) => id => {
            if (id === -1) {
                return 'Allgemein';
            }
            const obj = getters.getObjectByID(id);
            const parent = getters.getObjectByID(obj.parentID);
            return obj.name + ' (' + parent.name + ')';
        },
        // Chaining filters
        objects: (state, getters, rootState) => {
            if (rootState.snapshot) {
                if (state.filterObjectsCache === null) {
                    state.filterObjectsCache = JSON.parse(
                        JSON.stringify(rootState.snapshot.objects)
                    );
                    setTimeout(() => {
                        state.filterObjectsCache = null;
                    }, 3000);
                }
                state.filterObjects = state.filterObjectsCache;
            }
            // do not remove this "bufferRefFix" variable. Replaces console.log() dirtyfix.
            // eslint-disable-next-line no-unused-vars
            const bufferRefFix = state.filterObjects;
            return getters;
        },
        childrenOf: (state, getters) => id => {
            state.filterObjects = Object.filter(
                state.filterObjects,
                object => object.parentID === id
            );
            return getters;
        },
        typeOf: (state, getters) => type => {
            state.filterObjects = Object.filter(
                state.filterObjects,
                object => object.type === type
            );
            return getters;
        },
        scriptTypeOf: (state, getters) => type => {
            state.filterObjects = Object.filter(
                state.filterObjects,
                object => object.type === 3 && object.data.type === type
            );
            return getters;
        },
        linkTargetOf: (state, getters) => targetId => {
            state.filterObjects = Object.filter(
                state.filterObjects,
                object => object.data.targetID === targetId
            );
            return getters;
        },
        resolveLinks: (state, getters) => {
            for (const key in state.filterObjects) {
                if (state.filterObjects[key].type === 6) {
                    const obj = getters.getObjectByID(
                        state.filterObjects[key].data.targetID
                    );
                    delete state.filterObjects[key];
                    state.filterObjects['ID' + obj.id] = obj;
                }
            }
            return getters;
        },
        get: state => {
            const results = JSON.parse(JSON.stringify(state.filterObjects));
            //state.filterObjects = getters.objects;
            return results;
        }
    }
};
