<template>
    <div v-if="dialog.show">
        <v-dialog v-model="dialog.show" max-width="500" light>
            <v-card>
                <v-toolbar dark class="primary">
                    <v-toolbar-title>{{
                        dialog.title.toUpperCase()
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native="dialog.show = false" dark>
                        <i class="fas fa-times"></i>
                    </v-btn>
                </v-toolbar>
                <v-card-title> </v-card-title>

                <v-card-text>
                    <div v-for="(variable, id) in variables" :key="id">
                        <!-- VARIABLE-TYPES: 0=Boolean, 1=Integer, 2=Float, 3=String -->
                        <!-- BOOLEAN -->
                        <div class="row">
                            <div class="col" v-if="debug == true">
                                {{ variable.name }} ({{
                                    variable.data.profile
                                }})<br />
                                {{ variable }}
                            </div>
                            <div class="col" v-else>
                                {{ variable.name }}
                            </div>
                            <div class="col" max-height="20">
                                <IpsProfile
                                    v-if="dialogState"
                                    :variable="variable"
                                    @execute="execute($event)"
                                ></IpsProfile>
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialog.show = false"
                    >
                        schliessen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import IpsProfile from '@/components/profiles/IPSProfile';
import helpers from '@/mixins/helpers';
export default {
    name: 'InstanceDialog',
    components: {
        IpsProfile
    },
    mixins: [helpers],
    data() {
        return {
            dialog: this.$store.state.dialog,
            /** TURN ON TO SHOW DEBUG STUFF */
            debug: false
        };
    },
    methods: {
        execute(action) {
            this.$api.execute(action);
        }
    },
    computed: {
        dialogState() {
            return this.$store.state.dialog.show;
        },
        variables() {
            const vars = this.getChildren(this.dialog.instanceID, 2);

            const sortable = [];
            for (const key in vars) {
                const variable = vars[key];
                variable.id = key.substring(2);
                const profileKey = variable.data.customProfile
                    ? variable.data.customProfile
                    : variable.data.profile;
                variable.profile = this.$store.state.snapshot.profiles[
                    profileKey
                ];
                sortable.push(variable);
            }

            sortable.sort(function(a, b) {
                return a.position - b.position;
            });
            return sortable;
        }
    }
};
</script>
