var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-card',{staticStyle:{"padding-bottom":"0"},style:({
                padding:
                    _vm.$vuetify.breakpoint.name == 'xs'
                        ? '12px 3vw 0 3vw'
                        : '12px 2vw 0 2vw'
            }),attrs:{"flat":"","tile":"","max-height":"200px","color":'rgb(255, 255, 255, 0.0)'}},[(_vm.breadcrumbs.slice(1).length > 0)?_c('div',{staticClass:"grey darken-1",class:{
                    primary: _vm.breadcrumbs.slice(1).length == 1
                },staticStyle:{"width":"4px","height":"30px","float":"left","padding-right":"0","margin-top":"5px"}}):_vm._e(),(_vm.breadcrumbs.slice(1).length > 0)?_c('v-breadcrumbs',{staticClass:"py-1 pl-3",attrs:{"items":_vm.breadcrumbs.slice(1),"large":""},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('span')]},proxy:true},{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',{attrs:{"to":{
                            name: 'Home',
                            params: {
                                ..._vm.$route.params,
                                roomId: item.id
                            }
                        },"disabled":item.disabled}},[(_vm.breadcrumbs.slice(1).indexOf(item) > 0)?_c('div',{class:{
                                'grey darken-1': !item.disabled,
                                primary: item.disabled
                            },staticStyle:{"width":"4px","height":"30px","position":"relative","left":"-12px"}}):_vm._e(),_c('span',{staticStyle:{"line-height":"32px"}},[_vm._v(_vm._s(item.text))])])]}}],null,false,292811836)}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }