<template>
    <span
        style="position:absolute; bottom:20px; left: 10px; color:grey"
        v-if="appInfo"
    >
        <small>
            V{{ appInfo.Version }} B{{ appInfo.Build }}-{{ appInfo.Date }}
        </small>
        <small
            v-if="appInfo.updateAvailable"
            @click.stop="updateApp"
            style="cursor:pointer"
            class="accent--text"
        >
            (Update verfügbar)
        </small>
        <span v-else>
            <v-progress-circular v-if="updateRunning" indeterminate :width="2">
                <v-dialog v-model="updateRunning" persistent width="300">
                    <v-card color="secondary" dark>
                        <v-card-text>
                            <p class="py-3">
                                Aktualisiere Firmware. Bitte warten sie bis das
                                Update fertig ist und schalten sie den
                                Controller nicht aus.
                            </p>
                            <v-progress-linear
                                indeterminate
                                color="primary"
                                class="mb-0"
                            ></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-progress-circular>
            <small v-else> (aktuellste)</small>
        </span>
        <v-row justify="center">
            <v-dialog v-model="updateInfo" max-width="290">
                <v-card>
                    <v-card-title class="headline">Aktualisiert</v-card-title>
                    <v-card-text
                        >Starten sie die App neu um die neusten Änderungen zu
                        übernehmen</v-card-text
                    >
                </v-card>
            </v-dialog>
        </v-row>
    </span>
</template>

<script>
import packageInfo from '../../../package.json';
export default {
    name: 'AppUpdater',
    data() {
        return {
            appInfo: null,
            updateRunning: false,
            updateInfo: false
        };
    },
    methods: {
        async updateApp() {
            this.appInfo.updateAvailable = false;
            this.updateRunning = true;
            try {
                await this.$api.updateApp(packageInfo.version);
            } catch (error) {
                // TODO: error handling
                console.warn(error);
            }
            this.updateRunning = false;
            setTimeout(() => {
                this.updateInfo = false;
            }, 6000);
            this.updateInfo = true;
        }
    },
    async mounted() {
        /**
         * TODO:
         * 1) Rename from appInfo -> it's the KnockautBasic Module (can keep user-facing naming)
         * 2) Store information in store (currently fetched multiple times)
         * 3) Refresh after updating module
         */

        try {
            this.appInfo = await this.$api.getAppInfo();
        } catch (error) {
            // TODO: error handling
            console.warn(error);
        }
    }
};
</script>

<style scoped></style>
