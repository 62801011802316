<template>
    <div>
        <span class="float-left" style="margin-top: 6px; margin-right: 10px"
            >{{ val }} {{ profile.suffix }}
        </span>
        <v-slider
            v-model="val"
            :min="profile.minValue"
            :max="profile.maxValue"
            :step="profile.stepSize"
            :disabled="!action"
            thumb-label
            @end="valueChanged(val)"
        >
        </v-slider>
    </div>
</template>
<script>
export default {
    props: ['profile', 'value', 'action'],
    data() {
        return {
            val: this.value
        };
    },
    watch: {
        value(newVal) {
            this.val = newVal;
        }
    },
    methods: {
        valueChanged(value) {
            this.$emit('execute', parseInt(value));
        }
    }
};
</script>
<style>
.v-slider__thumb {
    width: 20px;
    height: 20px;
    left: -10px;
}
.v-slider__thumb:before {
    left: -9px;
    top: -9px;
}
.v-slider__thumb-container:hover .v-slider__thumb:before {
    transform: scale(0.1) !important;
}
.v-slider__thumb-container--focused .v-slider__thumb:before {
    transform: scale(0.1) !important;
}
</style>
