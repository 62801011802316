<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="400">
            <v-card>
                <v-card-title class="text-h5">
                    Allgemeine Geschäftsbedingungen
                </v-card-title>

                <v-card-text>
                    Für die Nutzung dieser App müssen Sie die
                    <a @click="openLink('https://www.brelag.com/terms')"
                        >allgemeinen Geschäftsbedingungen (AGB)</a
                    >
                    , sowie die aktuellen
                    <a
                        @click="
                            openLink(
                                'https://www.brelag.com/datenschutzbestimmungen'
                            )
                        "
                        ><span style="white-space:nowrap"
                            >Datenschutzbestimmungen</span
                        ></a
                    >
                    von BRELAG akzeptieren. Bitte bestätigen Sie dies durch
                    Klicken auf "AKZEPTIEREN".
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click="decline">
                        Ablehnen
                    </v-btn>

                    <v-btn color="green darken-1" text @click="accept">
                        Akzeptieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="overlay"></v-overlay>
    </div>
</template>
<script>
import { Preferences } from '@capacitor/preferences';

export default {
    data() {
        return {
            dialog: 1,
            overlay: false,
            agb_accepted: false,
            decline_url: 'https://www.knockaut.com/'
        };
    },
    methods: {
        async init() {
            const result = await Preferences.get({ key: 'agb' });
            if (result.value !== null) {
                this.agb_accepted = parseInt(result.value) ? 1 : 0;
            }
            this.dialog = !this.agb_accepted;
        },
        async accept() {
            this.agb_accepted = 1;
            await Preferences.set({
                key: 'agb',
                value: this.agb_accepted.toString()
            });
            this.dialog = false;
        },
        async decline() {
            this.dialog = false;
            this.overlay = true;
            this.agb_accepted = 0;
            await Preferences.set({ key: 'agb', value: this.agb_accepted });
            window.open(this.decline_url, '_self');
        },
        openLink(url) {
            window.open(url, '_blank');
        }
    },
    created() {
        this.init();
    }
};
</script>
