<template>
    <component
        :is="componentInstance"
        :profile="variable.profile"
        :name="variable.name ? variable.name : ''"
        :value="variable.data.value"
        :type="variable.data.type"
        :action="action"
        :id="variable.id"
        :knoIcon="icon"
        :fontsize="fontSize"
        :showsubtite="showsubtitle"
        v-on:execute="execute($event)"
    ></component>
</template>
<script>
const variableIdentToComponentMap = {
    // Switches
    Switch: 'LED_Switch',
    switch_led: 'LED_Switch',
    HUE_State: 'LED_Switch',
    switch_1: 'LED_Switch',
    switch_2: 'LED_Switch',
    switch: 'LED_Switch',
    switch_led_1: 'LED_Switch',
    switch_led_2: 'LED_Switch',
    on: 'LED_Switch', // HUE V2 State

    // LED
    colour_data_v2: 'knockautX/ColourDataV2'
};
const profileNameToComponentMap = {
    KnockautX_LEDBrightness: '_Intensity_',
    KnockautX_LEDTemperature: 'knockautX/LEDTemperature',
    KnockautX_PluginReceiverControl: 'knockautX/PluginReceiverControl',
    BESCHATTUNG_Switch: 'LED_Switch'
};

const variableIdentToIconMap = {
    switch_led: 'Bulb',
    HUE_State: 'Bulb',
    switch: 'Power',
    switch_1: 'Power',
    switch_2: 'Power',
    switch_led_1: 'Power',
    switch_led_2: 'Power'
};

export default {
    /**
     * This component will import a childcomponent from @/components/profiles/ depending on the profile-name stored in the variable
     * the profileName is slightly modified (see this.profileName() function) to make the filename conform and replace specialcharacters
     */
    name: 'IPSProfile',
    props: {
        variable: Object,
        'font-size': {
            type: String,
            default: '1'
        },
        showsubtitle: {
            type: Boolean,
            default: true
        },
        asIcon: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        action() {
            let action = this.variable.data.action;
            if (this.variable.data.customAction) {
                action = this.variable.data.customAction;
            }
            return action;
        },
        componentInstance() {
            let component;
            try {
                if (variableIdentToComponentMap[this.variable.ident]) {
                    component = () =>
                        import(
                            `@/components/profiles/${
                                variableIdentToComponentMap[this.variable.ident]
                            }`
                        );
                } else if (profileNameToComponentMap[this.profileName]) {
                    component = () =>
                        import(
                            `@/components/profiles/${
                                profileNameToComponentMap[this.profileName]
                            }`
                        );
                } else {
                    require(`@/components/profiles/${this.profileName}`);
                    component = () =>
                        import(`@/components/profiles/${this.profileName}`);
                }
            } catch (e) {
                component = () =>
                    import(`@/components/profiles/datatypes/Datatype`);
            }
            return component;
        },
        profileName() {
            let profileName = this.variable.data.profile;
            if (this.variable.data.customProfile != '') {
                profileName = this.variable.data.customProfile;
            }
            profileName = profileName.replace(/[^a-zA-Z0-9]/g, '_');
            profileName = profileName.replace(/[^a-zA-Z_]/g, '');
            return profileName ? profileName : '';
        },
        translatedValue() {
            return this.variable.profile.associations.find(
                assoc => assoc.value == this.variable.data.value
            );
        },
        icon() {
            let iconName = this.$IconName(this.variable, true);
            if (!iconName) {
                // no default or custom icon found.
                if (variableIdentToIconMap[this.variable.ident]) {
                    iconName = this.$IconName(
                        variableIdentToIconMap[this.variable.ident]
                    );
                }
            } else {
                // Default or custom icon found.
                iconName = this.$IconName(this.variable);
            }
            return { name: iconName };
        }
    },
    methods: {
        decimalColorToHTMLcolor(number) {
            const hex = number.toString(16);
            const s = '000000' + hex;
            return '#' + s.substr(hex.length);
        },
        execute(value) {
            const action = {
                actionID: this.variable.data.action,
                targetID: this.variable.id,
                value: value
            };
            this.$emit('execute', action);
        }
    }
};
</script>
<style scoped>
.colored {
    background-color: var(--color0);
}
</style>
