var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Browser")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-treeview',{attrs:{"open":_vm.open,"items":_vm.items,"disable-per-node":"","item-disabled":"locked","activatable":"","hoverable":"","item-key":"id","item-text":"name"},on:{"update:active":_vm.select},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [_c('fa-icon',{attrs:{"from":item.iconName,"width":"30px"}}),_c('v-img',{attrs:{"src":item.iconUrl,"width":"30px"}})]}},{key:"append",fn:function({ item, open }){return [(
                                    open &&
                                        item.object.type ==
                                            _vm.$CONST.OBJECTTYPE.CATEGORY
                                )?_c('i',{staticClass:"fas fa-lg fa-door-open"}):_vm._e(),(
                                    !open &&
                                        item.object.type ==
                                            _vm.$CONST.OBJECTTYPE.CATEGORY
                                )?_c('i',{staticClass:"fas fa-lg fa-door-closed"}):_vm._e()]}}])})],1)],1)],1),(_vm.error)?_c('v-alert',{staticClass:"mb-0 ml-5 mr-5 mt-2",attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" Unzulässiges Objekt ausgewählt! ")]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.done}},[_vm._v(" Okay ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }