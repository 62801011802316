<template>
    <div>
        <v-dialog
            v-if="!disableReleasenotes"
            v-model="showReleaseNotes"
            scrollable
            max-width="600"
        >
            <ReleaseNotesModal
                @close="setSeenReleaseNotesVersion(currentVersion)"
            ></ReleaseNotesModal>
        </v-dialog>
        <v-dialog
            v-if="!disableUpdates"
            v-model="updateModal"
            scrollable
            max-width="600"
        >
            <v-card>
                <v-toolbar color="primary" class="black--text"
                    ><h3>Update verfügbar</h3></v-toolbar
                >
                <v-card-text class="my-2">
                    <div>
                        Ein neues Update ist verfügbar. Aktualisieren sie die
                        App um alle Funktionen nutzen zu können.
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="updateModal = false">Abbrechen</v-btn>
                    <v-btn class="primary" text @click="openStorePage"
                        >Aktualisieren</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { Preferences } from '@capacitor/preferences';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Device } from '@capacitor/device';
import ReleaseNotesModal from './updater/ReleaseNotesModal.vue';
import packageInfo from '../../package';

export default {
    components: { ReleaseNotesModal },
    props: {
        disableReleasenotes: {
            type: Boolean,
            default: false
        },
        disableUpdates: {
            type: Boolean,
            default: false
        },
        forceReleasenotes: {
            type: Boolean,
            default: false
        },
        forceUpdates: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            PLATFORMS: {
                IOS: 'ios',
                ANDROID: 'android',
                WEB: 'web'
            },
            UPDATE_AVAILABILITY: {
                UNKNOWN: 0,
                UPDATE_NOT_AVAILABLE: 1,
                UPDATE_AVAILABLE: 2,
                UPDATE_IN_PROGRESS: 3
            },
            UPDATE_RESULT_CODE: {
                OK: 0,
                CANCELED: 1,
                FAILED: 2,
                NOT_AVAILABLE: 3,
                NOT_ALLOWED: 4,
                INFO_MISSING: 5
            },
            UPDATE_RESULT_CODE_TEXT: {
                0: 'Update wurde akzeptiert.',
                1: 'Update wurde abgelehnt oder abgebrochen.',
                2: 'Ein Fehler verhindert das durchführen des updates.',
                3: 'Kein Update verfügbar.',
                4: 'Update Typ wurde nicht erlaubt.',
                5: 'App Update Infos fehlen.'
            },
            showReleaseNotes: false,
            updateModal: false,
            currentVersion: ''
        };
    },
    methods: {
        async getDevicePlatform() {
            try {
                const info = await Device.getInfo();
                return info.platform;
            } catch (e) {
                return undefined;
            }
        },
        async getCurrentAppVersion() {
            try {
                const platform = await this.getDevicePlatform();
                if (
                    platform === this.PLATFORMS.ANDROID ||
                    platform === this.PLATFORMS.IOS
                ) {
                    const result = await AppUpdate.getAppUpdateInfo();
                    return result.currentVersion;
                } else {
                    const v = packageInfo.version;
                    return v;
                }
            } catch (ex) {
                console.log(ex);
                return undefined;
            }
        },
        async getSeenReleaseNotesVersion() {
            try {
                const result = await Preferences.get({
                    key: 'release-notes-version'
                });
                return result.value;
            } catch (ex) {
                console.log(ex);
                return undefined;
            }
        },
        async setSeenReleaseNotesVersion(version) {
            try {
                await Preferences.set({
                    key: 'release-notes-version',
                    value: version
                });
            } catch (ex) {
                console.log(ex);
            }
            this.showReleaseNotes = false;
        },
        async performUpdate() {
            const platform = await this.getDevicePlatform();
            if (
                platform === this.PLATFORMS.ANDROID ||
                platform === this.PLATFORMS.IOS
            ) {
                let progress = 0;
                try {
                    const result = await AppUpdate.getAppUpdateInfo();
                    if (typeof result === 'undefined') {
                        throw 'unable to get AppUpdateInfo(). returned undefined';
                    }
                    progress = 1;
                    if (
                        result.updateAvailability !==
                        this.UPDATE_AVAILABILITY.UPDATE_AVAILABLE
                    ) {
                        return; // return if no update is available.
                    }
                    // try to perform android in-app update. Otherwise show update modal to open the app store page (iOS or Android)
                    if (
                        result.immediateUpdateAllowed &&
                        platform === this.PLATFORMS.ANDROID
                    ) {
                        progress = 2;
                        const updateResult = await AppUpdate.performImmediateUpdate();
                        progress = 3;
                        if (updateResult !== this.UPDATE_RESULT_CODE.OK) {
                            throw updateResult;
                        }
                    } else {
                        this.updateModal = true;
                    }
                } catch (ex) {
                    let message = '';
                    switch (progress) {
                        case 0:
                            message =
                                'Fehler: App konnte nicht auf Updates geprüft werden.';
                            break;
                        case 2:
                            message =
                                'Fehler: in-app Update konnte nicht ausgeführt werden.';
                            break;
                        case 3:
                            message =
                                'Fehler: ' + this.UPDATE_RESULT_CODE_TEXT[ex];
                            break;
                        default:
                            message = 'Fehler: Unbekannter App-Update fehler.';
                            break;
                    }
                    this.$localNotifier.addNewNotification(
                        message,
                        ex,
                        'error',
                        5000
                    );
                }
            }
        },
        async openStorePage() {
            try {
                this.updateModal = false;
                await AppUpdate.openAppStore();
            } catch (ex) {
                this.$localNotifier.addNewNotification(
                    'Fehler: App-Seite konnte im Store nicht geöffnet werden.',
                    ex,
                    'error',
                    5000
                );
            }
        }
    },
    watch: {
        forceReleasenotes: {
            handler(force) {
                if (force) {
                    this.ReleaseNotesModal = true;
                }
            },
            immediate: true
        },
        forceUpdates: {
            handler(force) {
                if (force) {
                    this.updateModal = true;
                }
            },
            immediate: true
        }
    },
    async mounted() {
        // check for updates and perform update if possible.
        if (!this.disableUpdates) {
            await this.performUpdate();
        }

        // show release notes if not marked as seen.
        if (!this.disableReleasenotes) {
            const seenReleaseNotesVersion = await this.getSeenReleaseNotesVersion();
            const currentAppVersion = await this.getCurrentAppVersion();
            this.currentVersion = currentAppVersion;
            if (seenReleaseNotesVersion !== currentAppVersion) {
                if (currentAppVersion !== undefined) {
                    this.showReleaseNotes = true;
                }
            }
        }
    }
};
</script>
