<template>
    <div>
        <MessageDisplay
            :key="currentNotification.id"
            :value="currentNotification.display"
            :color="currentNotification.color"
            :button="currentNotification.button"
            :error="currentNotification.error"
            :timeout="currentNotification.timeout"
            @input="notificationConsumed"
            @ack="notificationAcknowledged"
        >
            {{ currentNotification.message }}
        </MessageDisplay>
    </div>
</template>
<script>
import MessageDisplay from '@/components/MessageDisplay.vue';
import { Notification } from '@/plugins/localNotifier.js';
export default {
    name: 'LocalNotifierDisplay',
    components: {
        MessageDisplay
    },
    data() {
        return {
            currentNotification: new Notification().setDisplay(false),
            idle: true
        };
    },
    watch: {
        $localNotifier: {
            deep: true,
            immediate: true,
            handler() {
                if (this.idle && !this.$localNotifier.isEmpty()) {
                    this.idle = false;
                    this.currentNotification = this.$localNotifier.dequeue();
                }
            }
        }
    },
    methods: {
        notificationConsumed() {
            try {
                this.currentNotification.hooks.onConsumed();
            } catch (ex) {
                this.$localNotifier.addNewNotification(ex.message, ex);
            }
            if (!this.$localNotifier.isEmpty()) {
                const nextNotification = this.$localNotifier.dequeue();
                // delay to prevent visual fade in/out effects of messages get cutt off.
                setTimeout(() => {
                    this.currentNotification = nextNotification;
                }, 200);
            } else {
                this.idle = true;
            }
        },
        notificationAcknowledged() {
            try {
                this.currentNotification.hooks.onAcknowledge();
            } catch (ex) {
                this.$localNotifier.addNewNotification(ex.message, ex);
            }
        }
    }
};
</script>
