<template>
    <div>
        <i
            :style="{ color: 'green' }"
            v-if="value == 0"
            class="far fa-check-circle fa-2x"
        ></i>
        <i
            :style="{ color: 'red' }"
            v-if="value == 1"
            class="far fa-times-circle fa-2x"
        ></i>
    </div>
</template>
<script>
export default {
    props: ['value']
};
</script>
