<template>
    <div>
        <v-color-picker
            flat
            v-model="color"
            dot-size="8"
            hide-canvas
            hide-inputs
            hide-mode-switch
            @update:color="update($event)"
            style="margin: auto"
        ></v-color-picker>
    </div>
</template>
<script>
export default {
    props: ['profile', 'value'],
    data() {
        return {
            color: this.intToHex(this.value),
            timer: null,
            ignoreChange: true,
            initialized: false
        };
    },
    watch: {
        value(newVal) {
            if (!this.ignoreChange) {
                this.color = this.intToHex(newVal);
            }
        }
    },
    methods: {
        update() {
            /* The purpose of this.initialized:
             *
             * This is a "fix" for the vuetify color-picker component.
             * The component does have an Event @update:color, which fires as soon as the slider moves.
             * I use this event to send the new color when the slider was moved.
             * In the beginning, i have to set the color of this slider to display correctly,
             * which fires the update event, which then turns on the Lamp due to a color change
             * since it is implemented this way in the Philips HUE Module on the Controller.
             * To prevent firing events on load, i have added this variable.
             * This can be removed when we replace the vuetify color-picker with the new,
             * self made color-picker which also supports saturation and color-temperature.
             */
            if (this.initialized) {
                if (this.timer == null) {
                    this.timer = setTimeout(() => {
                        const value = parseInt(this.color.substring(1), 16);
                        this.ignoreChange = true;
                        this.$emit('execute', value);
                        clearTimeout(this.timer);
                        this.timer = null;
                    }, 1000);
                }
            }
            this.initialized = true;
        },
        intToHex(number) {
            let value = number.toString(16);
            while (value.length < 6) {
                value = '0' + value;
            }
            value = '#' + value;
            return value;
        }
    }
};
</script>

<style>
.v-color-picker {
    background-color: rgba(0, 0, 0, 0) !important;
    max-width: 100% !important;
}
.v-color-picker__dot {
    max-width: 20px;
    max-height: 20px;
}
</style>
