import Vue from 'vue';
import Vuetify from 'vuetify';
//import 'vuetify/dist/vuetify.min.css';
import './vuetify.min.css';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { de },
        current: 'de'
    },
    icons: {
        iconfont: 'fa'
    },
    theme: {
        dark: true,
        themes: {
            light: {
                primary: '#292929',
                secondary: '#b5e10a',
                accent: '#292929',
                error: '#f44336',
                warning: '#fb8c00',
                info: '#2196f3',
                success: '#4caf50'
            },
            dark: {
                primary: '#b5e10a',
                secondary: '#292929',
                accent: '#b5e10a',
                error: '#f44336',
                warning: '#fb8c00',
                info: '#2196f3',
                success: '#4caf50'
            }
        }
    }
});
