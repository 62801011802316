<template>
    <div>
        <div
            class="brelag-led"
            :style="{
                'background-color': color,
                'box-shadow': shadow
            }"
        ></div>
    </div>
</template>
<script>
export default {
    props: ['profile', 'value'],
    computed: {
        color() {
            const value_association = this.profile.associations.find(
                assoc => assoc.value == this.value
            );
            const color = this.$parent.decimalColorToHTMLcolor(
                value_association.color
            );
            return color;
        },
        shadow() {
            const color = this.color;
            return (
                color +
                ' 0 -1px 7px 1px, inset ' +
                color +
                ' 0 -1px 9px, ' +
                color +
                ' 0 2px 12px'
            );
        }
    }
};
</script>

<style>
.brelag-led {
    margin: 20px auto;
    width: 20px;
    height: 20px;
    background-color: #690;
    border-radius: 50%;
    box-shadow: #690 0 -1px 7px 1px, inset #690 0 -1px 9px, #690 0 2px 12px;
}
</style>
