import { render, staticRenderFns } from "./FooterVariable.vue?vue&type=template&id=301230a8&scoped=true&"
import script from "./FooterVariable.vue?vue&type=script&lang=js&"
export * from "./FooterVariable.vue?vue&type=script&lang=js&"
import style0 from "./FooterVariable.vue?vue&type=style&index=0&id=301230a8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301230a8",
  null
  
)

export default component.exports