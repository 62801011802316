import router from '../router';
import { getModuleByGuid } from '../util/symcon/moduleUtil';
import { generateRandomString } from '../util/random';
import { registerPushNotifications } from '../plugins/pushNotifications';
import { Preferences } from '@capacitor/preferences';

const NOTIFICATION_MODULE_GUID = '{D4B231D6-8141-4B9E-9B32-82DA3AEEAB78}';

export default {
    namespaced: true,
    state: {
        moduleObject: null,
        initialized: false,
        token: null
    },
    actions: {
        async initializeNotifications(context) {
            const module = getModuleByGuid(
                NOTIFICATION_MODULE_GUID,
                context.rootGetters
            );
            context.commit('setModule', { module });
            if (module) {
                const devices = await context.rootState.$api.NC_GetDevices(
                    module.id
                );
                /* Device has structure:
                    Configurators: Array<number>
                    ID: <number>
                    InactiveConfigurators: Array<number>
                    Modified: <number>
                    Name: <string>
                */
                console.log({ devices });
            }
        },
        setToken(context, token) {
            console.log({ context, token });
            context.commit('setToken', { token });
        },
        setRegistrationError(context, error) {
            console.log({ context, error });
        },
        pushNotificationReceived(context, notification) {
            console.log({ context, notification });
        },
        pushNotificationActionPerformed(context, notification) {
            console.log({ context, notification });
        },
        async enablePushNotifications(context, { deviceInfo }) {
            await registerPushNotifications();

            // We need to wait until the token is set
            const waitTime = 500;
            const timeOut = 6000;
            let waiting = 0;
            while (!context.state.token) {
                if (waiting > timeOut) {
                    throw new Error(
                        'Konnte Push Nachrichten nicht registrieren. Bitte versuchen Sie es später nochmal.'
                    );
                }
                await new Promise(r => setTimeout(r, waitTime));
                waiting += waitTime;
            }

            if (!context.state.moduleObject) {
                await context.dispatch('initializeNotifications');
            }

            if (!context.state.moduleObject) {
                throw new Error(
                    'Notification Modul konnte auf dem Controller nicht gefunden werden.'
                );
            }

            const instanceId = context.state.moduleObject.id;
            const token = generateRandomString(10, true);
            /** PROVIDER
             *  - brelag.titanultimate.apns (Apple: Production Build über TestFlight oder Store!)
                - brelag.titanultimate.apns.sandbox (Apple: Debug Build on Device. Im Simulator keine PNS möglich!)
                - brelag.titanultimate.gcm (Google: Egal ob Simulator, Geräte oder aus dem Store)
             */
            let provider;
            if (deviceInfo.platform === 'ios') {
                provider = 'brelag.titanultimate.apns';
                // For local development, use the sandbox provider!
            } else if (deviceInfo.platform === 'android') {
                provider = 'brelag.titanultimate.gcm';
            } else {
                throw new Error('Unknown Device Platform');
            }

            const deviceName = generateRandomString(10, true);
            const deviceID = context.state.token; // APNS oder GCM Token/Identifier
            const webFrontVisualizationID =
                router.currentRoute.params.dashboardId;
            await context.rootState.$api.NC_AddDevice(
                instanceId,
                token,
                provider,
                deviceID,
                deviceName,
                webFrontVisualizationID
            );
            const settingsString = await Preferences.get({
                key: 'push-notification-settings'
            });
            let settings = {};
            if (settingsString.value) {
                settings = JSON.parse(settingsString.value);
            }
            settings[webFrontVisualizationID] = deviceName;
            await Preferences.set({
                key: 'push-notification-settings',
                value: JSON.stringify(settings)
            });
        },
        async disablePushNotifications(context) {
            // Get information from Preferences
            const settingsString = await Preferences.get({
                key: 'push-notification-settings'
            });
            let settings = {};
            if (settingsString.value) {
                settings = JSON.parse(settingsString.value);
            }
            const webFrontVisualizationID =
                router.currentRoute.params.dashboardId;
            const deviceName = settings[webFrontVisualizationID];
            const instanceId = context.state.moduleObject.id;
            const devices = await context.rootState.$api.NC_GetDevices(
                instanceId
            );
            let deviceID = null;
            for (const device of devices) {
                if (device.Name === deviceName) {
                    deviceID = device.ID;
                    break;
                }
            }
            if (deviceID === null) {
                // Cant find any entries, assume it was deleted otherwise
                return;
            }

            await context.rootState.$api.NC_RemoveDevice(instanceId, deviceID);
        }
    },
    mutations: {
        setToken(state, { token }) {
            state.token = token;
        },
        setModule(state, { module }) {
            state.moduleObject = module;
        }
    },
    getters: {}
};
