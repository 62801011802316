<template>
    <div>
        <v-card flat tile class="color-tile">
            <v-responsive
                :aspect-ratio="9 / 9"
                class="d-flex justify-center align-center"
            >
                <v-card-text class="ma-0 pa-0 text-center">
                    <p
                        class="mb-3"
                        :style="{
                            fontSize:
                                fontSize *
                                    (value.length > 7
                                        ? value.length > 10
                                            ? 1
                                            : 1.5
                                        : 2) +
                                'vw'
                        }"
                    >
                        {{ value }}
                    </p>
                    <b-icon
                        name="TileType_Variable"
                        class="tile-type-icon"
                    ></b-icon>
                    <div
                        class="tile-type-text-container"
                        :style="{ fontSize: fontSize + 'vw' }"
                    >
                        <div class="tile-type-text">
                            {{ instance.name.toUpperCase() }}
                        </div>
                    </div>
                </v-card-text>
            </v-responsive>
        </v-card>
    </div>
</template>
<script>
export default {
    props: ['instance', 'fontSize'],
    data() {
        return {
            value: ''
        };
    },
    watch: {
        instance: {
            deep: true,
            handler() {
                this.refresh();
            }
        }
    },
    methods: {
        getProfile() {
            const item = this.instance.data.target
                ? this.instance.data.target.data
                : this.instance.data;
            let profileName = item.profile;
            if (item.customProfile != '') {
                profileName = item.customProfile;
            }
            const profile = this.$store.state.snapshot.profiles[profileName];
            return profile;
        },
        refresh() {
            let profile = this.getProfile();
            let val = this.instance.data.target
                ? this.instance.data.target.data.value
                : this.instance.data;
            if (profile === undefined) {
                this.value = val;
                return;
            } else {
                if (profile.associations.length > 0) {
                    let association = null;
                    if (profile.type === this.$CONST.VARIABLETYPE.FLOAT) {
                        // if profile is Float and has associations, then use the association whos value is nearest to realValue and lower or equal
                        association = this.getNearestAssociationBelow(
                            profile.associations,
                            val
                        );
                    } else {
                        association = profile.associations.find(
                            assoc => assoc.value == val
                        );
                    }
                    val =
                        association && association.name
                            ? association.name
                            : val;
                }
                this.value = profile.prefix + val + profile.suffix;
            }
        },
        getNearestAssociationBelow(data, target) {
            return data.reduce((acc, obj) =>
                Math.abs(target - obj.value) < Math.abs(target - acc.value) &&
                target - obj.value >= 0
                    ? obj
                    : acc
            );
        }
    },
    mounted() {
        this.refresh();
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #272b28;
}
</style>
