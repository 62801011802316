<template>
    <div v-if="initialized">
        <Breadcrumbs />
        <v-row no-gutters :style="{ padding: tileSpace + 'vw' }">
            <v-col
                v-for="(instance, index) in flattenTiles"
                :key="instance.key + '_' + update"
                :cols="instance.tiletype != 'controls' ? '4' : '12'"
                :xs="instance.tiletype != 'controls' ? '4' : '12'"
                :sm="instance.tiletype != 'controls' ? '3' : '12'"
                :lg="instance.tiletype != 'controls' ? '3' : '12'"
                :xl="instance.tiletype != 'controls' ? '2' : '12'"
                :class="instance.tiletype != 'controls' ? 'pa-0' : 'py-0'"
            >
                <Tile
                    v-if="instance.tiletype != 'controls'"
                    :id="getControlTileId(instance)"
                    :instance="instance"
                    :tiletype="instance.tiletype"
                    :style="{ padding: tileSpace + 'vw' }"
                ></Tile>
                <ControlTile
                    v-else-if="instance.tiletype == 'controls'"
                    :id="getControlTileId(instance)"
                    :instance="instance"
                    :configuration="
                        instanceConfigurations[getControlTileId(instance)]
                    "
                    :index="index"
                    :style="{ padding: tileSpace / 2 + 'vw' }"
                />
            </v-col>
        </v-row>
        <InstanceDialog />
        <CustomFooter />
        <DebugOverlay />
        <ModuleUpdater />
    </div>
</template>

<script>
import Tile from '@/components/tiles/Tile.vue';
import ControlTile from '@/components/tiles/ControlTile.vue';
import InstanceDialog from '@/components/InstanceDialog.vue';
import ModuleUpdater from '@/components/ModuleUpdater.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DebugOverlay from '@/components/DebugOverlay.vue';
import CustomFooter from '@/components/CustomFooter.vue';

export default {
    name: 'Home',
    components: {
        Tile,
        InstanceDialog,
        ControlTile,
        ModuleUpdater,
        Breadcrumbs,
        DebugOverlay,
        CustomFooter
    },
    data() {
        return {
            items: {},
            root: null,
            update: 0,
            tiles: {
                categories: {},
                sensors: {},
                controls: {},
                scenes: {},
                devices: {},
                variables: {},
                alarms: {}
            },
            flatTiles: {},
            instanceConfigurations: {},
            links: {},
            cols: [3, 3, 2],
            initialized: false
        };
    },
    watch: {
        async $route(to) {
            if (to.params.roomId) {
                await this.updateChildren(
                    'tiles',
                    to.params.roomId,
                    'tilecategorized'
                );
            } else {
                await this.updateChildren('tiles', null, 'tilecategorized');
            }
            this.$store.commit('signalingNewRoute');
        },
        async updated(count) {
            const params = this.$router.currentRoute.params;
            if (params.roomId) {
                await this.updateChildren(
                    'tiles',
                    params.roomId,
                    'tilecategorized'
                );
            } else {
                await this.updateChildren('tiles', null, 'tilecategorized');
            }
            this.update = count;
        }
    },
    computed: {
        updated() {
            return this.$store.state.messageUpdates.changed;
        },
        isXs() {
            return this.$vuetify.breakpoint.name == 'xs';
        },
        snapshot() {
            return this.$store.state.snapshot;
        },
        flattenTiles() {
            let flattentiles = [];
            for (const key in this.tiles) {
                if (key !== 'devices') {
                    let group = this.tiles[key];
                    for (const id in group) {
                        group[id].tiletype = key;
                        group[id].key = id;
                        flattentiles.push(group[id]);
                    }
                }
            }
            flattentiles.sort((a, b) => (a.position > b.position ? 1 : -1));
            return flattentiles;
        },
        tileSpace() {
            let ret;
            if (this.$vuetify.breakpoint.name == 'xs') {
                ret = 1.5;
            } else {
                ret = 1;
            }
            return ret;
        }
    },
    methods: {
        getControlTileId(instance) {
            return instance.type == this.$CONST.OBJECTTYPE.LINK
                ? instance.data.targetID
                : instance.key.substring(2);
        },
        getObject(id = null) {
            let category = null;
            if (id) {
                category = this.snapshot.objects['ID' + id];
            } else {
                category = this.snapshot.objects['ID' + this.root];
            }
            return category;
        },
        async updateConfigurations() {
            let configurations = [];
            try {
                configurations = await this.$api.getConfigurations();
            } catch (error) {
                console.warn(error);
            }
            const instanceConfigurations = {};
            for (const config of configurations) {
                instanceConfigurations[config.ObjectID] = config;
            }
            this.instanceConfigurations = Object.assign(
                {},
                instanceConfigurations
            );
        },
        async updateChildren(attribute, id = null, type = null) {
            await this.updateConfigurations();
            const result = await this.$store.dispatch('getChildren', [
                id,
                type
            ]);
            this[attribute] = Object.assign({}, result);
        },
        getChildInstances(id) {
            const links = this.getChildren(id, this.$CONST.OBJECTTYPE.LINK);
            return links;
        },
        updateVirtualRoute(currentID) {
            const rootID = this.$store.getters.toValidIntID(
                this.$store.state.rootID
            );
            const itemID = this.$store.getters.toValidIntID(currentID);
            if (rootID === false || itemID === false) {
                this.$store.commit('updateBaseCategory', itemID.id);
                return;
            } else {
                const ancestorChain = this.$store.getters.getAncestors(
                    currentID,
                    rootID
                );
                const path = [];
                ancestorChain.map(obj => {
                    path.push({
                        disabled: obj.id === itemID,
                        id: obj.id,
                        text: obj.name ? obj.name : '' + obj.id
                    });
                });
                this.$store.state.virtualRoute.path = path;
                this.$store.commit('updateBaseCategory', path[1].id);
            }
        }
    },
    async beforeMount() {
        // TODO: Loading state/ error handling
        try {
            const items = this.$store.state.snapshot.items;
            const newItems = {};
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.Configuration.length > 1) {
                    item.Configuration = JSON.parse(item.Configuration);
                }
                newItems[items[i].ClassName] = item;
            }
            this.items = newItems;
            this.root = this.items.Category.Configuration.baseID;
            this.$store.commit('setRootID', this.root);
        } catch (error) {
            console.warn(error);
            // Something went wrong, go back to Dashboard list
            this.$router.push({
                name: 'DashboardList',
                params: {
                    controllerId: this.$router.currentRoute.params.controllerId
                }
            });
            return;
        }

        let roomId = this.$route.params.roomId
            ? this.$route.params.roomId
            : null;
        if (roomId === null || roomId === '0' || roomId === 0) {
            const roomsDict = Object.filter(
                this.$store.state.snapshot.objects,
                object =>
                    object.parentID == this.$store.state.rootID &&
                    !object.hidden &&
                    object.type == 0
            );
            const rooms = Object.values(roomsDict);
            if (rooms.length > 0) {
                rooms.sort((a, b) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                );
                rooms.sort((a, b) => a.position - b.position);
                roomId = rooms[0].id.toString();
                this.$router.push({
                    name: 'Home',
                    params: {
                        ...this.$router.currentRoute.params,
                        roomId: roomId
                    }
                });
            }
        }
        if (roomId !== null) {
            this.updateChildren('tiles', roomId, 'tilecategorized');
        }

        this.initialized = true;
    }
};
</script>
<style>
.category-text {
    color: #b5e10a;
}
</style>
