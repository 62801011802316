import {
    KNOCKAUT_X_PluginReceiverMulti_MODULE_ID,
    DOMINOSWISS_MXFEShutter_MODULE_ID,
    DOMINOSWISS_MXSLIM_MODULE_ID,
    DOMINOSWISS_MXFEULTRA_MODULE_ID,
    DOMINOSWISS_LXDIMM_MODULE_ID,
    DOMINOSWISS_LXRLUP_MODULE_ID,
    SONOS_SonosPlayer_MODULE_ID,
    SONOS_Sonos_MODULE_ID,
    IDENTS
} from '@/util/symcon/moduleUtil';
import { HUE_GROUPED_LIGHT, HUE_LIGHT } from '../util/symcon/moduleUtil';

export const prependVarIdents = [
    IDENTS.KNOCKAUT_X_SWITCH,
    IDENTS.KNOCKAUT_X_SWITCH_LED,
    IDENTS.KNOCKAUT_X_SWITCH_1,
    IDENTS.KNOCKAUT_X_SWITCH_LED_1,
    IDENTS.DOMINOSWISS_SWITCH,
    IDENTS.DOMINOSWISS_STATUS,
    IDENTS.DOMINOSWISS_WARNING,
    IDENTS.PHILIPS_HUE_STATE,
    IDENTS.PHILIPS_HUE_V2_STATE
];

// sonos tile config used for multiple instances
export const sonosTileConfig = {
    filters: {
        prependVar: {
            filter: null
        },
        controlVars: {
            filter: (item, index) => {
                return [0, 1].includes(index);
            },
            limit: 2
        }
    },
    scales: {
        prepend: null,
        name: 1
    }
};

export const configs = {
    // CONFIGS FOR TILES.
    tiles: {
        /*
         * Only visible variables will be processed.
         * The "default" config is used to generate tile configs.
         * The individual configs override the default config. This means that
         * individual configs only need to define the difference.
         */
        // DEFAULT TILE CONFIG
        default: {
            /*
             * "filters" contains filters for prependVar, controlVars and detailVars.
             * Considering a control tile:
             * - "prependVar" is a var which is shown on the left as f.ex. clickable icon.
             * - "controlVars" are shown directly on the tile, right after the name.
             * - "detailVars" are the vars shown in the popup.
             * each of those tree filters is an object with optional parameters.
             * { [filter: function|null], [sort: function], [limit: int] }
             * if one of the 3 filters isn't defined, it uses the default filter.
             * if "filter" is set to null, the returned data will be empty.
             */
            filters: {
                prependVar: {
                    filter: item => {
                        return prependVarIdents.includes(item.ident);
                    },
                    limit: 1
                },
                controlVars: {
                    filter: (item, index) => {
                        return index === 0;
                    },
                    limit: 1,
                    excludePrependVar: true
                },
                detailVars: {
                    filter: () => {
                        return true;
                    },
                    excludePrependVar: false
                }
            },
            /*
             * "scales" defines the width factors of the control-tile elements
             * if value is null, element will be hidden.
             * if value is 'auto', element will stretch to match remaining space.
             */
            scales: {
                prepend: 1,
                name: 2,
                controls: 'auto',
                append: 2 / 3
            },
            /*
             * "target" is used to reuse a config already defined under an alternate GUID.
             * if "tartet" is a GUID (string), the config for that module will be used.
             */
            target: null
        },
        // INDIVIDUAL TILE CONFIGS
        // KnockautX Storen
        [KNOCKAUT_X_PluginReceiverMulti_MODULE_ID]: {
            filters: {
                controlVars: {
                    filter: (item, index) => {
                        return [0, 1].includes(index);
                    },
                    limit: 2
                }
            }
        },
        // DominoSwiss Storen
        [DOMINOSWISS_MXFEShutter_MODULE_ID]: {
            filters: {
                controlVars: {
                    filter: item => {
                        return item.ident === 'Movement';
                    },
                    limit: 1
                }
            }
        },
        [DOMINOSWISS_MXSLIM_MODULE_ID]: {
            target: DOMINOSWISS_MXFEShutter_MODULE_ID
        },
        [DOMINOSWISS_MXFEULTRA_MODULE_ID]: {
            target: DOMINOSWISS_MXFEShutter_MODULE_ID
        },
        // DominoSwiss Dimmaktor
        [DOMINOSWISS_LXDIMM_MODULE_ID]: {
            filters: {
                controlVars: {
                    filter: item => {
                        return item.ident === 'Intensity';
                    },
                    limit: 1
                }
            }
        },
        // DominoSwiss Schaltaktor
        [DOMINOSWISS_LXRLUP_MODULE_ID]: {
            filters: {
                controlVars: {
                    filter: null
                }
            }
        },
        // Sonos Player current
        [SONOS_SonosPlayer_MODULE_ID]: {
            filters: {
                prependVar: {
                    filter: null
                },
                controlVars: {
                    filter: (item, index) => {
                        return item.ident === 'Status' || index === 0;
                    },
                    sort: (a, b) => {
                        return a.ident === 'Status'
                            ? -1
                            : b.ident === 'Status'
                            ? 1
                            : 0;
                    },
                    limit: 2
                }
            },
            scales: {
                prepend: null,
                name: 1
            }
        },
        // Sonos "Player" deprecated (used for backwards compatibility)
        [SONOS_Sonos_MODULE_ID]: { target: SONOS_SonosPlayer_MODULE_ID },
        [HUE_LIGHT]: {
            filters: {
                prependVar: {
                    filter: item => {
                        return item.ident === 'on';
                    }
                },
                controlVars: {
                    filter: item => {
                        return item.ident === 'brightness';
                    },
                    limit: 1
                }
            },
            scales: {
                prepend: null,
                name: 1
            }
        },
        [HUE_GROUPED_LIGHT]: { target: HUE_LIGHT }
    }
};

export default configs;
