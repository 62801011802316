<template>
    <v-slider
        v-model="val"
        :min="profile.minValue"
        :max="profile.maxValue"
        :step="profile.stepSize"
        @end="valueChanged(val)"
    >
        <template v-slot:prepend><span class="label-text">Warm</span></template>

        <template v-slot:append><span class="label-text">Kalt</span></template>
    </v-slider>
</template>
<script>
export default {
    props: ['profile', 'value'],
    data() {
        return {
            val: this.value
        };
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        value(newVal, oldVal) {
            this.val = newVal;
        }
    },
    methods: {
        valueChanged(value) {
            this.$emit('execute', parseInt(value));
        }
    }
};
</script>
<style scoped>
.label-text {
    word-break: keep-all;
}
</style>
