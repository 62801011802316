<template>
    <v-card>
        <v-card-title class="headline pb-0">
            <p>{{ name.toUpperCase() }}</p>
        </v-card-title>

        <v-card-text class="px-0 pb-0">
            <v-list three-line class="secondary">
                <ControlModalListItem
                    v-for="(variable, index) in variables"
                    :key="index + '_item'"
                    :variable="variable"
                    :fontSize="fontSize"
                    @execute="execute($event)"
                />
            </v-list>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary--text" text @click="close">
                Schliessen
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import ControlModalListItem from './ControlModalListItem.vue';

export default {
    props: ['fontSize', 'variables', 'instance'],
    components: {
        ControlModalListItem
    },
    mixins: [],
    data() {
        return {
            name: this.instance.name,
            executed: false
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async execute(action) {
            try {
                await this.$api.execute(action);
            } catch (ex) {
                this.$localNotifier.addNewNotification(ex.message, ex);
            }
        }
    }
};
</script>

<style></style>
