export default {
    //EventConditionComparison
    //Vergleichskonditionen für bedingte Ereignisse
    EVENTCONDITIONCOMPARISON: {
        EQUAL: 0,
        NOTEQUAL: 1,
        GREATER: 2,
        GREATEROREQUAL: 3,
        SMALLER: 4,
        SMALLEROREQUAL: 5
    },
    //EventCyclicDateType
    //Datumstyp bei zyklischem Ereignis
    EVENTCYCLICDATETYPE: {
        NONE: 0,
        ONCE: 1,
        DAY: 2,
        WEEK: 3,
        MONTH: 4,
        YEAR: 5
    },
    //EventCyclicTimeType
    //Zeittyp des Ereignisses
    EVENTCYCLICTIMETYPE: {
        ONCE: 0,
        SECOND: 1,
        MINUTE: 2,
        HOUR: 3
    },
    //EventTriggerType
    //Auslösetyp bei ausgelösten Ereignis
    EVENTTRIGGERTYPE: {
        ONUPDATE: 0,
        ONCHANGE: 1,
        ONLIMITEXCEED: 2,
        ONLIMITDROP: 3,
        ONVALUE: 4
    },
    //EventType
    //Typ des Ereignisses
    EVENTTYPE: {
        TRIGGER: 0,
        CYCLIC: 1,
        SCHEDULE: 2
    },
    //MediaType
    //Typ der Mediainstanz
    MEDIATYPE: {
        DASHBOARD: 0,
        IMAGE: 1,
        SOUND: 2,
        STREAM: 3,
        CHART: 4,
        DOCUMENT: 5
    },
    //ModuleType
    //Typ des Moduls
    MODULETYPE: {
        CORE: 0,
        IO: 1,
        SPLITTER: 2,
        DEVICE: 3,
        CONFIGURATOR: 4,
        DISCOVERY: 5
    },
    //ObjectType
    //Typ des Objekts
    OBJECTTYPE: {
        CATEGORY: 0,
        INSTANCE: 1,
        VARIABLE: 2,
        SCRIPT: 3,
        EVENT: 4,
        MEDIA: 5,
        LINK: 6
    },
    //ScriptType
    //Typ des Skripts
    SCRIPTTYPE: {
        PHP: 0,
        FLOW: 1
    },
    //VariableType
    //Typ der Variable
    VARIABLETYPE: {
        BOOLEAN: 0,
        INTEGER: 1,
        FLOAT: 2,
        STRING: 3
    },
    //FieldType
    //Typ des Feldes
    FIELDTYPE: {
        BOOLEAN: 0,
        INTEGER: 1,
        FLOAT: 2,
        STRING: 3,
        SELECTBOX: 10
    },
    //ComparisonType
    //Typ des Vergleichsoperators
    COMPARISONTYPE: {
        EQUAL: 0,
        NOT_EQUAL: 1,
        GREATER: 2,
        GREATER_OR_EQUAL: 3,
        LESS: 4,
        LESS_OR_EQUAL: 5
    },
    //ConditionType
    //Typ der Bedingung
    CONDITIONTYPE: {
        VARIABLE: 0,
        DATE: 1,
        DAY_OF_THE_WEEK: 2,
        TIME: 3
    },
    //FlowScriptState
    //Gibt an ob der Ablaufplan aktiv ist (ob die entsprechenden Event-Auslöser aktiv sind)
    FLOWSCRIPT_TRIGGER_STATE: {
        ALL: 0,
        SOME: 1,
        NONE: 2,
        HAS_NONE: 3
    },
    //ControllerListOrderingTypes
    //Definiert die verfügbaren Sortier-Optionen für die ControllerListe
    CONTROLLER_LIST_ORDER: {
        NEWEST_ON_TOP: 0,
        NEWEST_ON_BOTTOM: 1,
        ALPHABETIC_AZ: 2,
        ALPHABETIC_ZA: 3
    },
    //MessageType
    //Typ der Websocket Nachricht
    MESSAGETYPE: {
        KR_CREATE: 10101, // Kernel wurde erstellt
        KR_INIT: 10102, // Kernel Komponenten werden initialisiert, Module geladen und Settings eingelesen
        KR_READY: 10103, // Kernel ist bereit und läuft
        KR_UNINIT: 10104, // Shutdown-Befehl erhalten, finalisiere alles geladene
        KR_SHUTDOWN: 10105, // Finalisierung abgeschlossen, entferne Kernel
        KL_MESSAGE: 10201, // Normale Nachricht
        KL_SUCCESS: 10202, // Erfolg
        KL_NOTIFY: 10203, // Änderungsbenachrichtung
        KL_WARNING: 10204, // Warnung
        KL_ERROR: 10205, // Fehlermeldung
        KL_DEBUG: 10206, // Debug Information
        KL_CUSTOM: 10207, // Sonstige Nachrichten
        ML_LOAD: 10301, // Modul geladen
        ML_UNLOAD: 10302, // Modul entladen
        OM_REGISTER: 10401, // Objekt erstellt
        OM_UNREGISTER: 10402, // Objekt entfernt
        OM_CHANGEPARENT: 10403, // Übergeordnetes Objekt hat sich geändert
        OM_CHANGENAME: 10404, // Name hat sich geändert
        OM_CHANGEINFO: 10405, // Beschreibung hat sich geändert
        OM_CHANGETYPE: 10406, // Typ hat sich geändert
        OM_CHANGESUMMARY: 10407, // Kurzinfo hat sich geändert
        OM_CHANGEPOSITION: 10408, // Position hat sich geändert
        OM_CHANGEREADONLY: 10409, // Nur-Lesen-Status hat sich geändert
        OM_CHANGEHIDDEN: 10410, // Sichtbarkeit hat sich geändert
        OM_CHANGEICON: 10411, // Icon hat sich geändert
        OM_CHILDADDED: 10412, // Untergeordnetes Objekt hinzugefügt
        OM_CHILDREMOVED: 10413, // Untergeordnetes Objekt entfernt
        OM_CHANGEIDENT: 10414, // Ident hat sich geändert
        OM_CHANGEDISABLED: 10415, // Bedienbarkeit hat sich geändert
        IM_CREATE: 10501, // Instanz erstellt
        IM_DELETE: 10502, // Instanz entfernt
        IM_CONNECT: 10503, // Instanzinterface verfügbar
        IM_DISCONNECT: 10504, // Instanzinterface nicht mehr verfügbar
        IM_CHANGESTATUS: 10505, // Status hat sich geändert
        IM_CHANGESETTINGS: 10506, // Einstellungen haben sich geändert
        IM_SEARCHSTART: 10511, // Suche wurde gestartet
        IM_SEARCHSTOP: 10512, // Suche wurde gestoppt
        IM_SEARCHUPDATE: 10513, // Suche hat neue Ergebnisse
        VM_CREATE: 10601, // Variable wurde erstellt
        VM_DELETE: 10602, // Variable wurde entfernt
        VM_UPDATE: 10603, // Variable wurde aktualisiert
        VM_CHANGEPROFILENAME: 10604, // Variablenprofilname wurde geändert
        VM_CHANGEPROFILEACTION: 10605, // Variablenprofilaktion wurde geändert
        SM_CREATE: 10701, // Skript wurde erstellt
        SM_DELETE: 10702, // Skript wurde entfernt
        SM_CHANGEFILE: 10703, // Skript wurde Datei angehangen
        SM_BROKEN: 10704, // Skript Fehlerstatus hat sich geändert
        SM_UPDATE: 10704, // Skript wurde aktualisiert
        EM_CREATE: 10801, // Ereignis wurde erstellt
        EM_DELETE: 10802, // Ereignis wurde entfernt
        EM_UPDATE: 10803, // Ereignis wurde aktualisiert
        EM_CHANGEACTIVE: 10804, // Ereignisaktivierung hat sich geändert
        EM_CHANGELIMIT: 10805, // Ereignisaufruflimit hat sich geändert
        EM_CHANGESCRIPT: 10806, // Ereignisskriptinhalt hat sich geändert
        EM_CHANGETRIGGER: 10807, // Ereignisauslöser hat sich geändert
        EM_CHANGETRIGGERVALUE: 10808, // Ereignisgrenzwert hat sich geändert
        EM_CHANGETRIGGEREXECUTION: 10809, // Ereignisgrenzwertauslösung hat sich geändert
        EM_CHANGECYCLIC: 10810, // zyklisches Ereignis hat sich geändert
        EM_CHANGECYCLICDATEFROM: 10811, // Startdatum hat sich geändert
        EM_CHANGECYCLICDATETO: 10812, // Enddatum hat sich geändert
        EM_CHANGECYCLICTIMEFROM: 10813, // Startzeit hat sich geändert
        EM_CHANGECYCLICTIMETO: 10814, // Endzeit hat sich geändert
        EM_ADDSCHEDULEACTION: 10815, // Eintrag in der Aktionstabelle des Wochenplans wurde hinzugefügt
        EM_REMOVESCHEDULEACTION: 10816, // Eintrag in der Aktionstabelle des Wochenplans wurde entfernt
        EM_CHANGESCHEDULEACTION: 10817, // Eintrag in der Aktionstabelle des Wochenplans hat sich geändert
        EM_ADDSCHEDULEGROUP: 10818, // Gruppierung der Wochenplantage wurde hinzugefügt
        EM_REMOVESCHEDULEGROUP: 10819, // Gruppierung der Wochenplantage wurde entfernt
        EM_CHANGESCHEDULEGROUP: 10820, // Gruppierung der Wochenplantage hat sich geändert
        EM_ADDSCHEDULEGROUPPOINT: 10821, // Schaltpunkt einer Gruppierung wurde hinzugefügt
        EM_REMOVESCHEDULEGROUPPOINT: 10822, // Schaltpunkt einer Gruppierung wurde entfernt
        EM_CHANGESCHEDULEGROUPPOINT: 10823, // Schaltpunkt einer Gruppierung hat sich geändert
        EM_ADDCONDITION: 10824, // Bedingung wurde hinzugefügt
        EM_REMOVECONDITION: 10825, // Bedingung wurde entfernt
        EM_CHANGECONDITION: 10826, // Bedingung hat sich geändert
        EM_ADDCONDITIONVARIABLERULE: 10827, // Variablenregel der Bedingung wurde hinzugefügt
        EM_REMOVECONDITIONVARIABLERULE: 10828, // Variablenregel der Bedingung wurde entfernt
        EM_CHANGECONDITIONVARIABLERULE: 10829, // Variablenregel der Bedingung hat sich geändert
        EM_ADDCONDITIONDATERULE: 10830, // Datumsregel der Bedingung wurde hinzugefügt
        EM_REMOVECONDITIONDATERULE: 10831, // Datumsregel der Bedingung wurde entfernt
        EM_CHANGECONDITIONDATERULE: 10832, // Datumsregel der Bedingung hat sich geändert
        EM_ADDCONDITIONTIMERULE: 10833, // Zeitregel der Bedingung wurde hinzugefügt
        EM_REMOVECONDITIONTIMERULE: 10834, // Zeitregel der Bedingung wurde entfernt
        EM_CHANGECONDITIONTIMERULE: 10835, // Zeitregel der Bedingung hat sich geändert
        MM_CREATE: 10901, // Medienobjekt wurde erstellt
        MM_DELETE: 10902, // Medienobjekt wurde entfernt
        MM_CHANGEFILE: 10903, // Datei des Medienobjekts wurde geändert
        MM_AVAILABLE: 10904, // Verfügbarkeit des Medienobjekts hat sich geändert
        MM_UPDATE: 10905, // Medienobjekt wurde aktualisiert
        MM_CHANGECACHED: 10906, // Cacheoption vom Medienobjekt hat sich geändert
        LM_CREATE: 11001, // Link wurde erstellt
        LM_DELETE: 11002, // Link wurde entfernt
        LM_CHANGETARGET: 11003, // Ziel des Links hat sich geändert
        FM_CONNECT: 11101, // Instanz wurde verbunden
        FM_DISCONNECT: 11102, // Instanz wurde getrennt
        FM_CHILDADDED: 11103, // Untergeordnete Instanz wurde mit dieser Instanz verbunden
        FM_CHILDREMOVED: 11104, // Untergeordnete Instanz wurde von dieser Instanz getrennt
        SE_UPDATE: 11201, // Scriptengine wurde neu geladen
        SE_EXECUTE: 11202, // Script wurde ausgeführt
        SE_RUNNING: 11203, // Script wird ausgeführt
        PM_CREATE: 11301, // Profil wurde erstellt
        PM_DELETE: 11302, // Profil wurde entfernt
        PM_CHANGETEXT: 11303, // Profilprefix/Profilsuffix hat sich geändert
        PM_CHANGEVALUES: 11304, // Profilwerte haben sich geändert
        PM_CHANGEDIGITS: 11305, // Profilnachkommastellen haben sich geändert
        PM_CHANGEICON: 11306, // Profilicon hat sich geändert
        PM_ASSOCIATIONADDED: 11307, // Profilassoziation wurde hinzugefügt
        PM_ASSOCIATIONREMOVED: 11308, // Profilassoziation wurde entfernt
        PM_ASSOCIATIONCHANGED: 11309, // Profilassoziation hat sich geändert
        TM_REGISTER: 11401, // Timer wurde erstellt
        TM_UNREGISTER: 11402, // Timer wurde entfernt
        TM_CHANGEINTERVAL: 11403 // Timer Interval hat sich geändert
    },
    ALLOWEDACTIONS: [
        '{450423BB-2787-B376-842A-BCD8F117093A}', //Warten (Variable)
        '{788243BA-7BE4-E5BB-D8E6-518D16D9A026}', //Warte bis Variable mit einer Anderen übereinstimmt
        '{CA06E7EB-3BA1-1CE1-6023-C5C573720EF8}', //Warte auf Wert
        '{8B1D83D4-AC0A-D150-1E3E-41C2F91D5993}', //Warte auf Wert
        '{7D27C0C9-BE97-7FBF-3361-6B026A4FB851}', //Warte auf Wert
        '{0E9416DA-1505-4038-B4B7-DC550451B57A}', //Auf auslösenden Wert schalten
        '{97395D3B-1AD7-2AE7-6301-AE13C1CFEB2D}', //Auf auslösenden Wert schalten
        '{DF89F662-0BA8-7963-41F4-DBB63FC6426D}', //Auf auslösenden Wert setzen
        '{1DEF4656-18F6-BFF8-8ED6-553562C836A6}', //Auf multiplizierten auslösenden Wert schalten
        '{E6C76CE8-6F06-3510-2C46-5A5A9A861807}', //Auf multiplizierten auslösenden Wert setzen
        '{9F1494F4-FD90-A10C-7F39-4986112F66DB}', //Auf invertierten auslösenden Wert schalten
        '{728DA009-318D-5A5D-A19B-E5285A3DDD48}', //Auf dividierten auslösenden Wert setzen
        '{EF078D00-FBD5-8857-F5DC-F5B54B107DD3}', //Wert umschalten
        '{A4D52B67-BE4B-4AD0-964F-B9BA2556AAB0}', //Schalte auf Wert
        '{F25466DC-917D-4D81-979F-82494B559340}', //Schalte auf Wert
        '{A4C53C8D-795E-403A-9EEC-CC0D71E89A20}', //Schalte auf Wert
        '{FCE37F48-DA3F-45DD-AC77-71343792CC2D}', //Schalte auf Wert
        //'{819E3A9D-E964-424C-A88D-BA0D6E335BD0}', //Auf auslösenden Wert setzen
        //'{33C693B2-4FE1-79CB-2518-33C346DE21E9}', //Auf invertierten auslösenden Wert setzen
        '{46B65CA6-3098-4982-9A6C-B89DADBB0A96}', //Schalte auf Wert
        '{EEB96694-71CA-7AEE-7985-9AB65533BBE3}', //Schalte Statusvariable
        '{E616C2B2-A827-1712-4AE0-841C57B3DD74}', //Schalte Statusvariable
        //'{2F144AD7-D26B-17B7-3456-D16583E5092C}', //Auf dividierten auslösenden Wert schalten
        '{28D1076F-B227-C385-40F7-E96E3ED4381F}', //Subtrahieren
        '{AC811C76-7BBD-46FC-859B-EF5BD508CD8B}', //Subtrahieren
        '{8DA07624-78B7-CDA9-C05B-CCA77D82ABDF}', //Subtrahieren
        '{7DF1E982-A87E-0ED5-2A31-08DBE2587F39}', //Dividiere durch Wert einer anderen Variablen
        '{D53C4A98-50A7-1D0A-885F-B82FFC5AF498}', //Wert invertieren
        '{7938A5A2-0981-5FE0-BE6C-8AA610D654EB}', //Führe Automation aus
        //'{EC718CA1-7E19-290B-8E28-8EA87853169E}', //Auf aktuellen Zeitpunkt schalten
        //'{EF66A351-E45A-4EFE-B5AF-2C8E96543DF1}', //Auf aktuellen Zeitpunkt setzen
        //'{22F5BA55-5696-0B55-6F93-E4661501411B}', //Verketten
        //'{35FBD1CD-9F3C-4B28-9CED-978179570BF6}', //Addieren
        '{6D7E4807-E651-C565-D679-2598C06D0899}', //Führe PHP Code aus
        '{624825C6-1DF5-62EC-C204-29CA2D1244BC}', //Multiplizieren
        '{1BCF9475-3EE6-7113-FD05-62B203DD4900}', //Subtrahiere Wert einer anderen Variablen
        //'{ADE4807F-5053-CD86-85C5-5A968425AB60}', //Kommentar
        '{6C039C8D-2C8F-D8CE-E173-7E70F48F9BBE}', //Addieren
        '{E69FF53E-7E0F-222E-0129-DA7D43F2F433}', //Bedingung
        '{7F49C241-2E69-EB9E-39AE-060C11A87848}', //Warte auf Wert
        '{897EEE0C-2707-6508-397F-C3A1333FAD44}', //Ereignis aktiv/inaktiv setzen
        //'{A2AC38D9-5806-502D-EBDF-0F3D13699FA4}', //Wenn durch ein spezifisches Ereignis ausgelöst
        '{8C1EC6D4-8AA0-4AF8-AA57-0EF866C96AFB}', //Dividieren
        '{3C42985B-98E7-2FF3-54D2-E82505260860}', //Addiere Wert einer anderen Variablen
        '{75C67945-BE11-5965-C569-602D43F84269}', //Setze auf Wert
        '{611EB0CB-6783-E7CB-E2CE-C39A098D3E98}', //Subtrahiere Wert einer anderen Variablen
        //'{829B4BAF-CDD0-D510-A13C-F43C6500204E}', //Verketten mit Wert einer anderen Variable
        '{8539770B-C018-0A2A-FE9B-D7CA2E46F7FD}', //Subtrahieren
        '{28E92DFA-1640-2F3B-74F6-4B2AAE21CE22}', //Führe erweiterte Instanzfunktion aus
        '{244CDF2A-4135-81D0-D171-5E24E21D0B5B}', //Addieren
        '{92971E6F-4AC1-BEF8-B325-9E044D27B3EB}', //Setze auf Wert
        //'{D54FD4D7-43A1-9785-63B4-F58A940377F2}', //Frage aggregierte Werte ab
        '{D79A4E11-56E8-4FFA-B3BC-05584BF23CCE}', //Führe PHP Code aus
        '{50357777-FA40-6BF2-8A0F-36B0F9AE9A59}', //Dividieren
        '{4ED698BA-4E07-99E5-EDAD-FC70451070AC}', //Addiere Wert einer anderen Variablen
        '{478493A0-D120-ACF5-6F2F-B857D8F71AAA}', //Setze auf Wert
        '{A77F25D4-C899-42EF-8637-488937FBFB85}', //Setze auf Wert
        '{FF6ADC26-E841-DDFA-0CCE-CAA37A372E76}', //Addieren
        '{8890EA75-8C45-E218-27F5-345E11E3666C}', //Warten
        //'{4A8CB5B2-33A7-517F-A6CA-E71A02215EF4}', //Verketten
        '{346AA8C1-30E0-1663-78EF-93EFADFAC650}', //Führe PHP Code aus
        '{07DEB4D2-D32C-4226-8371-E8CD1A507D99}', //Schalte auf Wert
        //'{257DBB55-AC69-496E-029A-7F9FA068D60B}', //Wenn ..., dann
        //'{22BC4823-966C-3470-2B5B-E1A4D5FD1F04}', //Gruppenschaltung nach Profil
        //'{FC12B48C-52B9-36FA-682F-3560D4D76B31}', //Wenn ..., dann (mehrfach)
        '{317AAB37-69F3-C426-422D-2339834A6561}', //Warte auf Wert
        '{5F0216FB-A553-3504-9656-E82E8496F865}', //Dividiere durch Wert einer anderen Variablen (Schalten)
        '{085176EC-BEE5-3732-15E5-8C82875CABD4}', //Multipliziere mit Wert einer anderen Variablen
        //'{BADED3E1-9434-B752-74CB-7B55FF71990E}', //Bedingung (mehrfach)
        '{8F96F046-2F1B-BEB2-0451-D7C7EADA498D}', //Führe erweiterte Instanzfunktion aus
        '{DE55CF9F-AA55-3341-69EC-07E3D8BA9CE9}', //Führe Automation aus
        '{A3153696-013A-41B1-A001-5E8085D95465}', //Multiplizieren
        '{04603AA4-F1B8-3E28-8F31-8BC98C75A04B}', //Multipliziere mit Wert einer anderen Variablen
        '{FBA77158-B0FB-8CBD-7F75-6CFAAD3E64BB}', //Schalte auf Wert einer anderen Variablen
        //'{9D3DB9AE-2B41-89E0-9532-FCD945C016A3}', //Bei Wochenplanaktion
        //'{6285D5A2-0DD3-C0C5-71A8-DA5570D671F4}', //Verschicke Push Nachricht
        '{1A46B595-7F7D-4602-9EB2-B4D1577EB137}', //Setze auf Wert
        '{3644F802-C152-464A-868A-242C2A3DEC5C}', //Setze auf Wert
        '{16E78DB2-BD83-FE27-2D39-1515818C1770}', //Setze auf Wert einer anderen Variablen
        '{0792E8F6-07B1-C12F-D8C0-7CB048CABFB3}', //Warte auf Wert
        //'{23898C9A-130A-6EC1-4E61-64C36E88C658}', //Verketten mit Wert einer anderen Variable
        //'{E4539B92-E64E-29B2-F942-19C805927033}', //Sende Mail
        '{A7A928A3-86AB-4FFA-AECA-39C0A2E4933D}', //Sichtbarkeit setzen
        '{C8866B4E-3699-70E1-2B53-AC5B1FEFD2A7}' //Breche Ausführung ab
    ]
};
