<template>
    <div>
        <img
            :class="{
                'status-on': val,
                'scene-off': !val
            }"
            class="mb-0"
            :src="this.$api.getIcon(this.profile.icon)"
            alt="Object-Icon"
            :height="knoIcon.size"
        />
    </div>
</template>
<script>
export default {
    props: ['profile', 'value', 'knoIcon'],
    data() {
        return {
            val: this.value
        };
    },
    watch: {
        value(val) {
            this.val = val;
        }
    },
    computed: {
        color() {
            const value_association = this.profile.associations.find(
                assoc => assoc.value == this.value
            );
            const color = this.$parent.decimalColorToHTMLcolor(
                value_association.color
            );
            return color;
        },
        shadow() {
            const color = this.color;
            return (
                color +
                ' 0 -1px 7px 1px, inset ' +
                color +
                ' 0 -1px 9px, ' +
                color +
                ' 0 2px 12px'
            );
        }
    }
};
</script>

<style>
.status-on {
    filter: invert(22%) sepia(27%) saturate(1099%) hue-rotate(25deg)
        brightness(94%) contrast(91%);
}
.status.off {
    filter: none;
}
</style>
