import { render, staticRenderFns } from "./_WindSpeed_kmh.vue?vue&type=template&id=3de9e3f2&"
import script from "./_WindSpeed_kmh.vue?vue&type=script&lang=js&"
export * from "./_WindSpeed_kmh.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports