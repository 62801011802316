class LocalNotifier {
    constructor() {
        this.queue = [];
    }

    enqueue(notification) {
        this.queue.push(notification);
    }

    dequeue() {
        return this.queue.shift();
    }

    peek() {
        return this.queue[0];
    }

    isEmpty() {
        return this.queue.length === 0;
    }

    length() {
        return this.queue.length;
    }

    getQueue() {
        return this.queue;
    }

    remove(id = '0x0') {
        const index = this.queue.findIndex(notification => {
            return notification.id === id;
        });
        this.queue.splice(index, 1);
    }

    /**
     * Makes the "Notification" class available to every file without the need of importing it everywhere.
     * Easy access over Vue.prototype.$localNotifier.newNotification()
     */
    newNotification(
        message = '',
        error = null,
        color = 'error',
        timeout = 4000,
        button = 'OK',
        display = true
    ) {
        return new Notification(
            message,
            error,
            color,
            timeout,
            button,
            display
        );
    }

    /**
     * Immediately adds new "Notification" for comfortable one-liner.
     * If onConsumed or onAcknowledge hooks are used, it is possible
     * that the notification has already been processed when adding the
     * hooks afterwards. For this case it is safer to go with the
     * newNotification() method and enqueue() the notification after the
     * hooks where added.
     * @param {String} message The notification message.
     * @param {Error} error The error object to log on Sentry.
     * @param {String} color Color of the notification toast.
     * @param {Number} timeout Duration in ms to show the dialog.
     * @param {String} button Button text.
     * @param {Boolean} display Wether the notification should show up.
     * @returns Returns the added object of type Notification.
     */

    addNewNotification(
        message = '',
        error = null,
        color = 'error',
        timeout = 4000,
        button = 'OK',
        display = true
    ) {
        const notification = new Notification(
            message,
            error,
            color,
            timeout,
            button,
            display
        );
        this.enqueue(notification);
        return notification;
    }
}

class Notification {
    constructor(
        message = '',
        error = null,
        color = 'error',
        timeout = 4000,
        button = 'OK',
        display = true
    ) {
        this.message = message;
        this.error = error;
        this.color = color;
        this.timeout = timeout;
        this.button = button;
        this.display = display;
        this.id = Math.floor((1 + Math.random()) * 0x100000000)
            .toString(16)
            .substring(1);
        this.hooks = {
            onConsumed: () => {},
            onAcknowledge: () => {}
        };
    }

    // Just to easily set the 'display' value without touching the other defaults.
    setDisplay(display = true) {
        this.display = display;
        return this;
    }

    // hooks to have access to the lifecycle of the specific message.
    onConsumed(fnc) {
        this.hooks.onConsumed = fnc;
    }
    onAcknowledge(fnc) {
        this.hooks.onAcknowledge = fnc;
    }
}

module.exports = { LocalNotifier, Notification };
