<template>
    <img :src="svgCode" :width="width" />
</template>
<script>
export default {
    name: 'BIcon',
    props: {
        name: {
            type: String,
            required: false
        },
        svg: {
            type: String,
            required: false
        },
        width: {
            type: String,
            default: '32px'
        }
    },
    data() {
        return {
            svgCode: ''
        };
    },
    created() {
        if (this.name) {
            this.svgCode = require(`@/assets/icons/${this.name}.svg`);
        }
        if (this.svg) {
            this.svgCode = this.svg;
        }
    }
};
</script>
<style scoped></style>
