import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import CONST from '../scripts/constants';
import AlarmModuleStore from './AlarmModuleStore';
import PushNotificationStore from './PushNotificationStore';
import UtilStore from './UtilStore';
import router from '../router';
import { KnockautApiClient } from '@brelag-schweiz-ag/knockaut_ts_api';
import {
    KNOCKAUT_X_WaterSensor_MODULE_ID,
    KNOCKAUT_X_Repeater_MODULE_ID,
    KNOCKAUT_X_TemperatureHumiditySensor_MODULE_ID,
    KNOCKAUT_X_TemperatureHumiditySensorLCD_MODULE_ID,
    KNOCKAUT_X_SmokeAlarm_MODULE_ID,
    KNOCKAUT_X_WirelessSwitchMini1Channel_MODULE_ID,
    KNOCKAUT_X_MasterGatewayTWO_MODULE_ID,
    KNOCKAUT_X_Co2Sensor_MODULE_ID,
    KNOCKAUT_X_MotionSensor_MODULE_ID,
    KNOCKAUT_X_KnobDimmerSwitchWhite_MODULE_ID,
    KNOCKAUT_X_KnobDimmerSwitch_MODULE_ID,
    KNOCKAUT_X_VibrationSensor_MODULE_ID,
    KNOCKAUT_X_WirelessSwitch4Channel_MODULE_ID,
    KNOCKAUT_X_DoorWindowSensor_MODULE_ID
} from '../util/symcon/moduleUtil';
import { Preferences } from '@capacitor/preferences';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        alarmModule: AlarmModuleStore,
        notificationModule: PushNotificationStore,
        util: UtilStore
    },
    state: {
        $api: null,
        hostinfo: {
            secure: false,
            host: '',
            protocol: ''
        },
        controllers: [],
        configurators: [], // WebFronts
        configuratorID: null,
        snapshot: {}, // WebFront-Daten
        settingsPassword: '',
        dashboardPassword: null,
        isPasswordValid: false,
        isDashboardLoaded: false,
        sidebarShown: false,
        socket: {
            isConnected: false,
            message: 'nothing received!',
            reconnectError: 'no error!'
        },
        dialog: {
            show: false,
            moduleID: '',
            instanceID: '',
            title: ''
        },
        sensorModules: [
            // Knockaut X
            KNOCKAUT_X_WaterSensor_MODULE_ID,
            KNOCKAUT_X_Repeater_MODULE_ID,
            KNOCKAUT_X_TemperatureHumiditySensor_MODULE_ID,
            KNOCKAUT_X_TemperatureHumiditySensorLCD_MODULE_ID,
            KNOCKAUT_X_SmokeAlarm_MODULE_ID,
            KNOCKAUT_X_DoorWindowSensor_MODULE_ID,
            KNOCKAUT_X_WirelessSwitchMini1Channel_MODULE_ID,
            KNOCKAUT_X_MasterGatewayTWO_MODULE_ID,
            KNOCKAUT_X_Co2Sensor_MODULE_ID,
            KNOCKAUT_X_MotionSensor_MODULE_ID,
            KNOCKAUT_X_KnobDimmerSwitchWhite_MODULE_ID,
            KNOCKAUT_X_KnobDimmerSwitch_MODULE_ID,
            KNOCKAUT_X_VibrationSensor_MODULE_ID,
            KNOCKAUT_X_WirelessSwitch4Channel_MODULE_ID,

            // Dominoswiss
            '{26AE9337-13A8-4BF8-99D0-EE11D91FDEE2}', // DominoSwissMAG (Magnetkontakt Fenster/Türen)
            '{4E1FBB10-9283-7779-6D79-7D190ECE33FF}', // DominoSwissUTC (Temperatur-/Lichtsensor)
            '{B3F0007D-44EE-460B-81D1-5A74C85EE29C}', // DominoSwissWeatherstation
            '{CE892EF8-C01D-43D2-BBA7-D5B54484795E}' // DominoSwissPIR (Bewegungsmelder)
        ],
        alertModules: [
            '{0F342466-277E-5238-DC89-54A1A9B421BE}', // Bereichswarnung
            '{B23087BE-815D-9360-8CBD-B0DE995E0789}', // Sammelwarnung
            '{12C10735-18D9-5CFE-1830-C14C730B05E6}', // Schwellwertwarnung
            '{343919DA-56C1-66B1-41BF-40C97ACF8E93}' // Wetterstationswarnung
        ],
        rootID: null,
        newVirtualRouteSignal: false,
        newNavCategorySignal: false,
        messageUpdates: {
            updateTimer: 0,
            updateInterval: 500,
            updateIDs: {},
            changed: 0
        },
        footerEdit: false,
        isAppActive: true,
        snapshotUpdateCounter: 0,

        // These are used to indicate if user has manually logged out of a dashboard/controller
        // so that the automatic login to the default dashboard/controller still works afterwards
        autoLoginController: true,
        autoLoginDashboard: true
    },
    getters: {
        snapshotObjects: state => {
            try {
                return state.snapshot.objects;
            } catch (e) {
                return [];
            }
        },
        getObjectById: state => id => {
            return state.snapshot.objects[id];
        },
        getProfile: state => index => {
            return state.snapshot.profiles[index];
        },
        getRooms(state, getters) {
            let rooms = getters.objects.childrenOf(state.rootID).typeOf(0).get;
            rooms = Object.values(rooms).filter(room => room.hidden == false);
            rooms = rooms.sort((a, b) =>
                a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            );
            rooms = rooms.sort((a, b) => a.position - b.position);
            return rooms;
        },
        getRootRoomId(state, getters) {
            let roomId = 0;
            const rooms = getters.getRooms; // returns all rooms, ordered by name
            if (rooms.length >= 1) {
                roomId = rooms[0].id;
            }
            return roomId;
        },
        getControllerList(state) {
            return state.controllers;
        }
    },
    mutations: {
        signalingNewRoute(state) {
            state.newVirtualRouteSignal = true;
            state.newNavCategorySignal = true;
        },
        resetVirtualRouteSignal(state) {
            state.newVirtualRouteSignal = false;
        },
        resetNavCategorySignal(state) {
            state.newNavCategorySignal = false;
        },
        setAppActive(state, payload) {
            state.isAppActive = payload;
        },
        setControllers(state, controllers) {
            state.controllers = controllers;
        },
        setDashboardPassword(state, password) {
            state.dashboardPassword = password;
        },
        setSettingsPassword(state, pass) {
            state.settingsPassword = pass;
        },
        setSettingsPasswordValid(state, valid) {
            state.isPasswordValid = valid;
        },
        setSnapshot(state, payload) {
            if (payload.objects) {
                let objects = payload.objects;
                objects = Object.keys(objects).reduce(function(result, key) {
                    objects[key]['id'] = parseInt(key.substr(2));
                    result[key] = objects[key];
                    return result;
                }, {});
                payload.objects = objects;
            }
            state.snapshot = payload;
        },
        incrementSnapshotCounter(state) {
            state.snapshotUpdateCounter++;
        },
        setIsDashboardLoaded(state, payload) {
            state.isDashboardLoaded = payload;
        },
        setConfigurators(state, payload) {
            state.configurators = payload.result;
        },
        setConfiguratorId(state, id) {
            state.configuratorID = id;
        },
        setHostInfo(state, payload) {
            state.hostinfo = payload;
        },
        setApi(state, api) {
            Vue.prototype.$api = api;
            state.$api = api;
        },
        setRootID(state, payload) {
            state.rootID = payload;
        },
        disconnect(state) {
            state.$api.closeWebSocket();
        },
        resetCredentials(state) {
            state.settingsPassword = '';
            state.isPasswordValid = false;
        },
        setAutoLoginDashboard(state, value) {
            state.autoLoginDashboard = value;
        },
        setAutoLoginController(state, value) {
            state.autoLoginController = value;
        },
        showInstanceDialog(state, id) {
            let object = state.snapshot.objects['ID' + id];
            state.dialog.title = object.name;
            state.dialog.instanceID = id;
            if (object.type == 6) {
                const targetID = object.data.targetID;
                object = state.snapshot.objects['ID' + targetID];
                state.dialog.instanceID = targetID;
            }
            state.dialog.moduleID = object.data.moduleID;
            state.dialog.show = true;
        },
        addSnapshotObject(state, obj) {
            state.snapshot.objects['ID' + obj.id] = Object.assign({}, obj);
        },
        toggleSidebar(state) {
            state.sidebarShown = !state.sidebarShown;
        },
        showSidebar(state, value) {
            state.sidebarShown = value;
        },
        SOCKET_ONOPEN(state) {
            state.socket.isConnected = true;
        },
        SOCKET_ONCLOSE(state) {
            state.socket.isConnected = false;
        },
        SOCKET_ONERROR(state, event) {
            console.error(state, event);
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message;
            try {
                switch (message.Message) {
                    case CONST.MESSAGETYPE.OM_REGISTER: // Objekt erstellt
                    case CONST.MESSAGETYPE.OM_CHANGEPARENT: // Übergeordnetes Objekt hat sich geändert
                    case CONST.MESSAGETYPE.OM_CHANGENAME: // Name hat sich geändert
                    case CONST.MESSAGETYPE.OM_CHANGEINFO: // Beschreibung hat sich geändert
                    case CONST.MESSAGETYPE.OM_CHANGETYPE: // Typ hat sich geändert
                    case CONST.MESSAGETYPE.OM_CHANGESUMMARY: // Kurzinfo hat sich geändert
                    case CONST.MESSAGETYPE.OM_CHANGEPOSITION: // Position hat sich geändert
                    case CONST.MESSAGETYPE.OM_CHANGEREADONLY: // Nur-Lesen-Status hat sich geändert
                    case CONST.MESSAGETYPE.OM_CHANGEHIDDEN: // Sichtbarkeit hat sich geändert
                    case CONST.MESSAGETYPE.OM_CHANGEICON: // Icon hat sich geändert
                    case CONST.MESSAGETYPE.OM_CHILDADDED: // Untergeordnetes Objekt hinzugefügt
                    case CONST.MESSAGETYPE.OM_CHILDREMOVED: // Untergeordnetes Objekt entfernt
                    case CONST.MESSAGETYPE.OM_CHANGEIDENT: // Ident hat sich geändert
                    case CONST.MESSAGETYPE.OM_CHANGEDISABLED: // Bedienbarkeit hat sich geändert
                        clearTimeout(state.messageUpdates.updateTimer);
                        state.messageUpdates.updateIDs[message.SenderID] =
                            message.SenderID;
                        setTimeout(() => {
                            // eslint-disable-next-line no-unused-vars
                            for (const key in state.messageUpdates.updateIDs) {
                                state.$api
                                    .getSnapshotObject(key)
                                    .then(function(response) {
                                        if (!response) {
                                            return;
                                        }
                                        const changed =
                                            JSON.stringify(
                                                state.snapshot.objects[
                                                    'ID' + response.id
                                                ]
                                            ) !== JSON.stringify(response);
                                        if (changed) {
                                            Vue.set(
                                                state.snapshot.objects,
                                                'ID' + response.id,
                                                response
                                            );
                                            state.messageUpdates.changed++;
                                        }
                                    });
                            }
                            state.messageUpdates.updateIDs = {};
                        }, state.messageUpdates.updateTimer);
                        break;
                    case CONST.MESSAGETYPE.OM_UNREGISTER: // Objekt entfernt
                        Vue.delete(
                            state.snapshot.objects,
                            'ID' + message.SenderID
                        );
                        state.messageUpdates.changed++;
                        break;
                }
            } catch (ex) {
                console.warn(ex);
            }
            if (message.Message == CONST.MESSAGETYPE.VM_UPDATE) {
                try {
                    /**
                     * message.Data Response Header
                     * Wert
                     * Gab es eine Änderung
                     * Vorheriger Wert
                     * Zeitstempel der Änderung
                     * Zeitstempel der letzten Aktualisierung (seit 5.1)
                     * Zeitstempel der letzten Änderung (seit 5.1)
                     */
                    if (this.state.snapshot.objects['ID' + message.SenderID]) {
                        this.state.snapshot.objects[
                            'ID' + message.SenderID
                        ].data.value = message.Data[0];
                        this.state.snapshot.objects[
                            'ID' + message.SenderID
                        ].data.lastChange = message.Data[3];
                        this.state.snapshot.objects[
                            'ID' + message.SenderID
                        ].data.lastUpdate = message.Data[4];
                        const data = this.state.snapshot.objects[
                            'ID' + message.SenderID
                        ].data;
                        let profileName = data.profile;
                        if (data.customProfile != '') {
                            profileName = data.customProfile;
                        }
                        const profile = this.state.snapshot.profiles[
                            profileName
                        ];
                        if (
                            profile &&
                            profile.associations &&
                            profile.associations.length > 0
                        ) {
                            for (const key in profile.associations) {
                                if (
                                    profile.associations[key].value ==
                                    message.Data[0]
                                ) {
                                    this.state.snapshot.objects[
                                        'ID' + message.SenderID
                                    ].data.resolvedValue =
                                        profile.associations[key].name;
                                }
                            }
                        }
                    }
                } catch (ex) {
                    console.warn(ex);
                }
            }
        },
        // mutations for reconnect methods
        // eslint-disable-next-line no-unused-vars
        SOCKET_RECONNECT(state, count) {},
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        }
    },
    actions: {
        async setControllers(context, controllers) {
            await Preferences.set({
                key: 'servers',
                value: JSON.stringify(controllers)
            });
            context.commit('setControllers', controllers);
        },

        async getControllers(context) {
            let controllers = [];
            if (context.state.controllers.length > 0) {
                // get controllers from vuex state
                controllers = context.state.controllers;
            } else {
                // get controllers from permanent storage
                const controllersJson = await Preferences.get({
                    key: 'servers'
                });
                if (controllersJson.value !== null) {
                    controllers = JSON.parse(controllersJson.value);
                }
            }
            // add new parameters for controllers added before V 1.1.3
            for (let i = 0; i < controllers.length; i++) {
                let hasNewInitialized = false;
                if (controllers[i].addedAt === undefined) {
                    const now =
                        Math.floor(Date.now() / 1000) - controllers.length;
                    controllers[i].addedAt = now + i;
                    hasNewInitialized = true;
                }
                if (hasNewInitialized) {
                    context.dispatch('setControllers', controllers);
                }
            }
            // Order controllers depending on the preferences
            const result = await Preferences.get({ key: 'controller-order' });
            if (result.value !== null) {
                const orderType = parseInt(result.value);
                switch (orderType) {
                    case 0:
                        controllers = controllers.sort(
                            (a, b) => b.addedAt - a.addedAt
                        );
                        break;
                    case 1:
                        controllers = controllers.sort(
                            (a, b) => a.addedAt - b.addedAt
                        );
                        break;
                    case 2:
                        controllers = controllers.sort((a, b) =>
                            a.title > b.title ? 1 : b.title > a.title ? -1 : 0
                        );
                        break;
                    case 3:
                        controllers = controllers.sort((a, b) =>
                            a.title < b.title ? 1 : b.title < a.title ? -1 : 0
                        );
                        break;
                }
            }
            context.commit('setControllers', controllers);
            return controllers;
        },

        async getControllerFromId(context, id) {
            const controllers = await context.dispatch('getControllers');
            return controllers.find(server => {
                return server.id == parseInt(id);
            });
        },

        async getPasswordByIds(context, ids) {
            const pws = await Preferences.get({ key: 'dashboardPasswords' });
            let password = '';
            if (pws.value) {
                const passwords = JSON.parse(pws.value);
                if (
                    passwords[ids.controllerId] &&
                    passwords[ids.controllerId][ids.dashboardId]
                ) {
                    password = passwords[ids.controllerId][ids.dashboardId];
                }
            }
            return password;
        },

        /* parameter 'data': { controllerId: int, dashboardId: int, password: string } */
        async checkPassword(context, data = {}) {
            if (
                !data.controllerId ||
                !data.dashboardId ||
                (!data.password && data.password !== '')
            ) {
                const err = {
                    controllerId: data.controllerId ? 'ok' : data.controllerId,
                    dashboardId: data.dashboardId ? 'ok' : data.dashboardId,
                    password: data.password ? 'ok' : 'unbekannt'
                };
                throw new Error(
                    'Passwort Prüfung fehlgeschlagen: ' + JSON.stringify(err)
                );
            }
            const controller = await context.dispatch(
                'getControllerFromId',
                data.controllerId
            );

            const api = await context.dispatch('getNewApiClient', controller);
            api.instance.setConfiguratorID(data.dashboardId);
            api.instance.setDashboardPassword(data.password);

            api.instance.connectWebSocket();

            let success = false;
            let done = false;
            let timeout = 8 * 20; // ~ 8 Seconds
            const READYSTATE = {
                CONNECTING: 0,
                OPEN: 1,
                CLOSING: 2,
                CLOSED: 3
            };
            do {
                try {
                    const rs = api.instance.webSocket.readyState;
                    if (rs === READYSTATE.OPEN) {
                        success = true;
                        api.instance.closeWebSocket();
                    }
                    if (rs === READYSTATE.CLOSED) {
                        done = true;
                    }
                    await new Promise(r => setTimeout(r, 50));
                    timeout--;
                    if (timeout <= 0) {
                        done = true;
                    }
                } catch (ex) {
                    done = true;
                }
            } while (done === false);
            return success;
        },

        /**
         * Function that is called to check if we can automatically login to any controller/dashboard
         * @parameters {controllerId: int, dashbaordId: int, RoomId: int}
         */
        async autoLogin(context, parameters = {}) {
            const routeParameters = {
                controllerId: 0,
                dashboardId: 0,
                roomId: 0
            };

            const controllers = await context.dispatch('getControllers');

            // Check if we should automatically connect to a controller
            if (context.state.autoLoginController) {
                let controller = null;
                // get controller from controllerId in parameters
                if (parameters.controllerId) {
                    controller = await context.dispatch(
                        'getControllerFromId',
                        parameters.controllerId
                    );
                }
                // else, get controller which has autoConnect turned on (can only be one)
                if (!controller) {
                    controller = controllers.find(ctrl => {
                        return ctrl.autoConnect;
                    });
                }
                // else, get controller from router param
                if (!controller) {
                    const cid = parseInt(
                        router.currentRoute.params.controllerId
                    );
                    controller = controllers.find(ctrl => {
                        return ctrl.id === cid;
                    });
                }
                if (controller) {
                    routeParameters.controllerId = controller.id;
                    // Check if we should automatically connect to a dashboard
                    if (context.state.autoLoginDashboard) {
                        let dashboardId = 0;
                        if (parameters.dashboardId) {
                            // get dashboardId from parameters
                            dashboardId = parameters.dashboardId;
                        } else if (
                            controller.hasDefaultDashboard &&
                            controller.defaultDashboardId
                        ) {
                            // else, get dashboardId from controller-settings
                            dashboardId = controller.defaultDashboardId;
                        } else if (router.currentRoute.params.dashboardId) {
                            // else, get dashboardId from router param
                            dashboardId = parseInt(
                                router.currentRoute.params.dashboardId
                            );
                        }
                        if (dashboardId) {
                            routeParameters.dashboardId = dashboardId;
                            // load the snapshot now, to get the roomId easier afterwards.

                            // get the right password for the dashboard
                            let password = '';
                            if (parameters.password) {
                                password = parameters.password;
                            } else if (
                                controller.hasDefaultDashboard &&
                                controller.defaultDashboardId === dashboardId &&
                                controller.password
                            ) {
                                password = controller.password;
                            } else {
                                password = await context.dispatch(
                                    'getPasswordByIds',
                                    routeParameters
                                );
                                if (
                                    !password &&
                                    context.state.dashboardPassword
                                ) {
                                    password = context.state.dashboardPassword;
                                }
                            }

                            // init api
                            await context.dispatch('initApiClient', controller);

                            // load data (like snapshot)
                            const data = {
                                id: dashboardId,
                                password: password
                            };
                            const response = await context.dispatch(
                                'loadData',
                                data
                            );

                            // connect websocket
                            if (response.root_folder && response.snapshot) {
                                this._vm.$api.connectWebSocket();
                            }

                            // automatically connect to a room
                            if (parameters.roomId) {
                                // get roomId from parameters
                                routeParameters.roomId = parameters.roomId;
                            } else if (router.currentRoute.params.roomId) {
                                // else, get roomId from router param
                                routeParameters.roomId = parseInt(
                                    router.currentRoute.params.roomId
                                );
                            } else {
                                // get first room in alphabetic order
                                routeParameters.roomId =
                                    context.getters.getRootRoomId;
                            }

                            // load dashboard
                            context.commit('setIsDashboardLoaded', true);
                            router.push({
                                name: 'Home',
                                params: routeParameters
                            });
                        } else {
                            // no dashboardId found
                            router.push({
                                name: 'DashboardList',
                                params: routeParameters
                            });
                        }
                    } else {
                        // auto-login not allowed for dashboard
                        router.push({
                            name: 'DashboardList',
                            params: routeParameters
                        });
                    }
                } else {
                    // controller does not exist in ControllerList
                    router.push({ name: 'ControllerList' });
                }
            } else {
                // auto-login not allowed for controller
                router.push({ name: 'ControllerList' });
            }
        },

        setSnapshot(context, payload) {
            context.commit('setSnapshot', payload);
            context.commit('incrementSnapshotCounter');
        },

        async getNewApiClient(context, pController) {
            const url = pController.url;
            const parts = [];
            const urlParts = url.split('://');
            let hostParts = [];
            if (urlParts.length > 1) {
                if (urlParts[0] == 'symcons') {
                    urlParts[0] = 'https';
                }
                parts[0] = urlParts[0];
                hostParts = urlParts[1].split('/');
                parts[1] = hostParts[0];
            } else if (urlParts[0].includes('ipmagic')) {
                parts[0] = 'https';
                hostParts = urlParts[0].split('/');
                parts[1] = hostParts[0];
            }
            const protocol = parts[0];
            const host = parts[1];
            const hostinfo = {
                secure: !host.includes(':'),
                host: host,
                protocol: protocol
            };

            const apiConfig = {
                apiOptions: {
                    host: hostinfo.protocol + '://' + hostinfo.host,
                    username: 'webfront'
                },
                webSocketOptions: {
                    baseUrl:
                        'ws' +
                        (hostinfo.secure ? 's://' : '://') +
                        hostinfo.host,
                    autoConnect: false,
                    reconnection: false // remove again maybe ?
                },
                wsListener:
                    pController.wsListener !== undefined
                        ? pController.wsListener
                        : null,
                store:
                    pController.store !== undefined
                        ? pController.store
                        : context
            };

            const api = new KnockautApiClient(
                apiConfig.apiOptions,
                apiConfig.webSocketOptions,
                apiConfig.wsListener,
                apiConfig.store
            );
            return { instance: api, hostinfo: hostinfo };
        },

        async initApiClient(context, pController = null) {
            // Check if ApiClient already initialized
            if (context.state.$api !== null) {
                return;
            }

            let controller = pController;

            // If no controller is passed, use the current one from route
            if (controller === null) {
                controller = await context.dispatch(
                    'getControllerFromId',
                    router.currentRoute.params.controllerId
                );
            }
            const api = await context.dispatch('getNewApiClient', controller);
            context.commit('setHostInfo', api.hostinfo);
            context.commit('setApi', api.instance);
        },

        async loadData(context, payload) {
            let results = {};
            context.commit('setConfiguratorId', payload.id);
            this._vm.$api.setConfiguratorID(payload.id);
            this._vm.$api.setDashboardPassword(payload.password);
            const result = await this._vm.$api.getSnapshot();
            if (result.error) {
                throw new Error(
                    `Could not load data from controller: ${result.error.message}`
                );
            }
            results['snapshot'] = true;
            context.dispatch('setSnapshot', result);
            //context.commit('setSnapshot', result);

            // set rootID
            let items = context.state.snapshot.items;
            let root = -1;
            let newItems = {};
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.Configuration.length > 1) {
                    item.Configuration = JSON.parse(item.Configuration);
                }
                newItems[items[i].ClassName] = item;
            }
            root = newItems.Category.Configuration.baseID;
            results['root_folder'] = root ? true : false;
            context.commit('setRootID', root);

            return results;
        },

        execute({ state }, payload) {
            const parameters = jsonrpc(
                this.getters.httpHost,
                'WFC_Execute',
                [
                    state.configuratorID,
                    payload.actionID,
                    parseInt(payload.targetID),
                    payload.value
                ],
                null,
                false
            );
            return axios
                .post(parameters.apiurl, parameters.data, parameters.header)
                .catch(err => {
                    // TODO: This should be returned to the caller !!
                    console.warn(err);
                });
        },

        async getChildren({ state }, data) {
            // types 0=Category, 1=Instance, 2=Variable, 3=Script, 4=Event, 5=Media, 6=Link 'categorized'=AllTypesByCategory
            // To return all Objects, sorted by category, enter 'categorized' as the Objects type.

            let id = data[0];
            let type = data[1];
            if (!id) {
                id = state.rootID;
            }

            let children = {};

            if (type !== 'tilecategorized' && type !== 'typecategorized') {
                if (Array.isArray(type)) {
                    children = Object.filter(
                        state.snapshot.objects,
                        object =>
                            object.parentID === id &&
                            (type != null
                                ? type.includes(object.type)
                                : true) &&
                            !object.hidden
                    );
                } else {
                    children = Object.filter(
                        state.snapshot.objects,
                        object =>
                            object.parentID === id &&
                            (type !== null ? object.type === type : true) &&
                            !object.hidden
                    );
                }
            } else {
                if (type === 'typecategorized') {
                    let typeText = [
                        'categories',
                        'instances',
                        'variables',
                        'scripts',
                        'tasks',
                        'medias',
                        'links'
                    ];
                    let objects = state.snapshot.objects;
                    for (const key in objects) {
                        if (
                            objects[key].parentID === id &&
                            !objects[key].hidden
                        ) {
                            let objectType = typeText[objects[key].type];
                            if (typeof children[objectType] === 'undefined') {
                                children[objectType] = {};
                            }
                            children[objectType][key] = objects[key];
                        }
                    }
                } else {
                    // tilecategorized
                    let ipsTypeCategorizedChildren = {
                        0: {},
                        1: {},
                        2: {},
                        3: {}
                    };
                    let typeText = [
                        'categories',
                        'sensors',
                        'controls',
                        'scenes',
                        'devices',
                        'variables',
                        'alerts'
                    ];
                    if (!state.snapshot) {
                        return {};
                    }
                    const objects = JSON.parse(
                        JSON.stringify(state.snapshot.objects)
                    );
                    for (const [key, object] of Object.entries(objects)) {
                        if (object.parentID == id && !object.hidden) {
                            let type = object.type;
                            if (type === CONST.OBJECTTYPE.LINK) {
                                // move links to the respective category
                                if (!object.data.target) {
                                    object.data.target = this.state.snapshot.objects[
                                        'ID' + object.data.targetID
                                    ];
                                }
                                if (object.data.target === undefined) {
                                    // Link target does not exist anymore, ignore
                                    continue;
                                }
                                type = object.data.target.type;
                                if (type === CONST.OBJECTTYPE.INSTANCE) {
                                    const targetID = object.data.targetID;
                                    const moduleID =
                                        objects['ID' + targetID].data.moduleID;
                                    object.isSensor = this.state.sensorModules.includes(
                                        moduleID
                                    );
                                    object.isAlert = this.state.alertModules.includes(
                                        moduleID
                                    );
                                }
                            } else if (type === CONST.OBJECTTYPE.INSTANCE) {
                                const moduleID = object.data.moduleID;
                                object.isSensor = this.state.sensorModules.includes(
                                    moduleID
                                );
                                object.isAlert = this.state.alertModules.includes(
                                    moduleID
                                );
                            }
                            if (
                                typeof ipsTypeCategorizedChildren[type] ===
                                'undefined'
                            ) {
                                ipsTypeCategorizedChildren[type] = {};
                            }
                            ipsTypeCategorizedChildren[type][key] = object;
                        }
                    }
                    children[typeText[0]] = ipsTypeCategorizedChildren[0];
                    children[typeText[1]] = {};
                    children[typeText[2]] = {};
                    children[typeText[3]] = ipsTypeCategorizedChildren[3];
                    children[typeText[4]] = ipsTypeCategorizedChildren[1];
                    children[typeText[5]] = ipsTypeCategorizedChildren[2];
                    children[typeText[6]] = {};
                    for (const instanceID in ipsTypeCategorizedChildren[1]) {
                        if (
                            ipsTypeCategorizedChildren[1][instanceID].isSensor
                        ) {
                            children[typeText[1]][instanceID] =
                                ipsTypeCategorizedChildren[1][instanceID];
                        } else if (
                            ipsTypeCategorizedChildren[1][instanceID].isAlert
                        ) {
                            children[typeText[6]][instanceID] =
                                ipsTypeCategorizedChildren[1][instanceID];
                        } else {
                            children[typeText[2]][instanceID] =
                                ipsTypeCategorizedChildren[1][instanceID];
                        }
                    }
                }
            }
            return children;
        },

        logout({ commit }) {
            console.info('LOGGING OUT');
            commit('setAutoLoginDashboard', false);
            commit('setSnapshot', {});
            commit('disconnect');
            commit('resetCredentials');
            commit('setIsDashboardLoaded', false);
            commit('setDashboardPassword', null);
        }
    }
});

function jsonrpc(
    host,
    func,
    param = [],
    passwd = null,
    username = false,
    path = '/api/'
) {
    let user = 'webfront';
    if (username) {
        user = username;
    }
    let apiurl = host + path;
    let data = {
        jsonrpc: '2.0',
        method: func,
        params: param,
        id: Date.now()
    };
    let header = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    if (passwd !== null) {
        let auth = Buffer.from(user + ':' + passwd).toString('base64');
        header.headers.Authorization = 'Basic ' + auth;
    }

    let response = {
        apiurl: apiurl,
        data: data,
        header: header
    };

    //let response = await axios.post(apiurl, data, header);

    return response;
}
