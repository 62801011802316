<template>
    <div v-bind:class="flipped ? 'flip-container flipped' : 'flip-container'">
        <div class="flipper">
            <div class="front">
                <v-card
                    flat
                    tile
                    v-on:click="flipped = true"
                    justify="center"
                    align="center"
                    class="color-tile"
                >
                    <v-responsive
                        :aspect-ratio="9 / 9"
                        class="d-flex justify-center align-center"
                    >
                        <slot name="front"></slot>
                    </v-responsive>
                </v-card>
            </div>
            <div class="back" style="z-index: 1000">
                <v-card
                    flat
                    tile
                    v-on:click="flipped = false"
                    class="secondary"
                >
                    <v-responsive :aspect-ratio="9 / 9">
                        <slot name="back"></slot>
                    </v-responsive>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FlipCard',
    data: function() {
        return {
            flipped: false
        };
    }
};
</script>

<style type="text/css" scoped>
i.frontFlipBtn,
i.backFlipBtn {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #ffffff;
}
i.backFlipBtn {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}
.flip-container {
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -o-perspective: 1000;
    perspective: 1000;
}
.flip-container {
    min-height: 120px;
}
.flipper {
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    position: relative;
}
.front,
.back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 0.6s;
    -moz-transform-style: preserve-3d;
    -o-transition: 0.6s;
    -o-transform-style: preserve-3d;
    -ms-transition: 0.6s;
    -ms-transform-style: preserve-3d;
    transition: 0.6s;
    transform-style: preserve-3d;
    top: 0;
    left: 0;
    width: 100%;
}
.back {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    position: absolute;
}
.flip-container.flipped .back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
}
.flip-container.flipped .front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
.front {
    z-index: 2;
}
</style>
