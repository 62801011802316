/**
 * Generates a cryptographic random string using window.crypto.getRandomValues
 * @param len Lenngth of string
 * @param useSigns If true will use special signs
 * @returns string
 */
export function generateRandomString(len, useSigns = true) {
    const chars = useSigns
        ? '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!§$%&/?#+-_@'
        : '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return getCryptoRandomSubset(len, chars).join('');
}

/**
 * Generates a cryptographic random number represented as a string with the given base
 * @param digits Number of digits
 * @param radix Base of the desired number system. f.ex. (2=bin, 8=oct, 16=hex) limit=36
 * @returns string
 */
export function generateRandomRadixString(digits, radix = 16) {
    const chars = Array.from(Array(radix), (e, i) => i.toString(radix));
    return getCryptoRandomSubset(digits, chars.join('')).join('');
}

/**
 * Generates a cryptographic random UUID v4
 * @returns string
 */
export function generateUUID() {
    const sets = [8, 4, 4, 4, 12];
    for (let i = 0; i < sets.length; i++) {
        sets[i] = generateRandomRadixString(sets[i]);
    }
    return sets.join('-');
}

/**
 * Generates a cryptographic random subset of given length using window.crypto.getRandomValues
 * @param len Lenngth of string
 * @param useSigns If true will use special signs
 * @returns string
 */
export function getCryptoRandomSubset(len, charSet) {
    const values = [];
    const arr = new Uint32Array(len);
    const maxRange = Math.pow(2, 32);
    window.crypto.getRandomValues(arr);

    for (let i = 0; i < len; i++) {
        const c = Math.floor((arr[i] / maxRange) * charSet.length);
        values.push(charSet.charAt(c));
    }
    return values;
}

/**
 * Generates a pseudo random integer
 * @param max Max value of the random integer
 * @returns integer
 */
export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

/**
 * Generates a pseudo random hex string
 * @param digits number of digits
 * @returns integer
 */
export function getRandomHex(digits = 4) {
    const limit = Math.pow(16, digits);
    return Math.floor((1 + Math.random()) * limit)
        .toString(16)
        .substring(1);
}
