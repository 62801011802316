import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: {
            name: 'ControllerList'
        }
    },
    {
        path: '/controller-list',
        name: 'ControllerList',
        component: () => import('../controller/ControllerList.vue')
    },
    {
        path: '/controller-editor/:id?',
        name: 'ControllerEditor',
        component: () => import('../controller/ControllerEditor.vue')
    },
    {
        path: '/controller-search',
        name: 'ControllerSearch',
        component: () => import('../controller/ControllerSearch.vue')
    },
    {
        path: '/dev',
        name: 'Dev',
        component: () => import('../views/Dev.vue')
    },
    {
        path: '/loading',
        name: 'Loading',
        component: () => import('../views/Loading.vue')
    },
    {
        path: '/controller/:controllerId',
        name: 'Controller',
        component: () => import('../views/ControllerView.vue'),
        children: [
            {
                path: 'dashboard-list',
                name: 'DashboardList',
                component: () => import('../views/DashboardList.vue')
            },
            {
                path: 'dashboard/:dashboardId',
                name: 'Dashboard',
                component: () => import('../views/DashboardView.vue'),
                children: [
                    {
                        path: 'room/:roomId',
                        name: 'Home',
                        component: () => import('../views/Home.vue')
                    },
                    {
                        path: 'settings',
                        name: 'Settings',
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(store.state.snapshot).length === 0)
                                next({ name: 'DashboardList' });
                            else next();
                        },
                        component: () => import('../views/Settings.vue')
                    },
                    {
                        path: 'device-settings',
                        name: 'DeviceSettings',
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(store.state.snapshot).length === 0)
                                next({ name: 'DashboardList' });
                            else next();
                        },
                        component: () => import('../views/DeviceSettings.vue')
                    },
                    {
                        path: 'scenes',
                        name: 'Scenes',
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(store.state.snapshot).length === 0)
                                next({ name: 'DashboardList' });
                            else next();
                        },
                        component: () => import('../views/Scenes.vue')
                    },
                    {
                        path: 'tasks',
                        name: 'Tasks',
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(store.state.snapshot).length === 0)
                                next({ name: 'DashboardList' });
                            else next();
                        },
                        component: () => import('../views/Tasks.vue')
                    },
                    {
                        path: 'flowscripts',
                        name: 'Flowscripts',
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(store.state.snapshot).length === 0)
                                next({ name: 'DashboardList' });
                            else next();
                        },
                        component: () => import('../views/Flowscripts.vue')
                    },
                    {
                        path: 'alarms',
                        name: 'Alarms',
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(store.state.snapshot).length === 0)
                                next({ name: 'DashboardList' });
                            else next();
                        },
                        component: () => import('../views/Alarms.vue')
                    },
                    {
                        path: 'advanced-settings',
                        // TODO: Rename to AdvancedSettings
                        name: 'Advanced',
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(store.state.snapshot).length === 0)
                                next({ name: 'DashboardList' });
                            else next();
                        },
                        component: () => import('../views/Advanced.vue')
                    },
                    {
                        path: 'modules',
                        name: 'Modules',
                        beforeEnter: (to, from, next) => {
                            if (Object.keys(store.state.snapshot).length === 0)
                                next({ name: 'DashboardList' });
                            else next();
                        },
                        component: () => import('../views/Modules.vue'),
                        children: [
                            {
                                path: 'knockaut-alarm',
                                name: 'KnockautAlarm',
                                redirect: { name: 'KnockautAlarmOverview' },
                                component: () =>
                                    import(
                                        '../components/modules/knockaut-alarm/KnockautAlarm.vue'
                                    ),
                                children: [
                                    {
                                        path: 'overview',
                                        name: 'KnockautAlarmOverview',
                                        component: () =>
                                            import(
                                                '../components/modules/knockaut-alarm/KnockautAlarmOverview.vue'
                                            )
                                    },
                                    {
                                        path: 'settings',
                                        name: 'KnockautAlarmSettings',
                                        component: () =>
                                            import(
                                                '../components/modules/knockaut-alarm/KnockautAlarmSettings.vue'
                                            )
                                    },
                                    {
                                        path: 'mode',
                                        name: 'KnockautAlarmMode',
                                        component: () =>
                                            import(
                                                '../components/modules/knockaut-alarm/KnockautAlarmMode.vue'
                                            )
                                    },
                                    {
                                        path: 'state',
                                        name: 'KnockautAlarmState',
                                        component: () =>
                                            import(
                                                '../components/modules/knockaut-alarm/KnockautAlarmState.vue'
                                            )
                                    },
                                    {
                                        path: 'sensors',
                                        name: 'KnockautAlarmSensors',
                                        component: () =>
                                            import(
                                                '../components/modules/knockaut-alarm/KnockautAlarmSensors.vue'
                                            )
                                    },
                                    {
                                        path: 'modes',
                                        name: 'KnockautAlarmModes',
                                        component: () =>
                                            import(
                                                '../components/modules/knockaut-alarm/KnockautAlarmModes.vue'
                                            )
                                    },
                                    {
                                        path: 'password',
                                        name: 'KnockautAlarmPassword',
                                        component: () =>
                                            import(
                                                '../components/modules/knockaut-alarm/KnockautAlarmPassword.vue'
                                            )
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '/home/:id?',
        name: 'OldHome',
        component: Home,
        beforeEnter: async (to, from, next) => {
            if (to.params.id) {
                // TODO: Port this over

                const startFolders = Object.filter(
                    store.state.snapshot.objects,
                    object => {
                        return (
                            object.parentID == store.state.rootID &&
                            !object.hidden &&
                            object.type == 0
                        );
                    }
                );
                console.log({ startFolders });

                // get start folder with lowest sort-position and first in alphabet
                const sortedFolders = Object.values(startFolders).sort(
                    (a, b) =>
                        a.position - b.position ||
                        a.name.localeCompare(b.name, 'de-DE')
                );

                const id =
                    sortedFolders.length > 0
                        ? sortedFolders[0].id
                        : store.state.rootID;
                console.log('enter home id : ', id);
                if (id === undefined) {
                    next();
                } else {
                    next({
                        name: 'Home',
                        params: { id: id }
                    });
                }
            } else {
                next();
            }
        }
    }
];

const router = new VueRouter({
    routes
});

export default router;
