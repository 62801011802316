<template>
    <div>
        <div class="pa-0" tile style="max-width: 100%;">
            <span v-for="(btn, index) in profile.associations" :key="index">
                <v-btn
                    v-if="!awning || (awning && btn.value % 2 === 0)"
                    :class="{
                        'mx-2': !isXs && !isSm,
                        'mx-1': isSm,
                        'mr-1': isXs
                    }"
                    fab
                    :x-small="isXs"
                    :small="isSm"
                    @click.native="$emit('execute', btn.value)"
                    :color="'secondary'"
                >
                    <v-icon
                        v-if="btn.value == 'open'"
                        :size="isXs ? 12 : isSm ? 16 : 24"
                        :class="{ 'primary--text': value == 'open' }"
                        >fa-arrow-up</v-icon
                    >
                    <v-icon
                        v-if="btn.value == 'stop'"
                        :size="isXs ? 12 : isSm ? 16 : 24"
                        :class="{ 'primary--text': value == 'stop' }"
                        >fa-stop</v-icon
                    >
                    <v-icon
                        v-if="btn.value == 'close'"
                        :size="isXs ? 12 : isSm ? 16 : 24"
                        :class="{ 'primary--text': value == 'close' }"
                        >fa-arrow-down</v-icon
                    >
                    <v-icon
                        v-if="btn.value == 'lock'"
                        :size="isXs ? 12 : isSm ? 16 : 24"
                        :class="{ 'primary--text': value == 'lock' }"
                        >fa-lock</v-icon
                    >
                    <v-icon
                        v-if="btn.value == 'unlock'"
                        :size="isXs ? 12 : isSm ? 16 : 24"
                        :class="{ 'primary--text': value == 'unlock' }"
                        >fa-unlock</v-icon
                    >
                </v-btn>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['profile', 'value', 'id'],
    data() {
        return {
            awning: false
        };
    },
    computed: {
        isXs() {
            return this.$vuetify.breakpoint.name == 'xs';
        },
        isSm() {
            return this.$vuetify.breakpoint.name == 'sm';
        },
        btnClass() {
            if (this.isXs) {
                return 'mr-1';
            }
            if (this.isSm) {
                return 'mx-1';
            }
            return 'mx-2';
        }
    },
    created() {
        const parentID = this.$store.state.snapshot.objects['ID' + this.id]
            .parentID;
        this.$api.getConfigurations().then(configurations => {
            if (configurations) {
                const parent = configurations.find(
                    cfg => cfg.ObjectID === parentID
                );
                this.awning = parent.config.Awning;
            }
        });
    }
};
</script>
