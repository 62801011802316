<template>
    <div>
        <span>{{ $parent.translatedValue.name }}</span>
    </div>
</template>
<script>
export default {
    props: ['profile', 'value']
};
</script>
