<template>
    <div>
        <i class="fal" :class="faIconClass" style="font-size: 4vw;"></i>
    </div>
</template>
<script>
export default {
    props: ['profile', 'value', 'knoIcon'],
    data() {
        return {
            val: this.value
        };
    },
    watch: {
        value(val) {
            this.val = val;
        }
    },
    computed: {
        color() {
            const value_association = this.profile.associations.find(
                assoc => assoc.value == this.value
            );
            const color = this.$parent.decimalColorToHTMLcolor(
                value_association.color
            );
            return color;
        },
        shadow() {
            const color = this.color;
            return (
                color +
                ' 0 -1px 7px 1px, inset ' +
                color +
                ' 0 -1px 9px, ' +
                color +
                ' 0 2px 12px'
            );
        },
        faIconClass() {
            let classes = {};
            classes[this.knoIcon.name] = true;
            classes['status-on'] = this.val;
            classes['status-off'] = !this.val;
            return classes;
        }
    }
};
</script>

<style>
.status-on {
    filter: invert(22%) sepia(27%) saturate(1099%) hue-rotate(25deg)
        brightness(94%) contrast(91%);
}
.status.off {
    filter: none;
}
.status-icon {
    position: absolute;
    top: 20%;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 60%;
    max-height: 60%;
    width: auto;
    height: 100%;
}
</style>
