import configs from '@/scripts/configs';
import { ControlTileFactory } from '@/util/knockaut/tile';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

/**
 *
 * @param {Variable[]} variables Array of Variables
 * @param {Instance} instance Instance
 * @returns controlTile: ControlTile
 */

export function getControlTile(instance, variables) {
    let config = getConfigByGUID('');
    if (instance.data && instance.data.target && instance.data.target.data) {
        const guid = instance.data.target.data.moduleID;
        // Get config by the GUID of the module.
        config = getConfigByGUID(guid);
    }
    // Return ControlTile created from config.
    return ControlTileFactory.make(instance, variables, config);
}

// gets the config fully merged and resolved
export function getConfigByGUID(guid, customConfig = null) {
    const depthLimit = 8;
    const isCustom = !!customConfig;
    const hasConfig = !!configs.tiles[guid];

    if (!hasConfig) {
        let config = cloneDeep(configs.tiles.default);
        config.depth = 1;
        if (isCustom) {
            config = merge({}, config, customConfig);
            config.depth++;
        }
        return config;
    }

    let conf = cloneDeep(configs.tiles[guid]);
    conf.depth = 1;
    if (isCustom) {
        conf = merge({}, conf, customConfig);
        conf.depth++;
    }
    if (conf.depth >= depthLimit - 1) {
        conf.depth++;
        return merge({}, configs.tiles.default, conf);
    }
    const hasTaregt = !!conf.target && typeof conf.target === 'string';
    if (hasTaregt) {
        const targetGUID = conf.target;
        delete conf.target;
        return getConfigByGUID(targetGUID, conf);
    } else {
        return merge({}, configs.tiles.default, conf);
    }
}
