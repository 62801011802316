<template
    ><div>
        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <i class="fas fa-cog fa-2x kno-text-grey"></i>
                </v-btn>
            </template>
            <v-list>
                <v-list-item :to="{ name: 'Settings', params: $route.params }">
                    <v-list-item-action>
                        <i class="fas fa-cog"></i>
                    </v-list-item-action>
                    <v-list-item-title>
                        Einstellungen
                    </v-list-item-title>
                </v-list-item>
                <template v-if="isLoggedIn">
                    <v-list-item
                        v-for="(item, index) in settingsMenu"
                        :key="index"
                        :to="{
                            name: item.routeName,
                            params: $route.params
                        }"
                    >
                        <v-list-item-action>
                            <i :class="item.icon"></i>
                        </v-list-item-action>
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <template v-else>
                    <v-list-item @click="loginDialog = true">
                        <v-list-item-action>
                            <i class="fas fa-wrench"></i>
                        </v-list-item-action>
                        <v-list-item-title>
                            Experte
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item @click="logout">
                    <v-list-item-action>
                        <i class="fas fa-sign-out-alt"></i>
                    </v-list-item-action>
                    <v-list-item-title>
                        Logout
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="loginDialog" persistent max-width="400px">
            <v-card>
                <v-card-title>
                    <span class="headline">Experte Login</span>
                </v-card-title>
                <v-card-subtitle class="mt-2 pb-0"
                    >Sie müssen eingeloggt sein, um Experten Einstellungen
                    vornehmen zu können.</v-card-subtitle
                >
                <v-card-text class="pb-0">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Passwort"
                                    v-model="password"
                                    type="password"
                                    required
                                    v-on:keyup.enter="authenticate"
                                    autofocus
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-alert
                    v-if="error"
                    class="mb-0 ml-5 mr-5 mt-2"
                    dense
                    outlined
                    type="error"
                >
                    Login fehlgeschlagen!
                </v-alert>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="loginDialog = false">Abbrechen</v-btn>
                    <v-btn class="primary--text" text @click="authenticate"
                        >Login</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'SettingsMenu',
    data() {
        return {
            error: false,
            loginDialog: false,
            password: '',
            settingsMenu: [
                {
                    title: 'Ablaufpläne',
                    routeName: 'Flowscripts',
                    icon: 'fas fa-forward',
                    requireAuth: true
                },
                {
                    title: 'Warnungen',
                    routeName: 'Alarms',
                    icon: 'fas fa-bell',
                    requireAuth: true
                },
                {
                    title: 'Geräte',
                    routeName: 'DeviceSettings',
                    icon: 'fas fa-network-wired',
                    requireAuth: true
                },
                {
                    title: 'Erweitert',
                    routeName: 'Advanced',
                    icon: 'fas fa-cog',
                    requireAuth: true
                }
            ]
        };
    },
    computed: {
        isLoggedIn() {
            return (
                this.$store.state.settingsPassword &&
                this.$store.state.isPasswordValid
            );
        }
    },
    methods: {
        async logout() {
            this.$router.push({
                name: 'DashboardList',
                params: {
                    controllerId: this.$router.currentRoute.params.controllerId
                }
            });
            await this.$store.dispatch('logout');
        },
        async authenticate() {
            try {
                this.$api.setAdvancedSettingsPassword(this.password);
                // Manually verify if password is correct by doing a request
                await this.$api.getAlarms();
            } catch (error) {
                this.error = true;
                this.password = '';
                return;
            }
            this.$store.commit('setSettingsPassword', '1');
            this.$store.commit('setSettingsPasswordValid', true);
            this.password = '';
            this.loginDialog = false;
            this.$router.push({
                name: 'Flowscripts',
                params: {
                    ...this.$route.params
                }
            });
        }
    }
};
</script>
