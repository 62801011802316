<template>
    <div>
        <div class="pa-0" tile style="max-width: 100%;">
            <v-btn
                :class="{ 'mx-2': !isXs && !isSm, 'mx-1': isSm, 'mr-1': isXs }"
                v-for="(btn, index) in profile.associations"
                :key="index"
                fab
                :x-small="isXs"
                :small="isSm"
                @click.native="$emit('execute', btn.value)"
                :color="'secondary'"
            >
                <v-icon
                    v-if="btn.value == 0"
                    :size="isXs ? 12 : isSm ? 16 : 24"
                    :class="{ 'primary--text': value == 0 }"
                    >fa-step-backward</v-icon
                >
                <v-icon
                    v-if="btn.value == 1"
                    :size="isXs ? 12 : isSm ? 16 : 24"
                    :class="{ 'primary--text': value == 1 }"
                    >fa-play</v-icon
                >
                <v-icon
                    v-if="btn.value == 2"
                    :size="isXs ? 12 : isSm ? 16 : 24"
                    :class="{ 'primary--text': value == 2 }"
                    >fa-pause</v-icon
                >
                <v-icon
                    v-if="btn.value == 3"
                    :size="isXs ? 12 : isSm ? 16 : 24"
                    :class="{ 'primary--text': value == 3 }"
                    >fa-stop</v-icon
                >
                <v-icon
                    v-if="btn.value == 4"
                    :size="isXs ? 12 : isSm ? 16 : 24"
                    :class="{ 'primary--text': value == 4 }"
                    >fa-step-forward</v-icon
                >
                <v-icon
                    v-if="btn.value == 5"
                    :size="isXs ? 12 : isSm ? 16 : 24"
                    :class="{ 'primary--text': value == 5 }"
                    >fa-random</v-icon
                >
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: ['profile', 'value'],
    computed: {
        isXs() {
            return this.$vuetify.breakpoint.name == 'xs';
        },
        isSm() {
            return this.$vuetify.breakpoint.name == 'sm';
        }
    }
};
</script>
