<template>
    <span>
        <div class="notch-bar-background"></div>

        <v-app-bar
            class="notch-safe-bar"
            app
            color="rgb(56, 50, 47, 0.95)"
            dark
        >
            <v-app-bar-nav-icon
                @click.stop="toggleDrawer"
                class="kno-text-grey"
            >
                <i
                    v-if="!$store.state.sidebarShown"
                    class="fas fa-bars fa-2x"
                ></i>
                <i v-else class="fas fa-chevron-left"></i>
            </v-app-bar-nav-icon>
            <div class="grey--text" style="line-height: 110%">
                <small class="d-none d-sm-none d-md-flex">
                    {{ clock }}
                </small>
            </div>
            <v-spacer></v-spacer>
            <v-toolbar-title
                v-if="location.Sonnenaufgang && location.Sonnenuntergang"
            >
                <span style="width: 32px"
                    ><b-icon
                        name="SunRiseSet"
                        class="sun-rise-set mr-3"
                    ></b-icon
                ></span>
                <p
                    style="font-size: 12px; padding-top: -10px; padding-right: 60px"
                    class="kno-text-grey mb-0"
                >
                    {{ location.Sonnenaufgang }}
                </p>
                <p
                    style="font-size: 12px; padding-top: -10px; padding-right: 60px"
                    class="kno-text-grey mb-0"
                >
                    {{ location.Sonnenuntergang }}
                </p>
            </v-toolbar-title>
            <SettingsMenu />
        </v-app-bar>
    </span>
</template>

<script>
import SettingsMenu from './SettingsMenu.vue';

export default {
    components: { SettingsMenu },
    name: 'AppNavigation',
    data() {
        return {
            appTitle: 'KnockAut',
            clock: 'notime',
            location: {
                Sonnenaufgang: '',
                Sonnenuntergang: ''
            }
        };
    },
    methods: {
        toggleDrawer() {
            this.$store.commit('toggleSidebar');
        },
        startTime() {
            this.clock = this.$moment(new Date()).format(
                'dd DD. MMMM YYYY | HH:mm'
            );
            setTimeout(this.startTime, 500);
        },
        getSunriseInfo() {
            const location = Object.filter(
                this.$store.state.snapshot.objects,
                object =>
                    object.data &&
                    object.data.moduleID &&
                    object.data.moduleID ==
                        '{45E97A63-F870-408A-B259-2933F7EABF74}'
            );
            const data = {};
            if (Object.keys(location).length) {
                const locationID = Object.keys(location)[0].substring(2);
                const variables = Object.filter(
                    this.$store.state.snapshot.objects,
                    object =>
                        object.parentID == locationID &&
                        (object.ident == 'Sunrise' || object.ident == 'Sunset')
                );
                Object.values(variables).forEach(variable => {
                    data[variable.name] = this.$moment(
                        variable.data.value * 1000
                    ).calendar();
                });
            }
            return data;
        }
    },
    async mounted() {
        this.location = this.getSunriseInfo();
        this.startTime();
    }
};
</script>

<style scoped>
.sun-rise-set {
    float: left;
    filter: invert(47%) sepia(1%) saturate(1927%) hue-rotate(2deg)
        brightness(89%) contrast(77%);
}
</style>
