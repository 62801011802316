import { getModuleByGuid } from '../util/symcon/moduleUtil';

export default {
    methods: {
        getChildren(id = null, type = null) {
            // types 0=Category, 1=Instance, 2=Variable, 3=Script, 4=Event, 5=Media, 6=Link
            if (!id) {
                id = this.$store.state.rootID;
            }

            let children = {};

            if (Array.isArray(type)) {
                children = Object.filter(
                    this.$store.state.snapshot.objects,
                    object =>
                        object.parentID == id &&
                        (type != null ? type.includes(object.type) : true) &&
                        !object.hidden
                );
            } else {
                children = Object.filter(
                    this.$store.state.snapshot.objects,
                    object =>
                        object.parentID == id &&
                        (type != null ? object.type == type : true) &&
                        !object.hidden
                );
            }
            return children;
        },
        getSortedChildrenList(id = null, type = null) {
            const childrenDict = this.getChildren(id, type);
            const childrenList = [];
            for (const key in childrenDict) {
                const child = childrenDict[key];
                childrenList.push({
                    ...child,
                    id: key.substring(2)
                });
            }
            childrenList.sort(function(a, b) {
                return a.position - b.position;
            });
            return childrenList;
        },
        getModuleByGuid(guid = '{}') {
            return getModuleByGuid(guid, this.$store.getters);
        },
        hasJSONStringContent(str) {
            return str.length > 3;
        },
        pad(num, size = 2) {
            let s = num + '';
            while (s.length < size) s = '0' + s;
            return s;
        }
    },
    computed: {
        fontSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return '3.2';
                case 'sm':
                    return '1.6';
                case 'md':
                    return '1.4';
                case 'lg':
                    return '1.1';
                case 'xl':
                    return '0.9';
            }
            return '2vx';
        },
        tileSize() {
            let ret;
            if (this.$vuetify.breakpoint.name == 'xs') {
                ret = 21.25;
            } else if (this.$vuetify.breakpoint.name == 'sm') {
                ret = 14.333;
            } else {
                ret = 14.333;
                if (this.$store.state.sidebarShown) {
                    ret =
                        ret * (1 - 310 / (this.$vuetify.breakpoint.width - 16));
                }
            }
            ret = this.size ? ret * this.size : ret * 0.66;
            return ret;
        },
        progress() {
            return this.$store.state.loading;
        },
        safeIcon() {
            return this.icon ? this.icon : 'IPS';
        },
        iconSize() {
            return '50vw';
        }
    }
};
