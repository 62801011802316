<template>
    <div>
        <div v-if="hasAction">
            <v-select
                v-if="hasAssociations && type != 0"
                :items="profile.associations"
                item-text="name"
                item.value="value"
                v-model="processedValue"
                dense
                filled
                rounded
            ></v-select>
            <div
                v-else-if="
                    hasAssociations &&
                        type == 0 &&
                        profile.associations.length == 2
                "
            >
                <span class="mr-4">
                    {{ profile.associations[0].name }}
                </span>
                <v-switch
                    style="display:inline-block;"
                    class="mt-0 pt-0 centered-self"
                    v-model="processedValue"
                ></v-switch>
                <span class="ml-4">
                    {{ profile.associations[1].name }}
                </span>
            </div>
            <div v-else>
                <span v-if="isPercent">
                    <span
                        class="float-left"
                        style="margin-top: 6px; margin-right: 10px"
                        >{{ displayValue }}
                    </span>
                    <v-slider
                        min="0"
                        max="100"
                        :step="percentStepSize"
                        :value="processedValue"
                        @end="setProcessedValue"
                    >
                    </v-slider>
                </span>
                <v-text-field
                    v-else-if="type != 0"
                    :type="getType"
                    v-model="processedValue"
                    filled
                    rounded
                    dense
                    class="mt-0 pt-0 centered-input"
                >
                    <div
                        slot="append"
                        v-if="this.profile && this.profile.suffix"
                        style="white-space: nowrap"
                    >
                        {{ this.profile.suffix }}
                    </div>
                    <div
                        slot="prepend"
                        v-if="this.profile && this.profile.prefix"
                        style="white-space: nowrap"
                    >
                        {{ this.profile.prefix }}
                    </div>
                </v-text-field>
                <div v-else>
                    <v-switch
                        class="mt-0 pt-0 centered-self"
                        v-model="processedValue"
                    ></v-switch>
                </div>
            </div>
        </div>
        <div v-else>
            <div style="height: 30px;line-height: 30px;text-align: center;">
                {{ displayValue }}
            </div>
        </div>
        <div
            v-if="showsubtitle"
            class="cracked-ugly-title pr-4"
            :style="{
                'font-size': fontsize + 'vw',
                'line-height': fontsize + 'vw'
            }"
        >
            {{ name }}
        </div>
    </div>
</template>
<script>
export default {
    props: [
        'profile',
        'name',
        'value',
        'type',
        'action',
        'id',
        'fontsize',
        'showsubtitle'
    ],
    data() {
        return {
            actionValue: null,
            PARSE_LEVEL: {
                RAW: -1, // do not even process the value
                PROCESS: 0, // processed value (f.ex. -50 to 300 as percent)
                RESOLVE: 1, // resolved text (f.ex. association 1 as text "STOP" or numbers as fixed decimal string)
                FULL_RESOLVE: 2 // resolved text with prefix and suffix
            }
        };
    },
    computed: {
        processedValue: {
            get() {
                return this.raw2Processed(this.value, this.PARSE_LEVEL.PROCESS);
            },
            set(newValue) {
                const value = this.processed2Raw(newValue);
                this.$emit('execute', value);
            }
        },
        displayValue() {
            return this.raw2Processed(
                this.value,
                this.PARSE_LEVEL.FULL_RESOLVE
            );
        },
        percentStepSize() {
            const c = this.profileCalc;
            let percentStepSize = this.profile.stepSize * (100 / c.range);
            percentStepSize =
                Math.round(percentStepSize * c.magnitude) / c.magnitude;
            return percentStepSize > 0 ? percentStepSize : 1;
        },
        getType() {
            return this.type == 1 || this.type == 2 ? 'number' : 'text';
        },
        profileCalc() {
            if (this.hasProfile) {
                const min = this.profile.minValue;
                const max = this.profile.maxValue;
                const range = Math.abs(max - min);
                const digits =
                    this.profile.type === this.$CONST.VARIABLETYPE.FLOAT
                        ? this.profile.digits
                        : 0;
                const scaleFactor = 100 / range;
                const magnitude = Math.pow(10, digits);
                return {
                    min: min,
                    max: max,
                    range: range,
                    digits: digits,
                    scaleFactor: scaleFactor,
                    magnitude: magnitude
                };
            }
            return null;
        },
        hasProfile() {
            return this.profile ? true : false;
        },
        hasAssociations() {
            const result =
                this.profile &&
                this.profile.associations &&
                this.profile.associations.length > 0
                    ? true
                    : false;
            return result;
        },
        hasAction() {
            return this.action > 9999 ? true : false;
        },
        isPercent() {
            return (
                this.profile &&
                this.profile.suffix &&
                this.profile.suffix.includes('%')
            );
        }
    },
    methods: {
        setProcessedValue(newValue) {
            this.$set(this, 'processedValue', newValue);
        },
        getNearestAssociationBelow(data, target) {
            return data.reduce((acc, obj) =>
                Math.abs(target - obj.value) < Math.abs(target - acc.value) &&
                target - obj.value >= 0
                    ? obj
                    : acc
            );
        },
        raw2Processed(rawValue, parseLevel = 0) {
            let processedValue = rawValue;
            if (this.hasProfile && parseLevel >= this.PARSE_LEVEL.PROCESS) {
                if (
                    this.hasAssociations &&
                    parseLevel >= this.PARSE_LEVEL.RESOLVE
                ) {
                    let association = null;
                    if (this.profile.type === this.$CONST.VARIABLETYPE.FLOAT) {
                        association = this.getNearestAssociationBelow(
                            this.profile.associations,
                            rawValue
                        );
                        if (association && association.name) {
                            processedValue = association.name;
                        }
                    } else {
                        association = Object.filter(
                            this.profile.associations,
                            object => object.value == rawValue
                        );
                        association = Object.values(association);
                        if (association.length === 1) {
                            processedValue = association[0].name;
                        }
                    }
                } else {
                    if (this.isPercent) {
                        const c = this.profileCalc;
                        const distance = rawValue - c.min;
                        const percent = distance * c.scaleFactor;
                        processedValue =
                            Math.round(percent * c.magnitude) / c.magnitude;

                        if (parseLevel >= this.PARSE_LEVEL.RESOLVE) {
                            processedValue = processedValue.toFixed(c.digits);
                        }
                    }
                }
                if (parseLevel === this.PARSE_LEVEL.FULL_RESOLVE) {
                    return (
                        this.profile.prefix +
                        processedValue +
                        this.profile.suffix
                    );
                }
            }
            return processedValue;
        },
        processed2Raw(processedValue) {
            // only works for parse levels <= PARSE_LEVEL.PROCESS
            let rawValue = processedValue;
            const varType = this.type
                ? this.type
                : this.hasProfile
                ? this.profile.type
                : -1;
            if (this.isPercent) {
                const c = this.profileCalc;
                let value = c.min + processedValue / c.scaleFactor;
                rawValue = Math.round(value * c.magnitude) / c.magnitude;
            }
            if (varType === this.$CONST.VARIABLETYPE.INTEGER) {
                rawValue = parseInt(rawValue);
            }
            if (varType === this.$CONST.VARIABLETYPE.FLOAT) {
                rawValue = parseFloat(rawValue);
            }
            if (varType === this.$CONST.VARIABLETYPE.BOOLEAN) {
                rawValue = !!rawValue;
            }
            if (varType === this.$CONST.VARIABLETYPE.STRING) {
                rawValue = String(rawValue);
            }
            return rawValue;
        }
    }
};
</script>
<style>
.v-text-field__details {
    display: none;
}
.centered-input input {
    text-align: center !important;
}
.centered-self .v-input--selection-controls__input {
    margin: auto;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.cracked-ugly-title {
    position: absolute;
    width: 100%;
}
.v-select__selections input {
    display: none;
}
</style>
