<template>
    <div>
        <div class="pa-0" tile style="max-width: 100%;">
            <span v-for="(btn, index) in profile.associations" :key="index">
                <v-btn
                    v-if="!awning || (awning && btn.value % 2 === 0)"
                    :class="{
                        'mx-2': !isXs && !isSm,
                        'mx-1': isSm,
                        'mr-1': isXs
                    }"
                    fab
                    :x-small="isXs"
                    :small="isSm"
                    @click.native="$emit('execute', btn.value)"
                    :color="'secondary'"
                >
                    <v-icon
                        v-if="btn.value == 0"
                        :size="isXs ? 12 : isSm ? 16 : 24"
                        :class="{ 'primary--text': value == 0 }"
                        >fa-arrow-up</v-icon
                    >
                    <v-icon
                        v-if="btn.value == 1"
                        :size="isXs ? 12 : isSm ? 16 : 24"
                        :class="{ 'primary--text': value == 1 }"
                        >fa-chevron-up</v-icon
                    >
                    <v-icon
                        v-if="btn.value == 2"
                        :size="isXs ? 12 : isSm ? 16 : 24"
                        :class="{ 'primary--text': value == 2 }"
                        >fa-stop</v-icon
                    >
                    <v-icon
                        v-if="btn.value == 3"
                        :size="isXs ? 12 : isSm ? 16 : 24"
                        :class="{ 'primary--text': value == 3 }"
                        >fa-chevron-down</v-icon
                    >
                    <v-icon
                        v-if="btn.value == 4"
                        :size="isXs ? 12 : isSm ? 16 : 24"
                        :class="{ 'primary--text': value == 4 }"
                        >fa-arrow-down</v-icon
                    >
                </v-btn>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['profile', 'value', 'id'],
    data() {
        return {
            awning: false
        };
    },
    computed: {
        isXs() {
            return this.$vuetify.breakpoint.name == 'xs';
        },
        isSm() {
            return this.$vuetify.breakpoint.name == 'sm';
        }
    },
    created() {
        const parentID = this.$store.state.snapshot.objects['ID' + this.id]
            .parentID;
        this.$api.getConfigurations().then(configurations => {
            if (configurations) {
                const parent = configurations.find(
                    cfg => cfg.ObjectID === parentID
                );
                this.awning = parent.config.Awning;
            }
        });
    }
};
</script>
