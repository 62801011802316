<template>
    <i class="fal" :class="$IconName(from)" :width="width"></i>
</template>
<script>
export default {
    name: 'FaIcon',
    props: {
        from: {
            required: true
        },
        width: {
            type: String,
            default: ''
        }
    }
};
</script>
