<template>
    <div
        v-if="debug.show"
        v-resize="onResize"
        style="position: fixed; left: 8px; bottom: 8px; background-color: #555; border: solid white 1px; width: 20%; height: 100px; z-index:100; min-width: 230px"
    >
        <div class="mx-2" style="letter-spacing: 0">
            <div
                @click="debug.show = false"
                style="position: absolute; top:0;right:4px;"
            >
                X
            </div>
            <b style="font-family: comic-sansms">DESIGN CONSOLE</b><br />
            <b>Size:</b> {{ $vuetify.breakpoint.name }}<br />
            <b>Display:</b> {{ $vuetify.breakpoint.width }}x
            {{ $vuetify.breakpoint.height }}<br />
            <b>Device:</b> {{ deviceDescription }}<br />
        </div>
    </div>
</template>

<script>
export default {
    name: 'DebugOVerlay',
    data() {
        return {
            debug: {
                show: false,
                windowSize: {
                    x: 0,
                    y: 0
                },
                device: 'unknown'
            }
        };
    },

    computed: {
        deviceDescription() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'Small to large phone';
                case 'sm':
                    return 'Small to medium tablet';
                case 'md':
                    return 'Large tablet to laptop';
                case 'lg':
                    return 'Desktop';
                case 'xl':
                    return '4k and ultra-wide';
            }
            return 'unknown';
        }
    },
    methods: {
        onResize() {
            // DEBUG
            this.debug.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            };
        }
    }
};
</script>
<style></style>
