<template>
    <div>
        <i v-if="value == 0" class="fas fa-door-closed fa-2x"></i>
        <i v-if="value == 1" class="fas fa-door-open fa-2x"></i>
    </div>
</template>
<script>
export default {
    props: ['value']
};
</script>
